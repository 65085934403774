import React, { useState } from "react";
import Map from "./google/Map";
import Marker from "./google/Marker";
import TransitLayer from "./google/TransitLayer";
import getPlaces from "./utils/getPlaces";
import "./index.css";

export default function Consumer() {
  const places = getPlaces();
  const [placeIndex, setPlaceIndex] = useState(0);
  const [bound, setBound] = useState({});
  const [transitLayerEnabled, setTransitLayerEnabled] = useState(false);

  return (
    <div className="container-map">
      <Map
        zoom={10}
        center={{
          lat: places[placeIndex].lat,
          lng: places[placeIndex].lng,
        }}
        events={{ onBoundsChangerd: (arg) => setBound(arg) }}
      >
        <TransitLayer enabled={transitLayerEnabled} />
        {places.map((m, index) => (
          <Marker
            key={m.id}
            active={placeIndex === index}
            title={m.local}
            nome={m.nome}
            address={m.address}
            email={m.email}
            local={m.local}
            telefone={m.telefone}
            position={{ lat: m.lat, lng: m.lng }}
            events={{}}
          ></Marker>
        ))}
      </Map>
    </div>
  );
}
