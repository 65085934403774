import { api } from '../connection/api'

export async function getAssociated() {
    return await api.get(`associate`);
}

export async function getUpdatedAssociatedCnaesCompany() {
  return await api.get(`associate/updatedAssociatedCnaesCompany`);
}


export async function putAssociated(associated) {
    return await api.put(`associate`, associated);
}
