import { api } from '../connection/api'

const baseEndpoint = 'associate/findBySubscriptionPlanType';
const endPoint = '/payment/ordersale/findSubscriptionPlanAssociate';

export async function findBySubscriptionPlanType(subscriptionPlanTypeId) {
  return await api.get(`${baseEndpoint}/${subscriptionPlanTypeId}`);
}


export async function findBySubscriptionPlanAssociate(associatedId) {
  return await api.get(`${endPoint}/${associatedId}`);
}
