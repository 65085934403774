import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";

const CollapseButton = (props) => {
  const {
    variant,
    text,
    action,
    icon,
    iconIMG,
    size,
    iconColor,
    textColor,
    iconSize,
    _class,
    goesTo,
    weight,
    disabled,
  } = props.buttonData;

  return (
    <div className="d-flex">
      <div className="d-flex col-3 border-left border-right bg-light align-items-center justify-content-center"></div>
      <div className="d-flex col-9 pl-0 pr-0 pt-1 mb-1">
        <Button
          size={size}
          className={_class}
          style={{ color: textColor, fontWeight: weight }}
          variant={variant}
          disabled={disabled}
          onClick={() => {
            action();
          }}
        >
          {iconIMG ? (
            <img src={iconIMG} className="mr-2" alt="" />
          ) : (
            icon && (
              <i
                className={icon.concat(" mr-2 ")}
                style={{ color: iconColor, fontSize: iconSize }}
              ></i>
            )
          )}
          {/* {} */}
          <Link
            to={goesTo}
            style={{
              textDecoration: "none",
              color: textColor,
              fontFamily: "Source Sans Pro",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {text}
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default CollapseButton;
