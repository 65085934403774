import { api } from '../connection/api'

export async function getOne(id) {
    return await api.get(`financial/das/${id}`);
}

export async function getAllDas(data) {
    return await api.get(`financial/das`,{ params: data});
}

export async function downloadDasByAssociated(data) {
    return await api.get(`financial/das/update/download_das`,{params:data});
}

export async function updateAvailable(data) {
    return await api.get(`financial/das/update/update_available`,{params:data});
}
export async function lastUpdateLog(data) {
    return await api.get(`financial/das/update/last_update_log`,{params:data});
}
export async function updateCurrentDas(data) {
    return await api.post(`financial/das/update/update_current_das`,{params:data});
}
