import '../App.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import ButtonDefault from '../../../components/buttonDefault';
import IconButtonDefault from '../../../components/iconButton';
import { TypographDefault } from '../../../components/typograph'
import { useHistory } from 'react-router-dom';
import { putResetPassword } from '../../../services/associate/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Recovery() {
    const [toRecover, setToRecover] = useState('');
    const [sendEmail, setSendEmail] = useState('')

    const history = useHistory();

    const [backErrors, setBackErrors] = useState({});
    const clearBackErrors = (field) => {
        backErrors[field] = null;
        setBackErrors(backErrors);
    };

    const cancel = () => {
        history.push('/login');
    };

    const recoveryValidationSchema = Yup.object().shape({
        toRecover: Yup.string()
            .nullable()
            .required('Campo Obrigatório')
            .email('Insira um e-mail válido.')
    });
    return (
        <div className="container-fluid">
            <Formik
                initialValues={{
                    toRecover,
                }}
                enableReinitialize
                validationSchema={recoveryValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    putResetPassword(toRecover).then(response => {
                        setSendEmail('Email enviado!')
                    }).catch(err => {
                        setSendEmail('')
                        setBackErrors({
                            toRecover: err.response.data.errors[0]?.message
                        })
                    })

                    setSubmitting(true);
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <div>

                        <div id="header" className="px-5 my-3">
                            <div>
                                <img className="img-fluid mt-3" src="/logomei.svg" />
                            </div>
                            <div className="text-center">
                                <img className="img-fluid mt-3" src="/blackacsplogo.svg" />
                            </div>
                        </div>

                        <div className="row d-flex align-items-center" style={{ height: "60vh" }}>
                            <div className="col-12">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-6 m-0 p-0 d-flex align-items-center flex-wrap">
                                        <div className="ml-5 d-flex align-items-center flex-wrap mt-3">
                                            <h1 style={{ color: "#002c43" }}>
                                                <FontAwesomeIcon
                                                    className="back-icon mr-3 icon-hover"
                                                    icon={faArrowLeft}
                                                    size="1x"
                                                    onClick={() => history.push("/home")}
                                                    color="#002c43"
                                                    style={{ hover: "cursor: pointer" }}
                                                />
                                                Recuperar senha
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-5">
                                        <p className="text-muted">
                                            Informe o seu CNPJ ou Código de associado para que possamos enviar um link de
                                            recuperação, assim poderá recuperar sua senha.
                                        </p>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-md-5">
                                        <p className="text-muted font-weight-bold m-0">E-mail <label className="text-danger">*</label></p>
                                        <input
                                            className="form-control"
                                            name="toRecover"
                                            placeholder="Informe o e-mail"
                                            onChange={(toRecover) => {
                                                clearBackErrors('toRecover');
                                                setToRecover(toRecover.target.value);
                                                setFieldValue(
                                                    'toRecover',
                                                    toRecover.target.value,
                                                );
                                            }}
                                            value={values.toRecover}
                                        />
                                        <small className="col-12 text-left text-danger m-0 p-0">
                                            {(errors.toRecover
                                                && touched.toRecover
                                                && errors.toRecover) || backErrors.toRecover}
                                        </small>
                                        <div className="green">{sendEmail}</div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="row col-12 col-md-5 d-flex justify-content-between align-items-center">
                                        <ButtonDefault buttonData={{
                                            variant: 'none',
                                            action: () => handleSubmit(),
                                            text: 'Enviar',
                                            textColor: '#FFF',
                                            _class: 'createAccout_btn font-weight-bold col mr-1 mt-2',
                                        }} />
                                        <ButtonDefault buttonData={{
                                            variant: 'none',
                                            action: () => cancel(),
                                            text: 'Cancelar',
                                            textColor: '#87cefa',
                                            _class: 'recovery__btn--cancel font-weight-bold col mt-2',
                                        }} />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col-12 col-md-5">
                                        <p className="text-muted m-0">
                                            <label className="font-weight-bold m-0">Observação:</label> Após clicar em Enviar você terá 15 minutos para redefinir sua senha, caso não consiga você deverá solicitar nova recuperação.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </Formik>
        </div>
    );
}

export default Recovery;
