import '../App.css';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageDefault from '../../../components/pageDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { activateStatus, deactiveStatus, getProviderById, getProviders } from '../../../services/register/providers';
import SwitcherDefault from '../../../components/switcher';
import MUIDataTable from 'mui-datatables'
import FontAwesomeIconButtonDefault from '../../../components/buttonWithFontAwesomeIcon';
import { NotificationManager } from 'react-notifications'
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import 'react-notifications/lib/notifications.css';
import SweetAlert from "react-bootstrap-sweetalert";


function InvoiceSeries() {
    const history = useHistory();
    const [providersList, setProvidersList] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [currentProvider, setCurrentProvider] = useState()
    const [currentProviderName, setCurrentProviderName] = useState()


    useEffect(() => {
        getProviders().then(res => {
            setProvidersList(res.data)
        })
    }, [])

    const options = {
        selectableRows: false,
        onDownload: (buildHead, buildBody, columns, data) =>
            buildHead(columns) +
            buildBody(
                providersList.map((currentProvider) => {
                    currentProvider.status = currentProvider.status ? 'Ativo' : 'Inativo';
                    return {
                        data: [
                            currentProvider.socialName,
                            currentProvider.socialCode,
                            currentProvider.email,
                            currentProvider.status
                        ],
                    };
                })
            ),
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado!",
                toolTip: "Ordenação",
            },
            pagination: {
                next: "Próxima página",
                previous: "Página Anterior",
                rowsPerPage: "Linhas por página: "
            },
            filter: {
                all: "Todos resultados",
                title: "Seus filtros",
                reset: "",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Download csv",
                filterTable: "Filtrar tabela",
                print: "Imprimir",
                viewColumns: "Filtro de coluna",
            },
        },
    };

    const columns = [
        {
            name: "Nome",
            options: {
                filter: true,
            },
        },
        {
            name: "CPF / CNPJ / ID estrangeiro",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Email",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Status",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Ações",
            options: {
                filter: false,
                sort: false,
                print: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
    ];

    const page = (
        <div>
            <NotificationContainer />
            <SweetAlert
                show={showAlert}
                custom
                showCancel
                showCloseButton
                confirmBtnText="Sim"
                cancelBtnText="Não"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="danger"
                title="Confirmar ação"
                btnSize="md"
                onConfirm={() => {
                    setShowAlert(false)
                    getProviderById(currentProvider).then(({ data }) => {
                        data.status ?
                            deactiveStatus(currentProvider).then(res => {
                                NotificationManager.success('Status alterado com sucesso');
                                getProviders().then(res => {
                                    setProvidersList(res.data)
                                })
                            })
                                .catch(err => {
                                    NotificationManager.error(err);
                                })
                            :
                            activateStatus(currentProvider).then(res => {
                                NotificationManager.success('Status alterado com sucesso');
                                getProviders().then(res => {
                                    setProvidersList(res.data)
                                })
                            })
                                .catch(err => {
                                    NotificationManager.error(err);
                                })
                    })
                }}
                onCancel={() => setShowAlert(false)}
            >
                {`Você realmente deseja alterar o status do fornecedor?`}
            </SweetAlert>
            <div className="row align-items-center ml-md-3">
                <div className="col-12">
                    <div className="row d-flex justify-content-sm-center justify-content-md-start">
                        <div className="col-12 col-md-11 m-0 p-0 d-flex flex-wrap">
                            <div className="col-12 p-0 d-flex justify-content-between align-items-center flex-wrap mt-3">
                                <h1 style={{ color: "#002c43" }}>
                                    <FontAwesomeIcon
                                        className="back-icon mr-3 icon-hover"
                                        icon={faArrowLeft}
                                        size="1x"
                                        onClick={() => history.push("/home")}
                                        color="#002c43"
                                        style={{ hover: "cursor: pointer" }}
                                    />
                                    Fornecedores
                                </h1>
                                <FontAwesomeIconButtonDefault
                                    buttonData={{
                                        variant: "info",
                                        text: "Novo fornecedor",
                                        action: () => history.push('/registration/provider/create'),
                                        icon: (
                                            <FontAwesomeIcon icon={faPlus} />
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-11 p-0">
                            <MUIDataTable
                                title={"Fornecedores"}
                                data={providersList.map((currentProvider, index) => {
                                    return [
                                        currentProvider.socialName,
                                        currentProvider.socialCode,
                                        currentProvider.email,
                                        <SwitcherDefault
                                            switcherData={{
                                                checked: currentProvider.status,
                                                id: currentProvider.id,
                                                action: () => {
                                                    setShowAlert(true)
                                                    setCurrentProvider(currentProvider.id)
                                                    setCurrentProviderName(currentProvider.socialName)
                                                }
                                            }}
                                        />,
                                        <div style={{ textAlign: "start" }}>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                color="#878787"
                                                style={{ cursor: 'pointer' }}
                                                size="lg"
                                                title="Visualizar os dados do fornecedor"
                                                onClick={() => {
                                                    history.push(`/registration/provider/view/${currentProvider.id}`)
                                                }}
                                            />
                                            {
                                                currentProvider.status ? (
                                                    <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                        color="#878787"
                                                        className="ml-2"
                                                        style={{ cursor: 'pointer' }}
                                                        size="lg"
                                                        title="Alterar os dados do fornecedor"
                                                        onClick={() => {
                                                            history.push(`/registration/provider/edit/${currentProvider.id}`)
                                                        }}
                                                    />
                                                ) : ''
                                            }
                                        </div>,
                                    ];
                                })}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <div>
            <PageDefault pageData={{
                pageContent: page
            }} />
        </div>
    );
}

export default InvoiceSeries;

