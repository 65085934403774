import '../App.css';
import { TypographDefault } from '../../../components/typograph';
import TermsContent from '../../../components/termsContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom'

function UseTerms() {
    const history = useHistory()

    return (
        <div className="row d-flex justify-content-center">
            <div className="row col-11 col-md-8 m-0 p-0">
                <div className="row col-12 m-0 p-0">
                    <div className="m-0 p-0 d-flex align-items-center flex-wrap mt-3">
                        <h1 style={{ color: "#002c43" }}>
                            <FontAwesomeIcon
                                className="back-icon mr-3"
                                icon={faArrowLeft}
                                size="1x"
                                onClick={() => history.push("/login")}
                                color="#002c43"
                            />
                        </h1>
                    </div>
                    <img src="/logomei.svg" className="img-fluid mx-2" alt="Logo do portal do MEI" width={300} />
                </div>

                <div className="col mt-5 d-flex align-items-center justify-content-center flex-wrap">
                    <TypographDefault typographData={{
                        type: 'display-5 font-weight-bold',
                        colorText: '#002c43',
                        text: 'Termos de uso e privacidade'
                    }} />
                </div>
                <div className="col-12 my-3 text-muted" id="use-terms-content">
                    <TermsContent />
                </div>
            </div>
        </div>
    );
}

export default UseTerms;
