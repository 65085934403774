import { useEffect, useState } from "react";

const eventMapping = {
  onClick: "click",
  onDoubleClick: "dblclick",
};

export default function useGoogleMapMarker({
  position,
  type,
  maps,
  map,
  events,
  title,
  nome,
  address,
  email,
  local,
  telefone,
}) {
  const [marker, setMarker] = useState();
  useEffect(() => {
    const marker = new maps.Marker({
      position,
      map,
      title,
      nome,
      address,
      email,
      local,
      telefone,
    });

    let contentString = `

    <h2 id="titulo-infowindow">${marker.nome}</h2>
      <p class="local-mapa">${marker.local}</p>
      <p class="adrress-mapa">${marker.address}</p>
      <p class="telefone-mapa">${marker.telefone}</p>
      <p class="email-mapa">${marker.email}</p>

      `;

    const infowindow = new maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
        title,
        nome,
        address,
        email,
        local,
        telefone,
      });
    });

    Object.keys(events).forEach((eventName) =>
      marker.addListener(eventMapping[eventName], events[eventName])
    );
    setMarker(marker);
  }, []);

  return marker;
}
