import React from "react";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';


const ButtonDefault = (props) => {
  const {
    variant,
    text,
    action,
    icon,
    iconIMG,
    size,
    iconColor,
    textColor,
    iconSize,
    _class,
    goesTo,
    weight,
    disabled,
    offHover
  } = props.buttonData;

  return (
    <Button
      size={size}
      className={_class}
      style={{ color: textColor, fontWeight: weight}}
      variant={variant}
      disabled={disabled}
      onClick={() => {
        action()
      }}
    >
      {iconIMG ? <img src={iconIMG} className="mr-2" alt="" /> :
        icon && <i className={icon.concat(" mr-2")} style={{ color: iconColor, fontSize: iconSize }}></i>}
      {/* {} */}
      <Link to={goesTo} style={{ textDecoration: "none", color: textColor }}>
        {text}
      </Link>
    </Button>
  );
};

export default ButtonDefault;
