import React, { useEffect, useState } from "react";
import PageDefault from "../../components/pageDefault";
import { useHistory } from "react-router-dom";
import ProfileMenuPage from "../../modules/profileMenu/pages/ProfileMenu";

function ProfileMenu() {
  const [foundData, setFoundData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setFoundData(true);
  }, []);

  const home = () => {
    history.push("/home");
  };

  const page = (
    <div className="row m-0">
      {/* <h1 style={{ color: "#002c43" }}>
        <FontAwesomeIcon
          className="back-icon mr-3"
          icon={faArrowLeft}
          size="1x"
          onClick={home}
          color="#002c43"
        />
        PROFILE MENU
      </h1> */}

      {foundData ? (
        <div className="mt-4 col-10">{<ProfileMenuPage />}</div>
      ) : (
        <div className="mt-4">
          <h1>Nenhuma informação foi encontrada</h1>
        </div>
      )}
    </div>
  );
  return (
    <div>
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
    </div>
  );
}

export default ProfileMenu;
