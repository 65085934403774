import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import "../../modules/profileMenu/pages/fonteSourceSansPro.css";
import help from "../../imgs/icons/planoPagamento/help.svg";
import Avatar from "../../imgs/icons/Avatar.svg";
import notifications from "../../imgs/icons/notifications.svg";
import { getAssociated } from "../../services/associate";
import { Link } from "react-router-dom";

export default function ProfileMenuHeader() {
  const [socialName, setSocialName] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  useEffect(() => {
    getAssociated().then(({ data }) => {
      const { socialName, contact } = data;
      setSocialName(socialName);
      setContactEmail(contact.email);
    });
  }, []);

  const renderName = (name) => {
    return name.length > 16 ? name.substring(0, 16) + "..." : name;
  };

  return (
    <Box
      sx={{
        height: "150px",
        width: "75vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "60px",
      }}
    >
      <Box>
        <Link to="/consultancy" className="linkCard">
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#EBEBEB",
              color: "#7B7B7B",
              border: "none",
              height: 50,
              fontFamily: "Source Sans Pro",
              fontSize: "18px",
              textTransform: "none !important",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#7B7B7B",
                border: "1px solid #7B7B7B",
              },
            }}
          >
            Precisa de ajuda
            <img src={help} style={{ paddingLeft: "5px" }} />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          border={"2px solid #7B7B7B"}
          borderRadius={"50%"}
          width={40}
          height={40}
          display={"flex"}
          justifyContent={"center"}
          marginRight={"10px"}
        >
          <img src={Avatar} style={{ width: "25px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "55px",
          }}
        >
          <Box
            sx={{
              marginBottom: "-20px",
            }}
          >
            <p>
              <b
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#7B7B7B",
                }}
              >
                {renderName(socialName)}
              </b>
            </p>
          </Box>
          <Box>
            <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
              {contactEmail}
            </p>
          </Box>
        </Box>
        <Box
          width={40}
          height={40}
          borderRadius={50}
          bgcolor={"#EBEBEB"}
          marginLeft={1}
          display={"flex"}
          justifyContent={"center"}
        >
          <img src={notifications} style={{ width: "25px" }} />
        </Box>
      </Box>
    </Box>
  );
}
