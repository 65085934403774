import React from "react";
import { Button } from "react-bootstrap";


const FontAwesomeIconButtonDefault = (props) => {
    const {
        variant,
        text,
        action,
        icon,
        _class,
        disabled
    } = props.buttonData;

    return (
        <Button
            className={_class}
            variant={variant}
            disabled={disabled}
            onClick={() => {
                action()
            }}
        >
            <div className={ text ? "d-flex flex-row" : "" } >
                <div>
                    {icon}
                </div>
                {
                    text && (
                        <div className="ml-2">
                            {text}
                        </div>
                    )
                }
            </div>
        </Button>
    );
};

export default FontAwesomeIconButtonDefault;
