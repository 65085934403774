import "../App.css";
import PageDefault from "../../../components/pageDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faPencilAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SwitcherDefault from "../../../components/switcher";
import FontAwesomeIconButtonDefault from "../../../components/buttonWithFontAwesomeIcon";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import {
  activateProduct,
  deactivateProduct,
  getProdcutById,
  getProducts,
} from "../../../services/register/products";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";

function Product() {
  const history = useHistory();
  const [currentProduct, setCurrentProduct] = useState();
  const [products, setProducts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getProducts().then(({ data }) => setProducts(data));
  }, []);

  const options = {
    selectableRows: false,
    onDownload: (buildHead, buildBody, columns) =>
      buildHead(columns) +
      buildBody(
        products.map((currentProduct) => {
          return {
            data: [
              currentProduct.code,
              currentProduct.name,
              currentProduct.associatedProvider.socialName,
              currentProduct.status ? "Ativo" : "Inativo",
            ],
          };
        })
      ),
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página: ",
        displayRows: "de",
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: "",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
    download: false,
    downloadOptions: { filename: "Produtos.csv" },

    sortOrder: {
      name: "Nome",
      direction: "asc",
    },
  };

  const columns = [
    {
      name: "Código do produto",
      options: {
        filter: true,
      },
    },
    {
      name: "Nome",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Fornecedor",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Ações",
      options: {
        filter: false,
        sort: false,
        print: false,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        download: false,
      },
    },
  ];

  const page = (
    <div className="row ml-lg-3">
      <NotificationContainer />
      <SweetAlert
        custom
        showCancel
        showCloseButton
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        title="Confirmar ação"
        btnSize="md"
        show={showAlert}
        onConfirm={() => {
          setShowAlert(false);
          getProdcutById(currentProduct).then(({ data }) => {
            data.status
              ? deactivateProduct(currentProduct)
                  .then((res) => {
                    if (res.status === 204) {
                      NotificationManager.success(
                        "Status alterado com sucesso"
                      );
                      getProducts().then((res) => {
                        setProducts(res.data);
                      });
                    }
                  })
                  .catch((err) => {
                    NotificationManager.error(err);
                  })
              : activateProduct(currentProduct)
                  .then((res) => {
                    if (res.status === 204) {
                      NotificationManager.success(
                        "Status alterado com sucesso"
                      );
                      getProducts().then((res) => {
                        setProducts(res.data);
                      });
                    }
                  })
                  .catch((err) => {
                    NotificationManager.error(err);
                  });
          });
        }}
        onCancel={() => setShowAlert(false)}
      >
        Você realmente deseja alterar o status do produto?
      </SweetAlert>
      <div className="d-flex justify-content-between align-items-center flex-wrap col-11 mt-3 p-0">
        <h1 style={{ color: "#002c43" }}>
          <FontAwesomeIcon
            className="back-icon mr-3 icon-hover"
            icon={faArrowLeft}
            size="1x"
            onClick={() => history.push("/home")}
            color="#002c43 icon-hover "
          />
          Produtos
        </h1>
        <FontAwesomeIconButtonDefault
          buttonData={{
            variant: "info",
            text: "Novo produto",
            action: () => history.push("/registration/product/create"),
            icon: <FontAwesomeIcon icon={faPlus} />,
          }}
        />
      </div>
      <div className="row col-11 p-0 mt-3 m-0">
        <MUIDataTable
          className="col-12"
          title={"Produtos"}
          data={products.map((currentProduct, index) => {
            return [
              currentProduct.code,
              currentProduct.name,
              currentProduct?.associatedProvider?.socialName,
              <SwitcherDefault
                switcherData={{
                  checked: currentProduct.status,
                  id: currentProduct.id,
                  action: () => {
                    setCurrentProduct(currentProduct.id);
                    setShowAlert(true);
                  },
                }}
              />,
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon={faEye}
                  color="#878787"
                  style={{ cursor: "pointer" }}
                  size="lg"
                  title="Visualizar os dados do produto"
                  onClick={() => {
                    history.push(
                      `/registration/product/view/${currentProduct.id}`
                    );
                  }}
                />
                {currentProduct.status && (
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    color="#878787"
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                    size="lg"
                    title="Alterar os dados do produto"
                    onClick={() => {
                      history.push(
                        `/registration/product/edit/${currentProduct.id}`
                      );
                    }}
                  />
                )}
              </div>,
            ];
          })}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );

  return (
    <PageDefault
      pageData={{
        pageContent: page,
      }}
    />
  );
}

export default Product;
