import { api } from "../connection/api";

export async function putResetPassword(email) {
  const data = await api.put("associate/reset_password", { email });

  return data;
}

export async function getAssociated() {
  const data = await api.get("associate");

  return data;
}

export async function postAssociateInAcsp(userAssociate) {
  const data = await api.post(`associate/associat-acsp`, userAssociate);

  return data;
}

export async function verifyEmail(email) {
  const data = await api.get(`associate/verifyEmail?email=${email}`);

  return data;
}

export async function getAssociateByEmail(email) {
  const data = await api.get(`associate/getUserByEmail?email=${email}`);

  return data;
}

export async function putUpdatePassword(dataToken) {
  const data = await api.put("associate/update_password", dataToken);

  return data;
}

export async function postCreateAssociate(associate) {
  return api({
    method: "post",
    timeout: 700000,
    url: "/associate",
    data: associate,
  });
}

export async function postLogin(user) {
  return await api.post("associate/login", user);
}

export async function putActiveRegister(confirmationToken) {
  const data = await api.put("associate/active_register", {
    confirmationToken,
  });

  return data;
}

export async function putSendActavionToken(email) {
  const data = await api.put("associate/send_activation_token", email);

  return data;
}

export async function getCertificate() {
  const data = await api.get("associate/certificate");

  return data;
}

export async function putSolicitationUpdateEmail(sendData) {
  const data = await api.put("associate/solicitation_update_email", sendData);

  return data;
}

export async function putUpdateEmail(sendData) {
  const data = await api.put("associate/update_email", sendData);

  return data;
}

export async function putAlterPassword(passwords) {
  const data = await api.put("associate/alter_password", passwords);

  return data;
}

export async function putSolicitationInactiveAccount(accountDetails) {
  const data = await api.put(
    "associate/solicitation_inactive_account",
    accountDetails
  );

  return data;
}

export async function putInactiveAccount(accountDetails) {
  const data = await api.put("associate/inactive_account", accountDetails);

  return data;
}
