import { api } from '../connection/api'

export async function getProducts() {
  return api.get(`register/associated-products`);
}

export async function createProduct(data) {
  return api.post(`register/associated-products`, data);
}
export async function validateProduct(data) {
  return api.post(`register/associated-products/validate`, data);
}
export async function getProdcutById(id) {
  return api.get(`register/associated-products/${id}`);
}

export async function updateProduct(data) {
  return api.put(`register/associated-products/${Number(data.id)}`, data);
}
export async function consultaGtinNcmVinco(gtin,ncm) {
  let params = {};
  if(gtin){
    params = {
      gtin: gtin
    }
  }
  if(ncm){
    params = {
      ncm: ncm
    }
  }

 return api({
    method: 'get',
    url: 'register/associated-products/consultGtinNcm',
    params: params,
  });

}

export async function activateProduct(id) {
  return api.put(`register/associated-products/${id}/activate`);
}

export async function deactivateProduct(id) {
  return api.put(`register/associated-products/${id}/deactivate`);
}

export async function getProductOrigins() {
  return api.get(`register/product-origins`);
}

export async function getNcmCodes() {
  return api.get(`register/product-ncm-codes`);
}

export async function getProductUnitMeasurement() {
  return api.get(`register/product-unit-measurement`);
}

export async function findAllAssociatedProductsActive() {
  return api.get(`/register/associated-products/active`);
}
