import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
export default function AlertDialog({open,handleClose}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: { borderRadius: 25 }
        }}

      >
        <DialogTitle sx={{marginTop:4}} align="center" id="alert-dialog-title">
          <b>{"Procedimento Importante"}</b>
        </DialogTitle>
    <DialogContent sx={{marginBottom:2,fontSize:'14px'}} align="center">
      Olá, estamos atualizando nosso sistema para melhorar a sua experiência no Portal do Mei. Para que a emissão de notas continue funcionando, precisamos que você atualize os dados da sua empresa e de seus produtos e serviços cadastrados.
        <br /> <br />Segue abaixo o passo a passo e veja como é fácil.
    </DialogContent>

        <DialogTitle align="center">
          <b>Atualização de empresa</b>
          </DialogTitle>
          <DialogContent>
          <DialogContentText align="center"    sx={{marginBottom:4,fontSize:'14px', color:'#000'}} id="alert-dialog-description">
         No menu lateral, vá em <b>"Meu MEI"</b> e depois em <b>"Dados da sua Empresa"</b> e clique
        no botão <b>"Solicitar Atualização"</b>
          </DialogContentText>

          <DialogTitle align='center' >
          <b>Produtos</b>
          </DialogTitle>
          <DialogContentText align="center"   sx={{marginBottom:4,fontSize:'14px', color:'#000'}} >

          No menu lateral, clique em <b>"Cadastros"</b>, depois em <b>"Produtos"</b> e clique em <br /> editar(<EditIcon fontSize='5' />) e depois em Salvar.
           Clicar em "Editar" e Salvar
          </DialogContentText>
          <DialogTitle align="center">
          <b>Serviço</b>
          </DialogTitle>
          <DialogContentText  sx={{marginBottom:4,fontSize:'14px', color:'#000'}} align="center" >
         No menu lateral, clique em <b>"Cadastros"</b>, depois em Serviços e clique em editar(<EditIcon fontSize='5' />) e depois em Salvar.
           Clicar em "Editar" e Salvar
          </DialogContentText>

          <DialogContentText sx={{color:"#D92C6B",marginBottom:4}} align="center">
          <b>Importante:</b> Obrigatório editar os dados de todos os produtos e/ou serviços cadastrados.
          </DialogContentText>

          <DialogContentText align="center" sx={{fontSize:'14px', color:'#000'}}>
          Em caso de dúvidas, entre em contato: <br /><b>
          portaldomei@acsp.com.br  <br />
          Whatsapp (11) 3180-3737
          </b>
          </DialogContentText>
        </DialogContent>
        <DialogContentText sx={{height:75}} align='center'>
          <Button  variant="contained"  sx={{width:150,backgroundColor:"#58C5C6",fontColor:"#FFF",     "&:hover": {
      backgroundColor: "#58C5C6"
    }}} onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogContentText>
      </Dialog>
    </div>
  );
}
//   return (
//     <div>
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open dialog
//       </Button>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={true}
//       >
//         <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//           Procedimento Importante
//         </BootstrapDialogTitle>
//         <DialogContent>
//         <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//           Atualização de Empresa
//         </BootstrapDialogTitle>
//           <Typography gutterBottom>
//             Menu {'>'} Meu Mei {'>'} Dados da sua Empresa <br />
//             Clicar no botão "Solicitar Atualização"
//           </Typography>
//           <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//           Produto
//         </BootstrapDialogTitle>
//           <Typography gutterBottom>
//            Menu {'>'} Cadastro {'>'} Produtos {'>'} Ações
//            Clicar em "Editar" e Salvar
//           </Typography>
//           <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//            Serviço
//         </BootstrapDialogTitle>
//           <Typography gutterBottom>
//       Menu {'>'} Cadastro {'>'} Serviços {'>'} Ações
//       Clicar em "Editar" e Salvar
//           </Typography>
//         </DialogContent>

//         <Typography gutterBottom>
//      Em caso de dúvidas entre em contato:
//      suportemei@acsp.com.br
//           </Typography>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose}>
//             OK
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </div>
//   );
// }
