import React, { useEffect, useMemo, useState } from "react";
import ButtonDefault from "../buttonDefault";
import CollapseButton from "../collapseButton";
import CollapseDefault from "../collapse";
import DrawerDefault from "../drawerDefault";
import NavbarDefault from "../navbarDefault";
import { useHistory } from "react-router-dom";
import ButtonSidebar from "../buttonSidebar";
import iconsIMG from "../ImgImport/index";
import PopoverUser from "../navBarPopovers/popoverUser/index";
import PopoverNotify from "../navBarPopovers/popoverNotify/index";
import PopoverSchedule from "../navBarPopovers/popoverSchedule/index";
import { getCertificate } from "../../services/associate/index";
import jwt_decode from "jwt-decode";
import "./style.css";

const PageDefault = (props) => {
  // if(!props.shouldNotScroll){
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }

  const history = useHistory();

  const associated = useMemo(() => {
    const token = localStorage.getItem("jwt_auth");

    const decoded = jwt_decode(token);
    return decoded;
  }, []);

  const [drawer, setDrawer] = useState({
    logo: {
      url: "/logomei.svg",
      alt: "Logo do portal MEI",
    },
    logoHeight: 100,
    iconsSize: "1.5rem",
    iconsColor: "#75cbdc",
    _class: "border border-0 shadow p-0 m-0 borda-divisoria pt-4",
    listItems: [
      {
        key: "initialPage",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Visão Geral",
              action: () => history.push("/home"),
              icon: iconsIMG.VisaoGeral,
              iconHover: iconsIMG.VisaoGeralHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
            }}
          />
        ),
      },
      {
        key: "MEI",
        item: (
          <CollapseDefault
            collapseData={{
              textButton: "Meu MEI",
              iconIMG: iconsIMG.MEI,
              iconIMGHover: iconsIMG.MEIHover,
              marcacao: iconsIMG.Marcacao,
              textColor: "#404040",
              title: "1",
              iconSize: "1.8em",
              components: [
                {
                  key: "profile",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Dados da sua empresa",
                        action: () => history.push("/profile"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                // {
                //   key: "certificatedMEI",
                //   component: (
                //     <CollapseButton
                //       buttonData={{
                //         variant: "white",
                //         text: "Emitir certificado de MEI",
                //         action: () => {
                //           getCertificate().then((response) => {
                //             window.open(
                //               response.data.certificate,
                //               "_blank",
                //               "noopener,noreferrer"
                //             );
                //           });
                //         },
                //         _class: "btn-menu text-left col-12",
                //         textColor: "rgb(135, 135, 135)",
                //       }}
                //     />
                //   ),
                // },
                {
                  key: "digitalCertificate",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Salvar certificado digital",
                        action: () =>
                          history.push("/profile/digital_certificate"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
              ],
            }}
          />
        ),
      },
      {
        key: "generalInformation",
        item: (
          <CollapseDefault
            collapseData={{
              textButton: "Cadastros",
              iconIMG: iconsIMG.Cadastros,
              iconIMGHover: iconsIMG.CadastrosHover,
              marcacao: iconsIMG.Marcacao,
              textColor: "#404040",
              iconColor: "#58c5c6",
              iconSize: "1.8em",
              components: [
                {
                  key: "product",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Produtos",
                        action: () => history.push("/registration/product"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "service",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Serviços",
                        action: () => history.push("/registration/service"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "client",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Clientes",
                        action: () => history.push("/registration/client"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "prodiver",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Fornecedores",
                        action: () => history.push("/registration/provider"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
              ],
            }}
          />
        ),
      },
      {
        key: "financial",
        item: (
          <CollapseDefault
            collapseData={{
              textButton: "Finanças",
              iconIMG: iconsIMG.Financas,
              iconIMGHover: iconsIMG.FinancasHover,
              marcacao: iconsIMG.Marcacao,
              textColor: "#404040",
              title: "3",
              iconSize: "1.8em",
              components: [
                {
                  key: "invoiceProduct",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Notas fiscais de produto",
                        action: () =>
                          history.push("/financial/invoice_product"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "invoiceService",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Notas fiscais de serviço",
                        action: () =>
                          history.push("/financial/invoice_service"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "billReciver",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Contas a receber",
                        action: () =>
                          history.push("/financial/bills_to_receive"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "billPay",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Contas a pagar",
                        action: () => history.push("/financial/bills_to_pay"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "financialAnalysis",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Análise financeira",
                        action: () =>
                          history.push("/financial/financial_analysis"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "das",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Pagar imposto DAS",
                        action: () => history.push("/financial/pay_tax"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
                {
                  key: "dasn",
                  component: (
                    <CollapseButton
                      buttonData={{
                        variant: "white",
                        text: "Declaração anual - DASN",
                        action: () =>
                          history.push("/financial/annual_declaration"),
                        _class: "btn-menu text-left col-12",
                        textColor: "rgb(135, 135, 135)",
                      }}
                    />
                  ),
                },
              ],
            }}
          />
        ),
      },
      {
        key: "service",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Serviços",
              action: () => history.push("/service"),
              icon: iconsIMG.Servicos,
              iconHover: iconsIMG.ServicosHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
              goesTo: " ",
            }}
          />
        ),
      },
      {
        key: "training",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Capacitação",
              action: () => history.push("/training"),
              icon: iconsIMG.Capacitacao,
              iconHover: iconsIMG.CapacitacaoHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
            }}
          />
        ),
      },
      {
        key: "consultancy",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Me ajuda",
              action: () => history.push("/consultancy"),
              icon: iconsIMG.MeAjuda,
              iconHover: iconsIMG.MeAjudaHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
            }}
          />
        ),
      },
      {
        key: "legalInformation",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Sobre",
              action: () => history.push("/information/legal_information"),
              icon: iconsIMG.Sobre,
              iconHover: iconsIMG.SobreHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
              goesTo: " ",
            }}
          />
        ),
      },
      {
        key: "space",
        item: (
          <div className="d-flex">
            <div className="d-flex col-3 border-left border-right bg-light align-items-center justify-content-center"></div>
            <div className="d-flex col-9 pt-5 pb-5 pl-3"></div>
          </div>
        ),
      },
      {
        key: "legalInformation",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Perfil",
              action: () => history.push("/profileMenu"),
              icon: iconsIMG.Perfil,
              iconHover: iconsIMG.PerfilHover,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#404040",
              goesTo: " ",
            }}
          />
        ),
      },
      {
        key: "loggout",
        item: (
          <ButtonSidebar
            buttonData={{
              variant: "white",
              text: "Sair",
              action: () => {
                localStorage.removeItem("jwt_auth");
                history.push("/login");
              },
              icon: iconsIMG.Sair,
              marcacao: iconsIMG.Marcacao,
              _class: "btn-menu text-left col-9",
              textColor: "#FF0000",
            }}
          />
        ),
      },
      // {
      //   key: "logo",
      //   item: (
      //     <div className="d-flex">
      //       <div className="d-flex col-3 border-left border-right bg-light align-items-center justify-content-center"></div>
      //       <div className="d-flex col-9 align-items-center justify-content-center pt-5 pb-5">
      //         <img src={iconsIMG.LogoACSP} className="logo" alt="Logo ACSP" />
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   key: "space",
      //   item: (
      //     <div className="d-flex">
      //       <div className="d-flex col-3 border-left border-right bg-light align-items-center justify-content-center"></div>
      //       <div className="d-flex col-9 pt-5 pb-5 pl-3"></div>
      //     </div>
      //   ),
      // },
    ],
  });
  const [navbar, setNavbar] = useState({
    _class: "row m-0 border border-0 shadow ",
    leftComponents: [],
    rightComponents: [
      {
        key: "User",
        component: <PopoverUser data={associated} />,
      },
    ],
  });

  const { pageContent } = props.pageData;

  return (
    <div className="grid">
      <div
        className="drawerSidebar"
        style={{
          margin: "0!important",
          minWidth: "20vw",
        }}
      >
        <DrawerDefault id="sidebar-wrapper" drawer={drawer} />
      </div>

      <div className="header">
        <NavbarDefault navbarData={navbar} />
      </div>

      {/* <div className="d-flex"> */}
      {/* <div className="d-flex col-3 border-left border-right bg-light align-items-center justify-content-center"></div> */}
      {/* <div className="d-flex col-9 pt-5 pb-5 pl-3"></div> */}
      {/* </div> */}

      <div className="mobileCollapse">
        <CollapseDefault
          collapseData={{
            textButton: "Abrir Menu",
            icon: "bi bi-list",
            textColor: "#404040",
            title: "4",
            iconSize: "1.8em",
            components: [
              {
                key: "drawer",
                component: (
                  <DrawerDefault id="sidebar-wrapper" drawer={drawer} />
                ),
              },
            ],
          }}
        />
      </div>
      <div id="content" className="p-4">
        {pageContent}
      </div>
    </div>
  );
};

export default PageDefault;
