import { React, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ButtonDefault from "../buttonDefault";
import FontAwesomeIconButtonDefault from "../buttonWithFontAwesomeIcon";

const Dialog = (props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);

    if (props.dialogData.customSetState) {
      props.dialogData.customSetState(false);
    }
  };

  const { buttonText, buttonClass } = props.dialogData;
  const { buttonVariant } = props.dialogData;
  const { buttonFontColor } = props.dialogData;
  const { dialogTitle } = props.dialogData;
  const { dialogBody } = props.dialogData;

  const { showCancelButton } = props.dialogData;
  const { closeButtonText } = props.dialogData;
  const { closeButtonVariant } = props.dialogData;
  const { closeButtonAction } = props.dialogData;
  const { showCloseButton } = props.dialogData;

  const { confirmButtonText } = props.dialogData;
  const { confirmButtonVariant } = props.dialogData;
  const { confirmButtonAction } = props.dialogData;
  const { showModal, size } = props.dialogData;
  const { onclose } = props.dialogData;

  const { action } = props.dialogData;
  const { icon } = props.dialogData;

  const [showHeader, setShowHeader] = useState(props.dialogData.showHeader);
  const [showBody, setShowBody] = useState(props.dialogData.showBody);
  const [showFooter, setFooter] = useState(props.dialogData.showFooter);
  const [haveIcon, setHaveIcon] = useState(props.dialogData.haveIcon);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <div>
      {props.dialogData.closeModal === false ?? handleClose}

      {haveIcon ? (
        <FontAwesomeIconButtonDefault
          buttonData={{
            variant: buttonVariant,
            text: buttonText,
            action: () => handleShow(),
            icon: icon,
          }}
        />
      ) : (
        <ButtonDefault
          buttonData={{
            variant: buttonVariant,
            action: () => [handleShow(), action],
            text: buttonText,
            textColor: buttonFontColor,
            _class: buttonClass,
          }}
        />
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={size}
        onExit={onclose}
        centered
      >
        {showHeader ? (
          <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="text-muted">{dialogTitle}</Modal.Title>
          </Modal.Header>
        ) : (
          ""
        )}
        {showBody ? (
          <Modal.Body className="text-muted">{dialogBody}</Modal.Body>
        ) : (
          ""
        )}
        {showFooter ? (
          <Modal.Footer>
            {showCancelButton ? (
              <Button
                variant={closeButtonVariant}
                onClick={() => {
                  handleClose();
                  closeButtonAction();
                }}
              >
                {closeButtonText}
              </Button>
            ) : null}

            <ButtonDefault
              variant={confirmButtonVariant}
              onClick={() => {
                handleClose();
                confirmButtonAction();
              }}
            >
              {confirmButtonText}
            </ButtonDefault>
          </Modal.Footer>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default Dialog;
