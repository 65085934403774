import React from "react"
import { Bar } from 'react-chartjs-2';

export default function chart(props) {

  const { data } = props
  const state = {
    labels: [
      'Jan', 'Fev', 'Mar',
      'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set',
      'Out', 'Nov', 'Dez'
    ],
    datasets: [
      {
        label: 'NF-e',
        backgroundColor: 'rgb(31, 120, 180)',
        data: data.products
      },
      {
        label: 'NFS-e',
        backgroundColor: 'rgb(24, 196, 121)',
        data: data.services
      },
      {
        label: 'Total',
        backgroundColor: 'rgb(100, 194, 200)',
        data: data.total
      },
    ]
  }

  return (
    <Bar
      data={state}
      height={75}
      options={{
        responsive: true,
        title: {
          display: false,
          text: 'Notas',
          fontSize: 20
        },
        legend: {
          display: true,
          position: 'top'
        },
      }}
    />
  )
}