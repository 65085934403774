import { api } from '../connection/api'

const baseEndpoint = 'register/associated-client';

export async function fetchAssociatedClients() {
    return await api.get(baseEndpoint);
}

export async function getAssociatedClient(id) {
    return await api.get(`${baseEndpoint}/${id}`);
}

export async function postAssociatedClient(associatedClient) {
    return await api.post(baseEndpoint, associatedClient);
}

export async function postAssociatedProvider(associatedClient) {
    return await api.post('register/associated-provider', associatedClient);
}

export async function putAssociatedClient(id, associatedClient) {
    return await api.put(`${baseEndpoint}/${id}`, associatedClient);
}

export async function putActivate(id) {
    return await api.put(`${baseEndpoint}/${id}/activate`);
}

export async function putDeactivate(id) {
    return await api.put(`${baseEndpoint}/${id}/deactivate`);
}

export async function findAllAssociatedClientActive() {
    return api.get(`${baseEndpoint}/active`);
}