import '../App.css';
import PageDefault from "../../../components/pageDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faEye,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import FontAwesomeIconButtonDefault from '../../../components/buttonWithFontAwesomeIcon';
import MUIDataTable from 'mui-datatables';

function InvoiceDevolution() {
    const history = useHistory()

    const options = {
        selectableRows: false,
        onDownload: (buildHead, buildBody, columns, data) =>
            buildHead(columns) +
            buildBody(data),
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado!",
                toolTip: "Ordenação",
            },
            pagination: {
                next: "Próxima página",
                previous: "Página Anterior",
                rowsPerPage: "Linhas por página: "
            },
            filter: {
                all: "Todos resultados",
                title: "Seus filtros",
                reset: "",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Download csv",
                filterTable: "Filtrar tabela",
                print: "Imprimir",
                viewColumns: "Filtro de coluna",
            },
        },
    };

    const columns = [
        {
            name: "Série - NF",
            options: {
                filter: true,
            },
        },
        {
            name: "Data de emissão",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Cliente",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Valor da nota:",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Situação:",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
        {
            name: "Ações:",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                setCellHeaderProps: () => ({ style: { textAlign: "start" } }),
            },
        },
    ];
    const page = (
        <div className="row ml-lg-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap col-10 mt-3 p-0">
                <h1 style={{ color: "#002c43" }}>
                    <FontAwesomeIcon
                        className="back-icon mr-3 icon-hover"
                        icon={faArrowLeft}
                        size="1x"
                        onClick={() => history.push("/home")}
                        color="#002c43 icon-hover "
                    />
                    Notas fiscais de devolução
                </h1>
                <FontAwesomeIconButtonDefault
                    buttonData={{
                        variant: "info",
                        text: "Nova nota fiscal",
                        action: () => history.push('/financial/invoice_devolution/create'),
                        icon: (
                            <FontAwesomeIcon icon={faPlus} />
                        ),
                    }}
                />
            </div>

            <MUIDataTable
                className="col-12 col-md-10 mt-3"
                title={"Fornecedores"}
                print={true}
                data={['1', 's'].map((currentProvider) => {
                    return [
                        'NF',
                        '02/12/2020 14:46:47',
                        'Reinaldo Menezes Oliveira',
                        'R$ 328,00',
                        'Emitida com sucesso',
                        <div style={{ textAlign: "start" }}>
                            <FontAwesomeIcon
                                icon={faEye}
                                color="#878787"
                                style={{ cursor: 'pointer' }}
                                size="lg"
                                title="Visualizar os dados do fornecedor"
                                onClick={() => {
                                    history.push(`/registration/provider/view/${currentProvider.id}`)
                                }}
                            />
                        </div>,
                    ];
                })}
                columns={columns}
                options={options}
            />
        </div>
    )

    return (
        <PageDefault
            pageData={{
                pageContent: page
            }}
        />
    );
}

export default InvoiceDevolution;