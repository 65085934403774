import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import PageDefault from "../../../components/pageDefault";
import FileInput from "../../../components/fileInput";
import PasswordInput from "../../../components/passwordInput";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import { Button, Modal } from "react-bootstrap";
import AlertDefault from "../../../components/alertDefault";
import CertificateList from "../../../components/certificateList";
import {
  getDigitalCertificate,
  postDigitalCertificate,
} from "../../../services/digitalCertificate/index";

import * as yup from "yup";
import * as moment from "moment";
import db from "../db.json";
import "../App.css";
import { textAlign } from "@mui/system";

import Button from "@mui/material/Button";

function SaveCertificate() {
  const [password, setPassword] = useState("");
  const [validatePassword, setValidatePassword] = useState("");
  const [valid, setValid] = useState(true);
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  const [truthyPassword, setTruthyPassword] = useState(true);
  const [errorFile, setErrorFile] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [fetch, setFetch] = useState(true);
  const [messageAlert, setMessageAlert] = useState("");
  const history = useHistory();

  const [backErrors, setBackErrors] = useState({});
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };

  const home = () => {
    history.push("/home");
  };

  const handleClose = () => setShow(false);

  const handleDeleteCertificate = (certificate) => {
    setCertificates(certificates.filter((el) => el.id != certificate.id));
    setMessageAlert("Certificado removido com sucesso");
  };

  const handleChangeCertificate = () => {
    setMessageAlert("Senha alterada com sucesso");
  };

  const ValidationSchema = yup.object().shape({
    password: yup.string().min(4, "Mínimo 4 caracteres").required(),

    validatePassword: yup.string().min(4, "Mínimo 4 caracteres").required(),
  });

  useEffect(() => {
    if (!fetch) return;
    getDigitalCertificate()
      .then(({ data }) => {
        const { id, name, shelfLifeAt, status } = data;
        setCertificates([
          {
            id: id,
            file: name,
            valid: moment.utc(shelfLifeAt).format("DD/MM/YYYY"),
            status: status == "valid" ? true : false,
          },
        ]);
        setFetch(false);
      })
      .catch((err) => {
        setFetch(false);
      });
  }, [fetch]);

  useEffect(() => {
    clearBackErrors("password");
    clearBackErrors("validatePassword");

    if (password.length >= 4) {
      setTruthyPassword(true);
    }

    password === validatePassword ? setValid(true) : setValid(false);
  }, [password, validatePassword]);

  useEffect(() => {
    setErrorFile(false);
    clearBackErrors("file");
    setBackErrors({ file: null });
  }, [file]);

  const handleSaveCertificate = () => {
    const data = {
      file,
      password,
      validatePassword,
    };

    if (!file) {
      setErrorFile(true);
      return;
    }

    if (file.type != "application/x-pkcs12") {
      setBackErrors({ file: "O arquivo precisa ser do tipo pfx" });
      return;
    }
    if (password.length < 4) {
      setTruthyPassword(false);
      return;
    }

    if (password !== validatePassword) {
      setValid(false);
      return;
    }

    ValidationSchema.isValid(data).then((validForm) => {
      if (validForm) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("password", password);

        postDigitalCertificate(formData)
          .then((res) => {
            setFile(null);
            setPassword("");
            setValidatePassword("");
            setFetch(true);
            setMessageAlert("Certificado salvo com sucesso");
          })
          .catch((err) => {
            const errors = {};

            if (err.response)
              err.response.data.errors.forEach((item) => {
                const { fieldName, message } = item;

                if (fieldName == "digitalCertificte") errors.file = message;
                else errors[fieldName] = message;
              });

            setBackErrors(errors);
          });
      }
    });
  };

  const inactiveCertificate = () => {
    alert("inativar");
  };

  const page = (
    <div className="saveCertificateContainer">
      <div className="col alert alert-danger">
        Para emissão de nota fiscal é necessário possuir certificado digital,
        caso deseje contratar{" "}
        <a
          href="https://acsp.com.br/lp-certificado-home"
          className="alert-link"
          target="_blank"
        >
          clique aqui
        </a>{" "}
        e contrate de forma rápida e fácil.
      </div>
      <h1
        style={{
          fontSize: "32px",
          textAlign: "center",
          fontWeight: "600",
          marginBottom: "50px",
        }}
      >
        Salvar Certificado Digital
      </h1>
      <h1 style={{ fontSize: "24px", fontWeight: "600", marginBottom: "40px" }}>
        Importe o certificado digital A1 para utilizar em suas rotinas fiscais
      </h1>
      <div>
        <h3 style={{ fontSize: "16px", color: "#7B7B7B" }}>
          Importe o certificado digital A1:
        </h3>

        <FileInput
          icon={faCloudUploadAlt}
          onSelectFile={setFile}
          fileName={file ? file.name : null}
        />

        {(errorFile ||
          backErrors.file ||
          backErrors.shelfLifeAt ||
          backErrors.digitalCertificate) && (
          <p
            className="error"
            style={{ marginTop: "-30px", fontSize: "12px", color: "#D70F57" }}
          >
            {errorFile
              ? "Campo Obrigatório"
              : backErrors.file ||
                backErrors.shelfLifeAt ||
                backErrors.digitalCertificate}
            !
          </p>
        )}
      </div>
      <div className="row ">
        <div className="col-5" style={{ fontFamily: "Source Sans Pro" }}>
          <PasswordInput
            placeholder="Senha do Certificado Digital"
            title="Senha do certificado digital"
            onChangePassword={setPassword}
            password={password}
          />

          {truthyPassword ? "" : <p className="error"> Mínimo 4 caracteres</p>}
          {backErrors.password && (
            <p className="error"> {backErrors.password} </p>
          )}
        </div>
        <div className="col-5" style={{ fontFamily: "Source Sans Pro" }}>
          <PasswordInput
            placeholder="Confirme a senha"
            title="Confirme senha do certificado digital"
            onChangePassword={setValidatePassword}
            password={validatePassword}
          />
          <p className="error"> {valid ? "" : "Senhas não correspondem! "} </p>
          {backErrors.password && (
            <p className="error"> {backErrors.password} </p>
          )}
        </div>
      </div>

      <Button
        onClick={() => {
          history.push("/home");
        }}
        sx={{
          fontSize: "16px",
          fontFamily: "Source Sans Pro",
          fontWeight: "600",
          bgcolor: "#7B7B7B",
          color: "#fff",
          width: "140px",
          height: "42px",
          textTransform: "none",
          margin: "30px 0px 20px 0px",
          "&:hover": {
            color: "#7B7B7B",
            border: "1px solid #7B7B7B",
          },
        }}
      >
        Voltar
      </Button>

      <Button
        onClick={() => {
          handleSaveCertificate();
        }}
        sx={{
          fontSize: "16px",
          fontFamily: "Source Sans Pro",
          fontWeight: "600",
          bgcolor: "#58C5C6",
          color: "#fff",
          width: "140px",
          height: "42px",
          textTransform: "none",
          margin: "30px 0px 20px 20px",
          "&:hover": {
            color: "#58C5C6",
            border: "1px solid #58C5C6",
          },
        }}
      >
        Salvar
      </Button>

      <div className="row">
        <div className="col-sm-12 mt-2">
          <AlertDefault
            showAlert={messageAlert.length}
            alertData={{
              head: "Sucesso",
              alertVariant: "success",
              content: messageAlert,
              buttonCloseText: "Fechar",
              buttonCloseVariant: "default",
            }}
          />
        </div>
      </div>

      <div class="mt-5">
        <CertificateList
          onDeleteCertificate={handleDeleteCertificate}
          onChangeCertificate={handleChangeCertificate}
          data={certificates}
        />
      </div>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        size="sm"
        className="modalInactiveCertificate"
      >
        <Modal.Body className="modalBody">
          <p>Já existe um certificado ativo! Deseja inativa-lo?</p>
          <div className="modalButtonContainer">
            <Button
              className="modalButton"
              variant="info"
              onClick={handleClose}
            >
              Não
            </Button>

            <Button
              variant="outline-info"
              onClick={() => {
                inactiveCertificate();
              }}
              className="modalButton"
            >
              Sim
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

  return (
    <div>
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
    </div>
  );
}

export default SaveCertificate;
