import { api } from "../connection/api";

const baseEndpoint = "payment/ordersale";

export async function sendOrderSale(params) {
  const data = await api.post(`${baseEndpoint}/send`, params);

  return data;
}

export async function removeOrderSale(transactionId) {
  return await api.delete(`${baseEndpoint}/remove/${transactionId}`);
}

export async function findByTransaction(transactionId) {
  return await api.get(`${baseEndpoint}/findByTransaction/${transactionId}`);
}

export async function findByAssociatedId(associatedId) {
  return await api.get(`${baseEndpoint}/findByAssociatedId/${associatedId}`);
}
