import "./App.css";
import React, { useState, useEffect } from "react";

import PageDefault from "../../components/pageDefault";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { fetchCategoriesWithTrainings } from "../../services/training";

function Training() {
  const [categories, setCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchCategoriesWithTrainings()
      .then(({ data }) => setCategories(data))
  }, []);

  const renderEmptyWidget = () => (
    <section className="col-12 mt-5">
      <div className="row p-2 m-0">
        <div className="d-flex flex-row flex-wrap col-12 m-0 p-0">
          <div className="w-100">
            <p className="text-muted font-weight-bold ml-3 m-0 text-uppercase">
              Nenhum conteúdo cadastrado
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const renderCategories = (categories) => categories.map(({ id, name, image }) => (
    <div className="card m-3 shadow border rounded"
         onClick={() => history.push(`/training/detail/${id}`)}>
      <img className="category_card" width="360" height="240" src={image} alt="Card cap"/>
      <div className="card-header d-flex justify-content-start align-items-center" style={{ maxWidth: 360 }}>
        <p className="text-muted m-0 h5 font-weight-bold text-truncate">{name}</p>
      </div>
    </div>
  ));

  const pageContent = (
    <div className="row">
      <div className="col-12">
        <div className="ml-3 row d-flex justify-content-start">
          <div className="d-flex align-items-center flex-wrap mt-3">
            <h1 style={{ color: "#002c43" }}>
              <FontAwesomeIcon
                className="back-icon mr-3 icon-hover"
                icon={faArrowLeft}
                size="1x"
                onClick={() => history.push("/home")}
                color="#002c43"
                style={{ hover: "cursor: pointer" }}
              />
              Treinamento
            </h1>
          </div>
        </div>
      </div>
      {categories.length !== 0 ? renderCategories(categories) : renderEmptyWidget()}
    </div>
  );

  return <PageDefault pageData={{ pageContent }}/>
}

export default Training;
