import '../App.css';
import PageDefault from '../../../components/pageDefault';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import AlertDefault from '../../../components/alertDefault'
import ButtonSubmit from '../../../components/buttonSubmit';
import ButtonActionDefault from '../../../components/buttonAction';
import CurrencyFormat from 'react-currency-format';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { fetchDasn, postDasn } from '../../../services/dasn';
import { DasnPaymentStatus } from '../../../enumerations/paymentStatusDASN';
import { getInvoiceProductSum, getInvoiceServiceSum } from '../../../services/invoice/invoiceSum';

function AnnualDeclaration() {

    const history = useHistory()

    const [hasEmployee, setHasEmployee] = useState(null)
    const [totalTrade, setTotalTrade] = useState('')
    const [totalService, setTotalService] = useState('')
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [confirmInformations, setConfirmInformations] = useState(false)
    const [messageError, setMessageError] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    const totalRevenueRef = useRef(0)
    const { yearReference, isRectifier } = useParams()
    const [headerAppend, setHeaderAppend] = useState()
    const [previousYear] = useState(new Date().getFullYear() - 1)
    const [minTotalService, setMinTotalService] = useState()
    const [minTotalTrade, setMinTotalTrade] = useState()
    const [sendDisable, setSendDisable] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    const [backErrors, setBackErrors] = useState({});
    const clearBackErrors = (field) => {
        backErrors[field] = null;
        setBackErrors(backErrors);
    };

    useEffect(() => {
        setTotalRevenue(parseFloat(totalTrade) + parseFloat(totalService))
        totalRevenueRef.current.values.totalRevenue = parseFloat(totalTrade) + parseFloat(totalService)
    }, [totalTrade, totalService])

    console.log(yearReference)
    useEffect(() => {
        getInvoiceProductSum(yearReference).then(res => {
            setMinTotalTrade(res.data.value)
            setTotalTrade(res.data.value || 0)
        })

        getInvoiceServiceSum(yearReference).then(res => {
            setMinTotalService(res.data.value)
            setTotalService(res.data.value || 0)
        })
    }, [])

    useEffect(() => {
        const aux = JSON.parse(isRectifier)
        if (aux) {
            setHeaderAppend("- Retificadora")
        } else {
            setHeaderAppend("")
        }
    })

    const formSchema = yup.object().shape({
        hasEmployee: yup.boolean()
            .required("Campo obrigatório!")
            .nullable(),
        totalTrade: yup.number()
            .required("Campo obrigatório!")
            .test('val', 'Valor inválido', val => {
                if (val < minTotalTrade) {
                    return false
                }
                return true
            }),
        totalService: yup.number()
            .required("Campo obrigatório!")
            .test('val', 'Valor inválido', val => {
                if (val < minTotalService) {
                    return false
                }
                return true
            }),
        totalRevenue: yup.string()
            .required("Campo obrigatório!"),
        confirmInformations: yup.boolean()
            .oneOf([true], 'Campo obrigatório'),
    });

    const handleSaveDasn = (values, resetForm) => {
        setSendDisable(true);
        setShowInfo(true);
        setBackErrors({});

        values.year = yearReference;
        values.rectifier = JSON.parse(isRectifier);
        postDasn(values).then(res => {
            setShowInfo(false);
            setMessageSuccess('Dasn enviado com sucesso');
            resetForm();

        }).catch(err => {
            const errors = {};
            setSendDisable(false);
            setShowInfo(false);
            if (err.response) err.response.data.errors.forEach((item) => {
                const { fieldName, message } = item

                if (fieldName == 'rectifier') setMessageError(message)
                else errors[fieldName] = message;
            });

            setBackErrors(errors);
        })

    }
    const page = (
        <div>
            <div className="row">
                <div className="col-12 m-0 p-0 d-flex flex-wrap">
                    <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                        <h1 style={{ color: "#002c43" }}>
                            <FontAwesomeIcon
                                className="back-icon mr-3 icon-hover"
                                icon={faArrowLeft}
                                size="1x"
                                onClick={() => history.push("/home")}
                                color="#002c43"
                                style={{ hover: "cursor: pointer" }}
                            />
                            {`Declaração anual - ${yearReference} ${headerAppend}`}
                        </h1>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <Formik
                    initialValues={{
                        hasEmployee,
                        totalTrade,
                        totalService,
                        totalRevenue,
                        confirmInformations
                    }}
                    innerRef={{
                        totalTrade,
                        totalService,
                    }}
                    enableReinitialize={true}
                    validationSchema={formSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={totalRevenueRef}
                    onSubmit={async (values, { resetForm }) => handleSaveDasn(values, resetForm)}
                >
                    {({
                        setFieldValue,
                        values
                    }) => (
                        <Form>
                            <div className="shadow border rounded row col-12 col-lg-6 m-0 p-4">
                                <div className="col-12">
                                    <p className="text-muted font-weight-bold h3 m-0">Funcionários</p>
                                    <p className="text-muted m-0">Você teve algum funcionário registrado esse ano?</p>
                                    <div className="row m-0 mt-2">
                                        <div className="d-flex align-items-center">
                                            <Field
                                                id="yes"
                                                type="radio"
                                                name="hasEmployee"
                                                value="true"
                                                onChange={(hasEmployee) => {
                                                    setHasEmployee(hasEmployee.target.value)
                                                    setFieldValue('hasEmployee', hasEmployee.target.value)
                                                }}
                                            />
                                            <label for="yes" className="m-0 ml-2 text-muted">Sim</label>
                                        </div>
                                        <div className="d-flex align-items-center ml-2">
                                            <Field
                                                id="no"
                                                type="radio"
                                                name="hasEmployee"
                                                value="false"
                                                onChange={(hasEmployee) => {
                                                    setHasEmployee(hasEmployee.target.value)
                                                    setFieldValue('hasEmployee', hasEmployee.target.value)
                                                }}
                                            />
                                            <label for="no" className="m-0 ml-2 text-muted">Não</label>
                                        </div>
                                    </div>
                                    <ErrorMessage className="text-danger" component="small" name="hasEmployee" />
                                    {backErrors.hasEmployee && <p className="error"> {backErrors.hasEmployee} </p>}
                                </div>
                                <div className="col-12 mt-4">
                                    <p className="text-muted font-weight-bold h3 m-0">Comércio</p>
                                    <p className="text-muted m-0">Quanto foi o total que você recebeu nesse ano em:</p>
                                    <ul>
                                        <li className="text-muted">Comércio;</li>
                                        <li className="text-muted">Indústria;</li>
                                        <li className="text-muted">Transporte intermunicipais e interestaduais;</li>
                                        <li className="text-muted">Ou fornecimento de refeições?</li>
                                    </ul>
                                    <div class="input-group mt-2 ">
                                        <Field
                                            render={() => (
                                                <CurrencyFormat
                                                    name="totalTrade"
                                                    className="form-control"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={"R$ "}
                                                    decimalScale={2}
                                                    type="text"
                                                    value={values.totalTrade || 0}
                                                    onValueChange={(val) => {
                                                        setTotalTrade(val.value)
                                                        setFieldValue('totalTrade', val.value)
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <ErrorMessage className="text-danger" component="small" name="totalTrade" />
                                    {backErrors.totalTrade && <p className="error"> {backErrors.totalTrade} </p>}
                                </div>
                                <div className="col-12 mt-4">
                                    <p className="text-muted font-weight-bold h3 m-0">Serviços</p>
                                    <p className="text-muted m-0">Total recebido este ano como prestador de serviços:</p>
                                    <div class="input-group mt-2">
                                        <Field
                                            render={() => (
                                                <CurrencyFormat
                                                    name="totalService"
                                                    className="form-control"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={"R$ "}
                                                    decimalScale={2}
                                                    type="text"
                                                    value={values.totalService || 0}
                                                    onValueChange={(val) => {
                                                        setTotalService(val.value)
                                                        setFieldValue('totalService', val.value)
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <ErrorMessage className="text-danger" component="small" name="totalService" />
                                    {backErrors.totalService && <p className="error"> {backErrors.totalService} </p>}
                                </div>
                                <div className="col-12 mt-4">
                                    <p className="text-muted h3 m-0">Faturamento total</p>
                                    <p className="m-0">Faturamento total neste ano:</p>
                                    <div class="input-group mt-2">
                                        <Field
                                            render={({ }) => (
                                                <CurrencyFormat
                                                    disabled
                                                    name="totalRevenue"
                                                    className="form-control"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={"R$ "}
                                                    decimalScale={2}
                                                    type="text"
                                                    value={isNaN(totalRevenue) ? 0 : totalRevenue}
                                                />
                                            )}
                                        />
                                    </div>
                                    <ErrorMessage className="text-danger" component="small" name="totalRevenue" />
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="d-flex align-items-center">
                                        <Field
                                            id="confirmInformations"
                                            type="checkbox"
                                            name="confirmInformations"
                                            checked={confirmInformations}
                                            value={confirmInformations}
                                            onChange={(confirmInformations) => {
                                                setConfirmInformations(confirmInformations.target.checked)
                                                setFieldValue('confirmInformations', confirmInformations.target.checked)
                                            }}
                                        />

                                        <label for="confirmInformations" className="text-muted m-0 ml-2">Garanto a veracidade das informações inseridas e que estas são de minha total responsabilidade!</label>
                                    </div>
                                    <ErrorMessage className="text-danger" component="small" name="confirmInformations" />
                                </div>
                                <div className="row col-12 m-0 mt-4">
                                    <div>
                                        <ButtonSubmit
                                            buttonData={{
                                                variant: "info",
                                                text: "Enviar",
                                                disabled:sendDisable,
                                                action: () => console.log()
                                            }}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <ButtonActionDefault
                                            buttonData={{
                                                text: "Voltar",
                                                variant: "outline-info",
                                                action: () => history.goBack()
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-2">
                                    {showInfo && (
                                        <AlertDefault
                                            showAlert={true}
                                            alertData={{
                                                head: 'Aguarde!',
                                                alertVariant: 'info',
                                                content: 'Enviando dados para a Receita Federal',
                                                buttonCloseText: 'Fechar',
                                                buttonCloseVariant: 'default'
                                            }}
                                        />
                                    )}
                                    <AlertDefault
                                        showAlert={messageSuccess || messageError}
                                        alertData={{
                                            head: messageSuccess ? 'Sucesso' : 'Erro',
                                            alertVariant: messageSuccess ? 'success' : 'danger',
                                            content: messageSuccess || messageError,
                                            buttonCloseText: 'Fechar',
                                            buttonCloseVariant: 'default'
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )

    return (
        <PageDefault
            pageData={{
                pageContent: page,
            }}
        />
    );
}

export default AnnualDeclaration;
