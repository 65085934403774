import { api, apiCancellable } from "../../connection/api";
import axios from "axios";

const baseUrl = "/associate/nfe";
const baseUrlService = "/associate/invoice";

const baseUrlBasketNfe = "/invoice/nfe";

let source = axios.CancelToken.source();
let cancelTokenSource = source;

export function cancel(message) {
  cancelTokenSource.cancel(message);
  // cancelTokenSource = axios.CancelToken.source();
}

export async function findAllNfe(data) {
  return api.get(`${baseUrl}`, { params: data });
}

export async function amountNfsIssued() {
  return api.get(`${baseUrl}/amountNfsIssued`);
}

export async function findOneNfe(id) {
  return api.get(`${baseUrl}/${id}`,{
    params: {
      type: 0,
    }
  });
}

export async function findOneNfeService(id) {
  return api.get(`${baseUrlService}/${id}`,{
    params: {
      type: 1
    }
  });
}

export async function findOneNfeCancellable(id, cancelFirst) {
  if (cancelFirst) {
    cancelTokenSource = axios.CancelToken.source();
  }
  const data = api.get(`${baseUrl}/${id}`, {
    cancelToken: cancelTokenSource.token,
  });
  cancelTokenSource = axios.CancelToken.source();
  return data;
}
export async function findOneNfeServiceCancellable(id, cancelFirst) {
  if (cancelFirst) {
    cancelTokenSource = axios.CancelToken.source();
  }
  const data = api.get(`${baseUrlService}/${id}`, {
    cancelToken: cancelTokenSource.token,
  });
  cancelTokenSource = axios.CancelToken.source();
  return data;
}

export async function createNfe(nfeDTO) {
  return api.post(`${baseUrl}`, nfeDTO);
}

export async function cancelNFe({ id, body }) {
  return api.post(`${baseUrlBasketNfe}/cancel/${id}`, body);
}
export async function correctionNfe({ id, body }) {
  return api.post(`${baseUrlBasketNfe}/correction/${id}`, body);
}
export async function returnNfe({ id, body }) {
  return api.post(`${baseUrlBasketNfe}/return/${id}`, body);
}

export async function consultaNfe(body) {
  let controllerapi = "https://controllerapi.acsp.com.br/utils/pdf/extrair.php";
  return api.post(controllerapi, body);
}