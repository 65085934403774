import { api } from '../connection/api'

export async function getProviders() {
    return await api.get(`register/associated-provider`);
}

export async function getProviderById(id) {
    return await api.get(`register/associated-provider/${id}`);
}

export async function createProvider(provider) {
    return await api.post(`register/associated-provider`, provider);
}

export async function updateProvider(provider) {
    return await api.put(`register/associated-provider/${provider.id}`, provider);
}

export async function activateStatus(id) {
    return await api.put(`register/associated-provider/${id}/activate`);
}

export async function deactiveStatus(id) {
    return await api.put(`register/associated-provider/${id}/deactivate`);
}

export async function getIndicators() {
    return await api.get(`/register/indicators`);
}