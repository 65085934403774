import '../App.css';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import IconButtonDefault from '../../../components/iconButton';
import CpfMask from '../../../components/inputs/cpf';
import CnpjMask from '../../../components/inputs/cnpj';
import PhoneMask from '../../../components/inputs/phone';
import CepjMask from '../../../components/inputs/cep';
import { ParagraphDefault } from '../../../components/typograph';
import PageDefault from '../../../components/pageDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { createProvider, getIndicators } from '../../../services/register/providers';
import ButtonSubmit from '../../../components/buttonSubmit';
import jwtDecode from 'jwt-decode';
import { fetchCities, fetchStates } from '../../../services/address';
import { transformString } from '../../../components/utils/accent';
import { NotificationManager } from 'react-notifications'
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import 'react-notifications/lib/notifications.css';
import ButtonActionDefault from '../../../components/buttonAction';
import { PersonType } from "../../../enumerations/Invoice";


function ProviderCreate() {
  const [personType, setPersonType] = useState('');
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [foreignerId, setForeignerId] = useState('');
  const [foreignerName, setForeignerName] = useState('');
  const [openingDate, setopeningDate] = useState('');
  const [phone, setPhone] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [email, setEmail] = useState('');
  const [socialCode, setSocialCode] = useState('');
  const [stateInscription, setStateInscription] = useState('');
  const [stateInscriptionIndicator, setStateInscriptionIndicator] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [notes, setNotes] = useState('');
  // const [id, setId] = useState(null)
  const [associatedId, setAssociatedId] = useState(
    () => {
      const { id } = jwtDecode(
        localStorage.getItem('jwt_auth')
      )
      return id
    }
  )
  const [showAdicionalInformation, setShowAdicionaInformation] = useState(false);
  const [showTaxInformation, setShowTaxInformation] = useState(false);
  const [showAddressInformation, setShowAddressInformation] = useState(false);
  const [showObservationInformation, setShowObservationInformation] = useState(false);
  const [showPhysicalPersonInformation, setShowPhysicalPersonformation] = useState(false);
  const [showLegalPersonInformation, setShowLegalPersonformation] = useState(false);
  const [showForeignerInformation, setShowForeignerformation] = useState(false);
  const [stateId, setStateId] = useState()
  const [cityId, setCityId] = useState()
  const [indicators, setIndicators] = useState()
  const history = useHistory();
  const [backErrors, setBackErrors] = useState([]);
  const [cepError, setCepError] = useState()
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };

  const toggleShowAdicionalInformation = () => {
    setShowAdicionaInformation(!showAdicionalInformation);
  }

  const toggleShowTaxInformation = () => {
    setShowTaxInformation(!showTaxInformation);
  }

  const toggleShowAddressInformation = () => {
    setShowAddressInformation(!showAddressInformation);
  }

  const toggleShowObservationInformation = () => {
    setShowObservationInformation(!showObservationInformation);
  }

  const resetValidationSchema = Yup.object().shape({
    personType: Yup.string()
      .nullable()
      .required('Campo Obrigatório'),
    cpf: Yup.string().when('personType', {
      is: value => value === 'FISICA',
      then: Yup.string().nullable().required('Campo obrigatório'),
      otherwise: null,
    }),
    cnpj: Yup.string().when('personType', {
      is: value => value === 'JURIDICA',
      then: Yup.string().nullable().required('Campo obrigatório'),
      otherwise: null,
    }),
    companyName: Yup.string().when('personType', {
      is: value => value === 'JURIDICA',
      then: Yup.string().nullable().required('Campo obrigatório'),
      otherwise: null,
    }),
    name: Yup.string().when('personType', {
      is: value => value === 'FISICA',
      then: Yup.string().nullable().required('Campo obrigatório'),
      otherwise: null,
    }),
    foreignerName: Yup.string().when('personType', {
      is: value => value === 'ESTRANGEIRA',
      then: Yup.string().nullable().required('Campo obrigatório'),
      otherwise: null,
    }),
    openingDate: Yup.date()
      .max(new Date(), "Data inválida")
      .required("Campo obrigatório"),
    email: Yup.string().email('Email inválido').required("Campo obrigatório"),
    cep: Yup
      .string()
      .test('len', 'CEP inválido.', val => !val || (val && val.length === 10))
      .required("Campo Obrigatório!.")
      .nullable(),
    address: Yup
      .string()
      .max(255, "Campo precisa ser menor que 255")
      .required("Campo Obrigatório!.")
      .nullable(),
    neighborhood: Yup
      .string()
      .max(255, "Campo precisa ser menor que 255")
      .required("Campo Obrigatório!.")
      .nullable(),
    state: Yup
      .string()
      .required("Campo Obrigatório!.")
      .nullable(),
    city: Yup
      .string()
      .required("Campo Obrigatório!.")
      .nullable(),
  });

  useEffect(() => {
    if (personType === 'FISICA') {
      setShowPhysicalPersonformation(true);
      setShowLegalPersonformation(false);
      setShowForeignerformation(false);
    } else if (personType === 'JURIDICA') {
      setShowLegalPersonformation(true);
      setShowPhysicalPersonformation(false);
      setShowForeignerformation(false);
    } else if (personType === 'ESTRANGEIRA') {
      setShowForeignerformation(true);
      setShowPhysicalPersonformation(false);
      setShowLegalPersonformation(false);
    }
  }, [personType])
  const onBlurCep = (e) => {
    if (!e.target.value) {
      return;
    }

    const instance = axios.create();
    delete instance.defaults.headers.common.Authorization;
    instance.get(`https://viacep.com.br/ws/${e.target.value.replace(/[^0-9]+/g, '')}/json`)
      .then(({ data }) => {
        if (!data.erro) {
          setCepError('')
          setCity(data.localidade)
          setState(data.uf)
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);

          const _city = data.localidade
          const _state = data.uf

          fetchStates().then(({ data }) => {
            data.filter(currentState => {
              if (currentState.initials === _state) {
                const _stateId = currentState.id
                setStateId(_stateId)
                fetchCities(_stateId).then(({ data }) => {
                  data.filter(currentCity => {
                    if (currentCity.name === transformString(_city)) {
                      setCityId(currentCity.id)
                    }
                  })
                })
              }
            })
          })
        } else {
          setCepError('CEP inválido!')
          setCep('');
          setState('');
          setAddress('');
          setNeighborhood('');
          setCity('');
          setState('')
        }
      })
  };

  useEffect(() => {
    getIndicators().then(res => {
      setIndicators(res.data)
    })
  }, [])

  const back = {}
  backErrors.forEach(error => {
    switch (error.fieldName) {
      case 'type':
        back.type = error.message
        break;
      case 'socialName':
        back.socialName = error.message
        break;
      case 'socialCode':
        back.socialCode = error.message
        break;
      case 'openingDate':
        back.openingDate = error.message
        break;
      case 'email':
        back.email = error.message
        break;
      case 'indicatorId':
        back.indicator = error.message
        break;
      case 'phone':
        back.phone = error.message
        break;
      case 'whatsapp':
        back.whatsapp = error.message
        break;
      case 'zipCode':
        back.zipCode = error.message
        break;
      case 'street':
        back.street = error.message
        break;
      case 'neighborhood':
        back.neighborhood = error.message
        break;
      case 'stateId':
        back.state = error.message
        break;
      case 'cityId':
        back.city = error.message
        break;
    }
  })

  const page = (
    <>
      <Formik
        initialValues={{
          personType,
          cpf,
          name,
          companyName,
          cnpj,
          foreignerId,
          foreignerName,
          openingDate,
          phone,
          cellPhone,
          email,
          socialCode,
          stateInscription,
          stateInscriptionIndicator,
          cep,
          address,
          addressNumber,
          neighborhood,
          state,
          city,
          addressComplement,
        }}
        enableReinitialize
        validationSchema={resetValidationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}

        onSubmit={() => {
          const data = {}
          data.type = personType
          data.socialName = personType === 'FISICA' ? name : personType === 'ESTRANGEIRA' ? foreignerName : personType === 'JURIDICA' ? companyName : ''
          data.socialCode = personType === 'FISICA' ? cpf : personType === 'JURIDICA' ? cnpj : personType === 'ESTRANGEIRA' ? foreignerId : ''
          data.email = email
          data.simpleNational = true
          data.openingDate = openingDate
          data.stateRegistration = stateInscription
          data.municipalRegistration = ''
          data.comments = notes
          data.indicatorId = stateInscriptionIndicator === "" ? null : stateInscriptionIndicator
          data.phone = cellPhone
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
            .replace('-', '')
            .replace('_', '')
          data.whatsapp = phone
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
          data.street = address
          data.zipCode = cep
            .replace('-', '')
            .replace('.', '')
          data.neighborhood = neighborhood
          data.numberHouse = addressNumber
          data.complement = addressComplement
          data.referencePoint = ''
          data.stateId = stateId
          data.cityId = cityId
          data.associatedId = associatedId

          createProvider(data).then(res => {
            if (res.status === 201) {
              NotificationManager.success('Fornecedor cadastrado com sucesso');
              setBackErrors([]);
              history.push("/registration/provider");
            }
          }).catch((error) => {
            const errors = error.response.data.errors
            setBackErrors(errors)
            NotificationManager.error('Erro ao cadastrar fornecedor');
          })
        }}>
        {({
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
          <Form className="row d-flex align-items-center pl-4" style={{ height: "70vh" }}>
            <NotificationContainer/>
            <div className="col-12 col-md-10 d-flex justify-content-between align-items-center flex-wrap p-0">
              <div className="d-flex align-items-center flex-wrap mt-3">
                <h1 style={{ color: "#002c43" }}>
                  <FontAwesomeIcon
                    className="back-icon mr-3 icon-hover"
                    icon={faArrowLeft}
                    size="1x"
                    onClick={() => history.push("/registration/provider")}
                    color="#002c43"
                  />
                  Cadastro de fornecedores
                </h1>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-10 invoice__form">
                  <div className="row col-12">
                    <div className="col-9 col-md-9 my-3">
                      <ParagraphDefault paragraphData={{
                        _class: 'text-muted font-weight-bold h5 ml-2',
                        text: 'Dados gerais'
                      }}/>
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="col-12 col-md-4 mb-3">
                      <p className="text-muted font-weight-bold m-0">Tipo de pessoa: <label
                        className="text-danger m-0">*</label></p>
                      <div class="form-group">
                        <select
                          class="custom-select"
                          id="personType"
                          value={personType}
                          onChange={(personType) => {
                            setPersonType(personType.target.value)
                          }}
                        >
                          <option value="" selected>Selecione o tipo</option>
                          <option value="FISICA">Física</option>
                          <option value="JURIDICA">Jurídica</option>
                          <option value="ESTRANGEIRA">Estrangeira</option>
                        </select>
                        {
                          <small className="text-danger">{back.type || errors.personType}</small>
                        }
                      </div>
                    </div>
                    {showPhysicalPersonInformation && (
                      <>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">CPF: <label
                            className="text-danger m-0">*</label></p>
                          <CpfMask
                            id="cpf"
                            name="cpf"
                            className="form-control "
                            placeholder="Informe o seu CPF"
                            type="text"
                            value={values.cpf}
                            onChange={(cpf) => {
                              clearBackErrors('cpf');
                              setCpf(cpf.target.value.replace(/[^0-9]+/g, ''));
                              setFieldValue(
                                'cpf',
                                cpf.target.value.replace(/[^0-9]+/g, ''),
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.cpf
                              && touched.cpf
                              && errors.cpf) || back.socialCode}
                          </small>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Nome: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={values.name}
                            placeholder="Informe o nome"
                            onChange={(name) => {
                              clearBackErrors('name');
                              setName(name.target.value);
                              setFieldValue(
                                'name',
                                name.target.value,
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.name
                              && touched.name
                              && errors.name) || back.socialName}
                          </small>
                        </div>
                      </>
                    )}
                    {showLegalPersonInformation && (
                      <>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">CNPJ: <label
                            className="text-danger m-0">*</label></p>
                          <CnpjMask
                            id="cnpj"
                            name="cnpj"
                            className="form-control "
                            placeholder="Informe o CNPJ"
                            type="text"
                            value={values.cnpj}
                            onChange={(cnpj) => {
                              clearBackErrors('cnpj');
                              setCnpj(cnpj.target.value.replace(/[^0-9]+/g, ''));
                              setFieldValue(
                                'cnpj',
                                cnpj.target.value.replace(/[^0-9]+/g, ''),
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.cnpj
                              && touched.cnpj
                              && errors.cnpj) || back.socialCode}
                          </small>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Razão Social: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            placeholder="Informe a razão social"
                            onChange={(companyName) => {
                              clearBackErrors('companyName');
                              setCompanyName(companyName.target.value);
                              setFieldValue(
                                'companyName',
                                companyName.target.value,
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.companyName
                              && touched.companyName
                              && errors.companyName) || back.socialName}
                          </small>
                        </div>
                      </>
                    )}
                    {showForeignerInformation && (
                      <>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Identificação estrangeira: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            id="foreignerId"
                            name="foreignId"
                            className="form-control "
                            placeholder="Informe o ID"
                            type="text"
                            value={values.foreignerId}
                            onChange={(foreignerId) => {
                              clearBackErrors('foreignerId');
                              setForeignerId(foreignerId.target.value.replace(/[^0-9]+/g, ''));
                              setFieldValue(
                                'foreignerId',
                                foreignerId.target.value.replace(/[^0-9]+/g, ''),
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.foreignerId
                              && touched.foreignerId
                              && errors.foreignerId) || back.socialCode}
                          </small>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Nome: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            type="text"
                            className="form-control"
                            name="foreignerName"
                            placeholder="Informe o nome"
                            onChange={(foreignerName) => {
                              clearBackErrors('foreignerName');
                              setForeignerName(foreignerName.target.value);
                              setFieldValue(
                                'foreignerName',
                                foreignerName.target.value,
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.foreignerName
                              && touched.foreignerName
                              && errors.foreignerName) || back.socialName}
                          </small>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="row col-12">
                                        <div className="col-4 mb-2">
                                            <input
                                                type="checkbox"
                                                name="isClient"
                                                className="mr-2 checkbox-round"
                                            />
                                            <label>Adicionar fornecedor como cliente</label>
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-10 invoice__form">
                  <div className="row">
                    <div className="col-9 col-md-9 my-3">
                      <label
                        className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                        onClick={toggleShowAdicionalInformation}
                      >
                        Informações adicionais
                      </label>
                    </div>
                    {showAdicionalInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowAdicionalInformation(),
                          icon: 'bi bi-chevron-up',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                    {!showAdicionalInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowAdicionalInformation(),
                          icon: 'bi bi-chevron-down',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                  </div>
                  {showAdicionalInformation && (
                    <>
                      <div className="row col-12">
                        <div className="col-12 col-md-4 mb-3">
                          <p className="text-muted font-weight-bold mb-0">
                            {
                              values.personType === "JURIDICA" ? 'Data de abertura:' : 'Data de nascimento:'
                            }
                          </p>
                          <input
                            id="openingDate"
                            name="openingDate"
                            className="form-control"
                            type="date"
                            onChange={(openingDate) => {
                              clearBackErrors('openingDate');
                              setopeningDate(openingDate.target.value);
                              setFieldValue(
                                'openingDate',
                                openingDate.target.value,
                              );
                            }}
                          />

                          {
                            <small className="text-danger">{errors.openingDate || back.openingDate}</small>
                          }
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Telefone comercial: <label
                            className="text-danger m-0">*</label></p>
                          <PhoneMask
                            id="cellPhone"
                            name="cellPhone"
                            className="form-control "
                            placeholder="Informe o telefone"
                            onChange={(cellPhone) => {
                              clearBackErrors('cellPhone');
                              setCellPhone(cellPhone.target.value);
                              setFieldValue(
                                'cellPhone',
                                cellPhone.target.value,
                              );
                            }}
                            value={cellPhone}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.cellPhone
                              && touched.cellPhone
                              && errors.cellPhone) || back.phone}
                          </small>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-muted font-weight-bold m-0">Telefone celular: <label
                            className="text-danger m-0">*</label></p>
                          <PhoneMask
                            id="phone"
                            name="phone"
                            className="form-control "
                            placeholder="Informe o telefone"
                            onChange={(phone) => {
                              clearBackErrors('phone');
                              setPhone(phone.target.value);
                              setFieldValue(
                                'phone',
                                phone.target.value,
                              );
                            }}
                            value={phone}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.phone
                              && touched.phone
                              && errors.phone) || back.whatsapp}
                          </small>
                        </div>
                      </div>
                      <div className="row col-12 mb-2">
                        <div className="col-12 col-md-6 mb-3">
                          <p className="text-muted font-weight-bold m-0">E-mail: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            id="email"
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Informe o e-mail"
                            onChange={(email) => {
                              clearBackErrors('email');
                              setEmail(email.target.value);
                              setFieldValue(
                                'email',
                                email.target.value,
                              );
                            }}
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.email
                              && touched.email
                              && errors.email) || back.email}
                          </small>
                        </div>
                        {/* {
                          values.personType !== "JURIDICA" && (
                            <div className="col-12 col-md-6">
                              <p className="text-muted font-weight-bold m-0">RG: </p>
                              <input
                                type="text"
                                className="form-control"
                                name="id"
                                placeholder="Informe o RG"
                                onChange={(id) => {
                                  clearBackErrors('id');
                                  setId(id.target.value);
                                  setFieldValue(
                                    'id',
                                    id.target.value,
                                  );
                                }}
                              />
                              <small className="col-12 p-0 text-left text-danger">
                                {(errors.id
                                  && touched.id
                                  && errors.id) || backErrors.id}
                              </small>
                            </div>
                          )
                        } */}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {
                personType === PersonType.JURIDICA && (
                  <div className="row mt-4">
                    <div className="col-10 invoice__form">
                      <div className="row">
                        <div className="col-9 col-md-9 my-3">
                          <label
                            className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                            onClick={toggleShowTaxInformation}
                          >
                            Informações fiscais
                          </label>
                        </div>
                        {showTaxInformation && (
                          <div className="col-3 float-right">
                            <IconButtonDefault iconButtonData={{
                              variant: "none",
                              action: () => toggleShowTaxInformation(),
                              icon: 'bi bi-chevron-up',
                              iconSize: '1.5rem',
                              // goesTo: '/',
                              iconColor: '#002c43',
                              _class: 'mt-3 float-right'
                            }}
                            />
                          </div>
                        )}
                        {!showTaxInformation && (
                          <div className="col-3 float-right">
                            <IconButtonDefault iconButtonData={{
                              variant: "none",
                              action: () => toggleShowTaxInformation(),
                              icon: 'bi bi-chevron-down',
                              iconSize: '1.5rem',
                              // goesTo: '/',
                              iconColor: '#002c43',
                              _class: 'mt-3 float-right'
                            }}
                            />
                          </div>
                        )}
                      </div>
                      {showTaxInformation && (
                        <>
                          <div className="row col-12">
                            <div className="col-12 col-md-6 mb-3">
                              <p className="text-muted font-weight-bold mb-0">Indicador de inscrição estadual: </p>
                              <div className="form-group">
                                <select
                                  className="custom-select"
                                  id="stateInscriptionIndicator"
                                  value={stateInscriptionIndicator}
                                  onChange={(stateInscriptionIndicator) => {
                                    setStateInscriptionIndicator(stateInscriptionIndicator.target.value)
                                  }}
                                >
                                  <option value="" selected>Informe o indicador</option>
                                  {
                                    indicators !== undefined &&
                                    indicators.map(currentIndicator => {
                                      return (
                                        <option key={currentIndicator.id}
                                                value={currentIndicator.id}>{currentIndicator.name}</option>
                                      )
                                    })
                                  }
                                </select>
                                <small className="col-12 p-0 text-left text-danger">
                                  {(errors.stateInscriptionIndicator
                                    && touched.stateInscriptionIndicator
                                    && errors.stateInscriptionIndicator) || backErrors.indicator}
                                </small>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <p className="text-muted font-weight-bold m-0">Inscrição estadual: </p>
                              <input
                                type="text"
                                className="form-control"
                                name="stateInscription"
                                placeholder="Informe a inscrição estadual"
                                onChange={(stateInscription) => {
                                  clearBackErrors('stateInscription');
                                  setStateInscription(stateInscription.target.value);
                                  setFieldValue(
                                    'stateInscription',
                                    stateInscription.target.value,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              }
              <div className="row mt-4">
                <div className="col-10 invoice__form">
                  <div className="row">
                    <div className="col-9 col-md-9 my-3">
                      <label
                        className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                        onClick={toggleShowAddressInformation}
                      >
                        Endereço
                      </label>
                    </div>
                    {showAddressInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowAddressInformation(),
                          icon: 'bi bi-chevron-up',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                    {!showAddressInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowAddressInformation(),
                          icon: 'bi bi-chevron-down',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                  </div>
                  {showAddressInformation && (
                    <>
                      <div className="row col-12">
                        <div className="col-12 col-md-3 mb-3">
                          <p className="text-muted font-weight-bold mb-0">CEP: <label
                            className="text-danger m-0">*</label></p>
                          <div class="form-group">
                            <CepjMask
                              id="cep"
                              name="cep"
                              className="form-control"
                              placeholder="Informe o CEP"
                              onChange={(cep) => {
                                clearBackErrors('cep');
                                setCep(cep.target.value);
                                setFieldValue(
                                  'cep',
                                  cep.target.value,
                                );

                              }}
                              value={values.cep}
                              onBlur={(e) => {
                                onBlurCep(e)

                              }}
                            />
                            <small className="col-12 p-0 text-left text-danger">
                              {(errors.cep
                                && touched.cep
                                && errors.cep) || backErrors.zipCode}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="text-muted font-weight-bold m-0">Logradouro: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            id="address"
                            name="address"
                            className="form-control"
                            onChange={(address) => {
                              clearBackErrors('address');
                              setAddress(address.target.value);
                              setFieldValue(
                                'address',
                                address.target.value,
                              );
                            }}
                            value={values.address}
                            disabled
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.address
                              && touched.address
                              && errors.address) || backErrors.street}
                          </small>
                        </div>
                        <div className="col-md-3">
                          <p className="text-muted font-weight-bold m-0">Número: </p>
                          <input
                            type="text"
                            className="form-control"
                            name="addressNumber"
                            placeholder=""
                            onChange={(addressNumber) => {
                              clearBackErrors('addressNumber');
                              setAddressNumber(addressNumber.target.value);
                              setFieldValue(
                                'addressNumber',
                                addressNumber.target.value,
                              );
                            }}
                            value={values.addressNumber}
                          />
                        </div>
                      </div>
                      <div className="row col-12">
                        <div className="COL-12 col-md-4 mb-3">
                          <p className="text-muted font-weight-bold mb-0">Bairro: <label
                            className="text-danger m-0">*</label></p>
                          <div class="form-group">
                            <input
                              id="neighborhood"
                              name="neighborhood"
                              className="form-control"
                              onChange={(neighborhood) => {
                                clearBackErrors('neighborhood');
                                setNeighborhood(neighborhood.target.value);
                                setFieldValue(
                                  'neighborhood',
                                  neighborhood.target.value,
                                );
                              }}
                              value={values.neighborhood}
                              disabled
                            />
                            <small className="col-12 p-0 text-left text-danger">
                              {(errors.neighborhood
                                && touched.neighborhood
                                && errors.neighborhood) || backErrors.neighborhood}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <p className="text-muted font-weight-bold m-0">Estado: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            id="state"
                            name="state"
                            className="form-control"
                            value={state}
                            disabled
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.state
                              && touched.state
                              && errors.state) || backErrors.state}
                          </small>
                        </div>
                        <div className="col-md-5">
                          <p className="text-muted font-weight-bold m-0">Cidade: <label
                            className="text-danger m-0">*</label></p>
                          <input
                            id="city"
                            name="city"
                            className="form-control"
                            value={city}
                            disabled
                          />
                          <small className="col-12 p-0 text-left text-danger">
                            {(errors.city
                              && touched.city
                              && errors.city) || backErrors.city}
                          </small>
                        </div>
                        <div className="col-12 mb-3">
                          <p className="text-muted font-weight-bold mb-0">Complemento: </p>
                          <div class="form-group">
                            <input
                              id="addressComplement"
                              name="addressComplement"
                              className="form-control"
                              onChange={(addressComplement) => {
                                clearBackErrors('addressComplement');
                                setAddressComplement(addressComplement.target.value);
                                setFieldValue(
                                  'addressComplement',
                                  addressComplement.target.value,
                                );
                              }}
                              value={values.addressComplement}

                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-10 invoice__form">
                  <div className="row">
                    <div className="col-9 col-md-9 my-3">
                      <label
                        className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                        onClick={toggleShowObservationInformation}
                      >
                        Observações
                      </label>
                    </div>
                    {showObservationInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowObservationInformation(),
                          icon: 'bi bi-chevron-up',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                    {!showObservationInformation && (
                      <div className="col-3 float-right">
                        <IconButtonDefault iconButtonData={{
                          variant: "none",
                          action: () => toggleShowObservationInformation(),
                          icon: 'bi bi-chevron-down',
                          iconSize: '1.5rem',
                          // goesTo: '/',
                          iconColor: '#002c43',
                          _class: 'mt-3 float-right'
                        }}
                        />
                      </div>
                    )}
                  </div>
                  {showObservationInformation && (
                    <>
                      <div className="row col-12 mb-5">
                        <div className="col-md-12">
                          <p className="text-muted font-weight-bold m-0">Observações: </p>
                          <textarea
                            className="form-control"
                            id="notes"
                            rows="5"
                            onChange={(notes) => {
                              clearBackErrors('notes');
                              setNotes(notes.target.value);
                              setFieldValue(
                                'notes',
                                notes.target.value,
                              );
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col p-0 justify-content-between align-items-center mb-5">
                  <ButtonSubmit
                    buttonData={{
                      variant: "info",
                      text: "Salvar",
                      _class: "mt-2"
                    }}
                  />

                  <ButtonActionDefault
                    buttonData={{
                      text: "Voltar",
                      variant: "outline-info",
                      _class: "ml-2 mt-2",
                      action: () => history.goBack(),
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>)}
      </Formik>
    </>
  );
  return (
    <div>
      <PageDefault pageData={{
        pageContent: page
      }}/>
    </div>
  );
}

export default ProviderCreate;

