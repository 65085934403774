import React from 'react';
import MaskedInput from 'react-text-mask';

const CreditCardMask = (props) => {
    const creditCardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

    return (
        <MaskedInput
            {...props}
            mask={creditCardMask}
        />
    )
}

export default CreditCardMask;