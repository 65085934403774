import React from 'react';
import MaskedInput from 'react-text-mask';

export default function CepMask(props) {

    const cepMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,];

    const mask = (val) => {
        const rawValue = val.replace(/[^0-9]+/g, '');

        return cepMask;
    };

    return (
        <MaskedInput
            {...props}
            mask={mask}
        />
    );
}
