import { api } from '../connection/api'

export async function fetchDasn() {
    return await api.get(`financial/dasn`);
}

export async function getDasn(id) {
    return await api.get(`financial/dasn/${id}`);
}

export async function postDasn(dasn) {
    return await api.post(`financial/dasn`, dasn);
}
export async function downloadDasnByAssociated() {
    return await api.get(`financial/dasn/update/update_dasn_associated`);
}
export async function updateAvailable(data) {
    return await api.get(`financial/dasn/update/update_available`,{params:data});
}
export async function lastUpdateLog(data) {
    return await api.get(`financial/dasn/update/last_update_log`,{params:data});
}