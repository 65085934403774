import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
// import Ativo from "../../../imgs/icons/planoPagamento/Ativo.svg";

export default function PasswordInput(props) {
  const { title, placeholder, onChangePassword, password } = props;

  const [olho, setOlho] = useState("");

  // Troca cor da borda dos inputs --------
  const [border, setBorder] = useState("1px solid #aeaeae");

  const handleClick = () => {
    setBorder("1px solid #58C5C6");
  };

  const handleBlur = () => {
    if (passwordRef.current.value != 0) {
      setBorder("1px solid #58C5C6");
    } else {
      setBorder("1px solid #aeaeae");
    }
  };

  const handleMouseEnter = () => {
    if (border == "1px solid #58C5C6") {
      setBorder("1px solid #58C5C6");
    } else {
      setBorder("1px solid #212121");
    }
  };

  const handleMouseLeave = () => {
    if (border == "1px solid #58C5C6") {
      setBorder("1px solid #58C5C6");
    } else {
      setBorder("1px solid #aeaeae");
    }
  };
  // ---------------------------------------

  const passwordRef = useRef(null);

  const handleEye = () => {
    setOlho("password");
    passwordRef.current.type = "text";
  };

  const handleEyeOff = () => {
    setOlho("text");
    passwordRef.current.type = "password";
  };

  const passwordValue = (password) => {
    onChangePassword(password);
  };

  return (
    <>
      <label>{title}</label>
      <div
        className="inputPasswordContainer"
        style={{ border: border }}
        onClick={handleClick}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={0}
      >
        <input
          type="password"
          name="password"
          placeholder={placeholder}
          className="inputPassword"
          ref={passwordRef}
          value={password}
          onChange={(e) => {
            passwordValue(e.target.value);
          }}
        />

        {olho !== "password" ? (
          <span>
            <VisibilityOffRoundedIcon
              className="backIcon"
              onClick={() => {
                handleEye();
              }}
              sx={{ color: "#7B7B7B" }}
            />
          </span>
        ) : (
          <span>
            <VisibilityRoundedIcon
              className="backIcon"
              onClick={() => {
                handleEyeOff();
              }}
              sx={{ color: "#58C5C6" }}
            />
          </span>
        )}
      </div>
    </>
  );
}
