// react
import React from 'react';
// third-party
import PropTypes from 'prop-types';
function Currency(props) {
    let { value } = props;
    const numberToCurrency = (number) => {
        const num = number.toFixed(2).split('.');
        num[0] = `${num[0].split(/(?=(?:...)*$)/).join('.')}`;
        return num.join(',');
    };
    
    if (!value) {
        value = 0;
    }
    const money = numberToCurrency(value)
    return money
}
Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
};
export default Currency;