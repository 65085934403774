import '../App.css';
import { useHistory } from 'react-router-dom';
import PageDefault from '../../../components/pageDefault';
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faEye,
  faPen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIconButtonDefault from '../../../components/buttonWithFontAwesomeIcon';
import { fetchAssociatedClients, putActivate, putDeactivate } from '../../../services/associatedClient';
import { FormCheck } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import SweetAlert from 'react-bootstrap-sweetalert'

function Client() {

  const [clients, setClients] = useState([]);
  const [currentClient, setCurrentClient] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const handleStatus = (idChange) => {
    console.log('->>',idChange)
    clients.forEach(({ id, status }) => {
      if (id == currentClient) {
        status === 'ATIVO'
          ? putDeactivate(id).then(res => changeStatusOnList())
          : putActivate(id).then(res => changeStatusOnList());
      }
    });
  }

  const changeStatusOnList = () => {
    setClients(
      clients.map((client) => {
        const { id, status } = client;
        if (id === currentClient) {
          client.status = status === 'ATIVO' ? 'INATIVO' : 'ATIVO';
        }
        return client;
      })
    );
  }

  useEffect(async () => {
    await fetchAssociatedClients().then(({ data }) => setClients(
      data.map((client) => {
        const { status,validated } = client;
        client.status = status ? 'ATIVO' : 'INATIVO';
        client.validated = validated ? 'SIM' : 'NÃO';
        return client;
      })
    ));
  }, []);

  const options = {
    selectableRows: false,
    onDownload: (buildHead, buildBody, columns, data) =>
      buildHead(columns) +
      buildBody(
        clients.map(({ id, socialName, socialCode, email, status }) => {
          return {
            index: clients.length - 1,
            data: [id, socialName, socialCode, email, status],
          };
        })
      ),
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: ""
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
    downloadOptions: { filename: "Clientes.csv" },
  };
  const columns = [
    {
      name: 'Id',
      options: {
        filter: false,
        display: false,
        print: false,
        export: false
      }
    },
    {
      name: "Nome",
      options: {
        filter: false,
      },
    },
    {
      name: "CPF / CNPJ / ID Estrangeiro",
      options: {
        filter: false,
      },
    },
    {
      name: "E-mail",
      options: {
        filter: false,
      },

    },
    {
      name: "VALIDADO",
      options: {
        filter: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "end" } }),
        customBodyRender: (status, tableMeta) => {
          return (
            <div
              style={{ textAlign: "center" }}
            >
              {status}
              </div>
          );
        }
      },

    },
    {
      name: "Status",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "end" } }),
        customBodyRender: (status, tableMeta) => {
          const id = tableMeta.rowData[0];
          const status2 = tableMeta.rowData[4];
          return (
            <FormCheck
              style={{ textAlign: "end" }}
              type="switch"
              id={`${id}`}
              className={status.toLowerCase()}
              defaultChecked={status2}
              onClick={() => {
                setCurrentClient(id)
                setShowAlert(true)
              }}
            />
          );
        }
      }
    },
    {
      name: "Ações:",
      options: {
        filter: false,
        sort: false,
        print: false,
        export: false,
        download: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "end" } }),
      },
    },
  ];

  const history = useHistory()
  const page = (
    <div className="row d-flex justify-content-start">
      <SweetAlert
        custom
        showCancel
        showCloseButton
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        title="Confirmar ação"
        btnSize="md"
        show={showAlert}
        onConfirm={() => {
          handleStatus(currentClient)
          setShowAlert(false)
        }}
        onCancel={() => setShowAlert(false)}
      >
        Você realmente deseja alterar o status do cliente?
      </SweetAlert>
      <div className="col-12 col-md-11 d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center flex-wrap mt-3">
          <h1 style={{ color: "#002c43" }}>
            <FontAwesomeIcon
              className="back-icon mr-3 icon-hover"
              icon={faArrowLeft}
              size="1x"
              onClick={() => history.push("/home")}
              color="#002c43"
            />
              Clientes
            </h1>
        </div>
        <div>
          <FontAwesomeIconButtonDefault
            buttonData={{
              variant: "info",
              text: "Novo cliente",
              action: () => history.push('/registration/client/create'),
              icon: (
                <FontAwesomeIcon icon={faPlus} />
              ),
            }}
          />
        </div>
      </div>

      <div className="col-12 col-md-11 justify-content-start mt-2">
        <MUIDataTable
          title={"Clientes"}
          data={clients.map(({ id, socialName, socialCode, email,validated, status }) => {
            return [
              id,
              socialName,
              socialCode,
              email,
              validated,
              status,
              <div style={{ textAlign: "end" }}>
                <FontAwesomeIcon icon={faEye} className="icon-hover" onClick={() => { history.push(`/registration/client/view/${id}`) }} />
                {status === 'ATIVO' ? (
                  <FontAwesomeIcon icon={faPen} className="ml-2 icon-hover" onClick={() => { status === "ATIVO" && history.push(`/registration/client/edit/${id}`) }} />
                ) : ''}
              </div>,
            ];
          })}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  )

  return (
    <PageDefault
      pageData={{
        pageContent: page
      }}
    />
  );
}

export default Client;
