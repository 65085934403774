import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 170px);
  background: #ececf3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
