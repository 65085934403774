import jwtDecode from 'jwt-decode';
import { api } from '../connection/api'


const URL = {
    region: `general/district-zone/districts`,
    excludedDates: `general/schedule/unavailable`,
    availabletimes: `general/schedule/available`,
    scheduleCreate: `associate/schedule`,
    consultation: `associate/consultation`,
    schedules: `/associate/schedules`,
    scheduleById: `/associate/schedule`,
    receipt: `/general/schedule/generate-receipt`
}

export async function getRegion() {
    return await api.get(URL.region);
}

export async function getExcludedDates({ districtId, month, year }) {
    return await api.get(`${URL.excludedDates}/${districtId}?month=${month}&year=${year}`);
}

export async function getAvailableTimes({ districtId, date }) {
    return await api.get(`${URL.availabletimes}/${districtId}?date=${date}`);
}

export async function getConsultation() {
    return await api.get(URL.consultation);
}

export async function createSchedule(data) {
    return await api.post(`${URL.scheduleCreate}`, data);
}

export async function updateSchedule(data) {
    return await api.put(`${URL.scheduleCreate}/${data.id}`, data);
}

export async function getScheduleById(id) {
    return await api.get(`${URL.scheduleById}/${id}`);
}

export async function getSchedules({ perPage, currentPage: page }) {
    return await api.get(`${URL.schedules}`, {
        params: {
            perPage,
            page
        }
    });
}

export async function generateReceipt(scheduleId) {
    return await api.get(`${URL.receipt}/${scheduleId}`);
}

export const zones = [
    {
        "name": "Região Norte",
        "code": "north_zone",
        "id": 0
    },
    {
        "name": "Região Sul",
        "code": "south_zone",
        "id": 1
    },
    {
        "name": "Centro",
        "code": "center_zone",
        "id": 2
    },
    {
        "name": "Região Leste",
        "code": "east_zone",
        "id": 3
    },
    {
        "name": "Região Oeste",
        "code": "west_zone",
        "id": 4
    }
]