import React from "react";
import { Button } from "react-bootstrap";

const ButtonSubmit = (props) => {
    const {
        variant,
        text,
        icon,
        size,
        iconColor,
        textColor,
        _class,
        action,
        disabled
    } = props.buttonData;

    return (
        <Button
            size={size}
            className={_class}
            style={{ color: textColor }}
            variant={variant}
            type="submit"
            onClick={action}
            disabled={disabled}
        >
            {icon && <i className={icon.concat(" mr-2")} style={{ color: iconColor }}></i>}
            {text}
        </Button>
    );
};

export default ButtonSubmit;
