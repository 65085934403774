export const ErrorMessages = {
  NETWORK_ERROR_MESSAGE : "Não foi possível carregar suas informações. Verifique sua conexão e tente novamente.",
  DEFAULT_ERROR_MESSAGE : "Tivemos um problema ao processar a solicitação. Por favor, tente novamente mais tarde.",
  DEFAULT_GENERAL_SUCCESS_MESSAGE : "Solicitação enviada com sucesso."
}

export const SuccessMessages = {
  SUCCESS_SEND_LEAD_TITLE: "Seu cadastro foi efetuado com sucesso!",
  SUCCESS_SEND_LEAD_MESSAGE: "Em breve entraremos em contato para finalizar o atendimento.",
  DEFAULT_REGISTER_SUCCESS_MESSAGE : "Cadastro efetuado com sucesso!.",
  SUCCESS_UPDATE_MEI_REQUEST: 'A solicitação de atualização dos seus dados foi realizada com sucesso',
}

export const AlertMessages = {

  CANNOT_EMITE_NFE: "Para regularizar a sua situação, é necessário que entre em contato com os órgãos reguladores para corrigir os dados. Após correção, é sóclicar no botão: Solicitar Atualização .",
  CANNOT_EMITE_NFSE: "Para regularizar a sua situação,, é necessário que entre em contato com os órgãos reguladores para corrigir os dados. Após correção, é só clicar no botão: Solicitar Atualização .",
  CANNOT_EMITE_NFSE_DETAIL: "Encontramos problemas no seu cadastro no Município.",
  CANNOT_EMITE_NFE_DETAIL: "Encontramos problemas no seu cadastro no Sefaz."
}

export const AlertSuccess = {

  CAN_EMITE_NFE:"Parabéns você está apto a emitir Nota Fiscal de Produto.",
  CAN_EMITE_NFSE:"Parabéns você está apto  a emitir Nota Fiscal de Serviço."
}

export const ValidationMessages = {
  MISSING_FIELD:  "Campo obrigatório",
  VALIDATION_CLIENT_SUCESS: "A validação dos dados foi efetuada com sucesso. Por favor, clique em salvar para concluir.",
  VALIDATION_CLIENT_PARTIAL_SUCESS: "Cadastro efetuado, mas ainda é necessário validar os dados. Você pode fazer isso em ações (Editar) e clicando no botão (Validar), após isso é só salvar, seu cadastro estará validado.",
  SUCCESS_CLIENT_SAVE: "Cadastro de cliente efetuado com sucesso.",
  INVALID_ZIPCODE_MESSAGE: "Cep inválido, por favor verifique",
  DEFAULT_REGISTER_SUCCESS_MESSAGE : "Cadastro efetuado com sucesso!."
}


