import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

export default function FileInput(props) {
  const { icon, onSelectFile, fileName } = props;

  return (
    <div className="inputFileContainer">
      <div
        className="inputButton"
        onClick={() => {
          const input = document.getElementById("input");
          input.click();
        }}
      >
        {/* <FontAwesomeIcon
          className="backIcon"
          icon={icon}
          size="2x"
          color="#64C2C8"
        /> */}
        <label>Certificado Digital</label>
      </div>

      <div className="fileName">
        {fileName ? fileName : "Nenhum arquivo selecionado"}
      </div>
      <input
        className="fileInput"
        type="file"
        id="input"
        onChange={(e) => {
          onSelectFile(e.target.files[0]);
        }}
      />
    </div>
  );
}
