import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const DialogAutoOpen = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { size, body } = props.dialogData;
    return (
        <Modal
            show={true}
            backdrop="static"
            keyboard={false}
            size={size ?? 'sm'}
        >
            <Modal.Body className="text-muted">
                {body}
            </Modal.Body>
        </Modal>
    );
};

export default DialogAutoOpen;
