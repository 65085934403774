import { api, viaCepApi } from '../connection/api'

export async function fetchStates() {
    return await api.get(`register/address/states`);
}

export async function fetchCities(stateId) {
    return await api.get(`register/address/states/${stateId}/cities`);
}

export async function buscaCep(cep) {
    return await viaCepApi.get(`${cep}/json`);
}