import "./App.css";
import React from "react";
import PageDefault from "../../components/pageDefault";
import { useHistory } from "react-router";

import {
  AcMarcas,
  BalcaoConsumidor,
  GrupoAtivarNegocios,
  ConsultaSCPC,
  ClubeDescontoVitrineSampa,
  AcCredito,
  AcspLegal,
  Jucesp,
  ReceitaFederal,
  Cemaac,
  DeclaracaoExclusividade,
} from "./logomarcas";

function Services() {
  const history = useHistory();
  const page = (
    <div className="container" style={{ paddingRight: 0, paddingLeft: 0 }}>
      <section className="col-12 mt-5">
        <div className="row mb-3">
          <div className="info col">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px 0px 30px 0px",
              }}
            >
              <h2 style={{ color: "#7B7B7B" }}>Serviços</h2>
            </div>
          </div>
        </div>
      </section>
      <hr className="mb-5" />
      <div className="section">
        <div className="row ml-1">
          <div className="col-4">
            <div className="acmarcas prop-card">
              <AcMarcas />
              <h2>ACMarcas</h2>
              <p>
                Aqui, a sua marca tem valor e precisa ser <br></br>protegida.
                Com uma consultoria de ponta, <br></br>facilitamos o processo de
                registro, <br></br>simplificando a aprovação.
              </p>
              <a href="https://acsp.com.br/lp-acmarcas" target="_blank">
                Saiba mais
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="balcao-consumidor prop-card">
              <BalcaoConsumidor />
              <h2>Balcão do Consumidor</h2>
              <p>
                A inadimplência não pode comprometer o seu <br></br>negócio.
                Deixe a cobrança da sua carteira de<br></br> clientes
                inadimplentes nas mãos de quem <br></br>entende do assunto.
              </p>
              <a
                href="https://acsp.com.br/lp-balcao-consumidor"
                target="_blank"
              >
                Saiba mais
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="grupo-ativar prop-card">
              <GrupoAtivarNegocios />
              <h2>Grupo Ativar Negócios</h2>
              <p>
                Coloque seu networking em dia fazendo parte <br></br>de um grupo
                de WhatsApp que reúne centenas <br></br>de empreendedores para
                gerar oportunidades <br></br>e parcerias.
              </p>
              <a
                href="https://acsp.com.br/lp-ativar-os-negocios"
                target="_blank"
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-5 ml-1">
          <div className="col-4">
            <div className="consultas prop-card">
              <ConsultaSCPC />
              <h2>Consultas de Crédito</h2>
              <p>
                Faça suas vendas a prazo com mais segurança <br></br>contando
                com as análises de crédito <br></br>avançadas do ACConsulta,
                parceiro de <br></br>
                negócios da Boa Vista SCPC.
              </p>
              <a href="https://acsp.com.br/acconsulta" target="_blank">
                Saiba mais
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="vitrine prop-card">
              <ClubeDescontoVitrineSampa />
              <h2>
                Clube de Descontos <br></br>Vitrine Sampa
              </h2>
              <p>
                Descontos são sempre bem-vindos né? Acesse <br></br>a nossa
                plataforma e adquira produtos e <br></br>serviços para você e o
                seu negócio com <br></br>condições imperdíveis.
              </p>
              <a href="https://clubededescontos.acsp.com.br" target="_blank">
                Saiba mais
              </a>
            </div>
          </div>
          {/* <div className="col-4">
            <div className="accredito prop-card">
              <AcCredito />
              <h2>ACCrédito</h2>
              <p>
                A ACSP acredita nos seus sonhos. Oferemos <br></br>crédito
                rápido, descomplicado e sem garantias <br></br>físicas para a
                sua empresa decolar.
              </p>
              <a href="https://www.accreditodigital.com.br" target="_blank">
                Saiba mais
              </a>
            </div>
          </div> */}
          <div className="col-4">
            <div className="acsp-legal prop-card">
              <AcspLegal />
              <h2>ACSP Legal</h2>
              <p>
                Advogados e Contadores, chega desse vai e <br></br>
                vem de documentos. Com o ACSP Legal, o <br></br>
                processo de legalização de empresas é <br></br>
                aprovado no primeiro envio.
              </p>
              <a href="https://legal.acsp.com.br" target="_blank">
                Saiba mais
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-5 ml-1">
          <div className="col-4">
            <div className="jucesp-serviço prop-card">
              <Jucesp />
              <h2>JUCESP</h2>
              <p>
                Tempo é dinheiro. Resolva os principais <br></br>
                processos da Junta Comercial em até uma <br></br>
                hora pelo VRE Digital ou em até seis horas pelo <br></br>VRE.
              </p>
              <a
                href="https://acsp.com.br/servicos/s/junta-comercial"
                target="_blank"
              >
                Saiba mais
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="receita-federal-serviço prop-card">
              <ReceitaFederal />
              <h2>Posto da Receita Federal</h2>
              <p>
                Esteja em dia com suas obrigações fiscais. <br></br>
                Resolva as principais demandas da Receita <br></br>Federal no
                nosso posto de atendimento <br></br>exclusivo para pessoas
                jurídicas.
              </p>
              <a
                href="https://acsp.com.br/servicos/s/receita-federal"
                target="_blank"
              >
                Saiba mais
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="cemaac-serviço prop-card">
              <Cemaac />
              <h2>CEMAAC</h2>
              <p>
                Não espere meses para que seu processo seja <br></br>julgado.
                Resolva seus conflitos empresariais, <br></br>societários e
                trabalhistas de forma <br></br>extrajudicial com rapidez e
                segurança na <br></br>
                Câmara de Mediação e Arbitragem da ACSP.
              </p>
              <a href="https://camara.acsp.com.br" target="_blank">
                Saiba mais
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-5 ml-1">
          <div className="col-4">
            <div className="exclusividade-serviço prop-card">
              <DeclaracaoExclusividade />
              <h2>Declaração de Exclusividade</h2>
              <p>
                Abra novas oportunidades de vendas. Com esse <br></br>
                documento, você atesta que seu produto ou <br></br>serviço é
                exclusivo no mercado e agiliza o <br></br>processo de licitação.
              </p>
              <a
                href="https://acsp.com.br/servicos/s/declaracao-de-exclusividade"
                target="_blank"
              >
                Saiba mais
              </a>
            </div>
          </div>

          <div className="col">
            {/* <CardService
              title="AC Lojas"
              body="Plataforma de vendas
                online desenvolvida pela
                ACSP para apoiar o
                comércio de bairro.
                Compre e venda seus
                produtos sem sair de casa."
              showImage="true"
              imagePath={iconsIMG.ACLojas}
              imageAlt="AC Lojas"
              imgWidth="50%"
              linkUrl="https://vitrinesampa.com.br"
              linkText="Saiba mais"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PageDefault
      pageData={{
        pageContent: page,
      }}
    />
  );
}

export default Services;
