import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import "./style.css";

export default function ParcelsCard({ title, noResultsMsg, icon, cardLinkTo, itemLinkTo, data}) {

  const renderItem = (item) => {
    return (
      <Link
        className="cardInfo mt-0 mx-2"
        to={itemLinkTo + "/" + item.id}
        key={item.id}
      >
        <div className="flex">
          <img className="m-0" src={icon} alt="item"></img>
          <p className="abbreviation" > {item.name}</p>
        </div>
        <div className="mt-3">
          <p>{`${item.parc_num}/${item.parc_amount}`}</p>
        </div>
        <div className="flex status">
          <p>{parseFloat(item.parc_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
          <i className="bi bi-chevron-right ml-2" />
        </div>
      </Link>
    )
  }

  return (
    <div>
      <div className="card heig">
        <div className="DASTitle row justify-content-between m-1">
          <h5>{title}</h5>
          <h5>{moment(new Date()).format('DD/MM/YYYY')}</h5>
        </div>

        { !!data.length &&
          data?.map(item => renderItem(item))
        }
        {
          !data.length &&
          (
            <div className="text-center mt-3">
              <p>{noResultsMsg}</p>
            </div>
          )
        }

        <Link to={cardLinkTo} className="linkCard">
          <p>Ver todas</p>
        </Link>
      </div>
    </div>
  )
}
