import '../App.css';
import PageDefault from '../../../components/pageDefault';
import Dialog from '../../../components/dialog';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import ButtonDefault from '../../../components/buttonDefault';
import Currency from '../../../components/inputs/currency';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { fetchDasn, getDasn, downloadDasnByAssociated, lastUpdateLog,updateAvailable } from '../../../services/dasn';
import { propTypes } from 'react-bootstrap/esm/Image';
import { PaymentStatus } from '../../../enumerations/paymentStatusDAS';
import {
    NotificationManager,
    NotificationContainer,
  } from "react-notifications";

function AnnualDeclaration() {

    const history = useHistory()
    const [dasn, setDasn] = useState([])
    const [dasnApi, setDasnApi] = useState([])
    const [fetch, setFetch] = useState(true);
    const [dialogShow, setDialogShow] = useState(false);
    const [currentDasn, setCurrentDasn] = useState({});

    const [hasEmployee, setHaveRegisteredEmployee] = useState(null)
    const [totalTrade, setTotalComerceRevenue] = useState()
    const [totalService, setTotalServiceRevenue] = useState()
    const [totalRevenue, setTotalRevenue] = useState()
    const [confirmInformations, setConfirmInformations] = useState(null)

    const [isRectifier, setIsRectifier] = useState(false)

    const [atualizarDasn, setAtualizarDasn] = useState(true);
    const [atualizarDasnTitle, setAtualizarDasnTitle] = useState("Atualizar Registros Dasn");
    const [loading, setLoading] = useState(false);
    const [buttonClass, setButtonClass] = useState('');

    useEffect(() => {
        fetchDasn().then(({ data }) => {
            const dasns = data.map(item => {
                const { id, year, status,has_employee,total_trade, total_service } = item;
                const dasn = {
                    id: id,
                    year: year,
                    status: status,
                    hasEmployee: has_employee,
                    totalTrade: total_trade,
                    totalService: total_service
                }
                return dasn;
            });
            setDasnApi(dasns);
            setFetch(false);
        }).catch();
        checkAtualizarDasn();
    }, [fetch]);
    const checkAtualizarDasn = async () => {
        console.log("verificando disponivibilidade de botao atualizar dasn");
        await updateAvailable({funcao:"update-dasn",type:"dasn"}).then(
          (response) => {
            if(response.data.horasRestantes <= 0) {
              setAtualizarDasn(true);
              setAtualizarDasnTitle("Atualizar DAS");
              setButtonClass('btn-info');
            } else {
              setAtualizarDasn(false);
              setAtualizarDasnTitle(`Atualização disponível aproximadamente daqui a ${response.data.horasRestantes} horas`);
              setButtonClass('btn-danger');
            }

          }
        ).catch((error) => {
          console.log("erro na verificacao disponibilidade de botao de atualizacao DAS")
        })
    };
    const formSchema = yup.object().shape({
        totalTrade: yup.number()
            .required("Campo obrigatório."),
        totalService: yup.string()
            .required("Campo obrigatório."),
        totalRevenue: yup.number()
            .required("Campo obrigatório."),
        confirmInformations: yup.boolean()
            .nullable("Campo obrigatório.")
            .isFalse("Campo obrigatório.")
    });
    
    const handleGetDasn = (dasnId) => {
        if (dasnId) getDasn(dasnId).then(({ data }) => {
            setCurrentDasn(data);
            setDialogShow(true);
            const { status } = data
            if (status === "SENT"){
                setIsRectifier(true)
            }else{
                setIsRectifier(false)
            }
        })
    };
    const updateDASN = async () => {
        console.log("executando atualizacao da listagem da dasn");
        if(atualizarDasn == false) {
            return;
        }
        setAtualizarDasn(false);
        setAtualizarDasnTitle("Aguarde um momento, estamos sincronizando com os dados da receita federal");
        setButtonClass('btn-primary');
        setLoading(true);
        await downloadDasnByAssociated().then(
            (response) => {

                setLoading(false);
                if (response.status === 200) {

                    fetchDasn().then(({ data }) => {
                        const dasns = data.map(item => {
                            const { id, year, status } = item;
                            const dasn = {
                                id: id,
                                year: year,
                                status: status
                            }
                            return dasn;
                        });
                        setDasnApi(dasns);
                        setFetch(false);
                        lastUpdateLog({funcao:"update-dasn", type:"dasn"});
                        setAtualizarDasn(false);
                        setAtualizarDasnTitle(`Atualização indisponível no momento`);
                        setButtonClass("btn-danger");
                    }).catch();
                    // setDases(response.data.results);

                    NotificationManager.success("atualização realizada com sucesso");
                  }
        }).catch((error) => {

          let msg = error.response ? error.response.data.message : "Erro na solicitação";
          setAtualizarDasnTitle("Atualizar DASN");
          setButtonClass("btn-info");
          setLoading(false);
          setAtualizarDasn(true);
          NotificationManager.error(msg);
        });  
      };
   
    /**
     * Conteúdo do modal caso esteja com status Entregue
     */
    const modalDeliveredContent = (currentDasn) => {
        return (
            <div div className="row" >
                <div className="col-12">
                    <p className="text-muted m-0">Declaração referente a:</p>
                    <p className="text-muted font-weight-bold m-0"> {currentDasn.year} </p>
                </div>
                <div className="col-12 mt-2">
                    <p className="text-muted m-0">Tem algum funcionário registrado:</p>
                    <p className="text-muted font-weight-bold m-0"> {currentDasn.hasEmployee ? 'Sim' : 'Não'} </p>
                </div>
                <div className="col-12 mt-2">
                    <p className="text-muted m-0">Total faturado em comércio:</p>
                    <p className="text-muted font-weight-bold m-0"> R$ {Currency({ value: parseFloat(currentDasn.totalTrade) })} </p>
                </div>
                <div className="col-12 mt-2">
                    <p className="text-muted m-0">Total faturado na prestação de serviços:</p>
                    <p className="text-muted font-weight-bold m-0"> R$ {Currency({ value: parseFloat(currentDasn.totalService) })} </p>
                </div>
                <div className="col-12 mt-2">
                    <p className="text-muted m-0">Faturamento total:</p>
                    <p className="text-muted font-weight-bold m-0"> R$ {Currency({ value: parseFloat(currentDasn.totalService + currentDasn.totalTrade) })} </p>
                </div>
                <div className="col-12 mt-2">
                    <p className="text-muted m-0">Status de declaração:</p>
                    <p className="text-success font-weight-bold m-0"> {currentDasn.status === 'SENT' ? 'Entregue' : 'Em aberto'} </p>
                </div>
                <div className=" col-12 d-flex justify-content-center mt-3">
                    <ButtonDefault
                        buttonData={{
                            variant: "info",
                            text: `Enviar DASN Retificadora`,
                            action: () => history.push(`/financial/annual_declaration/create/${currentDasn.year}/true`),
                            icon: "",
                            _class: "font-weight-bold text-info",
                            iconColor: "",
                            size: "",
                            textColor: "#FFF"
                        }}
                    />
                </div>
            </div>
        )
    }

    /**
    * Conteúdo do modal caso esteja com status Em aberto
    */
    const modalOpenContent = (year) => {
        return (
            <div div className="row" >
                <div className="col-12">
                    <p className="text-muted m-0">Atenção! Preencha com cuidado, pois os dados a seguir serão enviados para a RECEITA FEDERAL!</p>
                    <p className="text-muted mt-3 m-0">Esta declaração se refere ao ano de {year}!</p>
                </div>
                <div className=" col-12 d-flex justify-content-center mt-3">
                    <ButtonDefault
                        buttonData={{
                            variant: "info",
                            text: "Ok! Entendi",
                            action: () => history.push(`/financial/annual_declaration/create/${year}/${isRectifier}`),
                            icon: "",
                            _class: "font-weight-bold",
                            iconColor: "#58c5c6",
                            size: "",
                            textColor: "#FFF",
                        }}
                    />
                </div>
            </div>
        )
    }

    /**
     * Card que lista os dasn's
     */
    const dasnList = (
        <div className="shadow p-4 border rounded">
            {dasnApi.length === 0 && <p className="h4">Nenhum item encontrado! Clique no botão atualizar para buscar os registros diponíveis</p>}
            {dasnApi.map(item => {
                return (
                        <div key={item.id} className="mt-1">
                        {item.status === "UNKNOWN" ? (
                            <div>
                                <button class="col-12 text-left border-bottom border-muted btn btn-light">
                                    <div className="d-flex justify-content-between align-items-center" id={item.id}>
                                    <i className="bi bi-calendar-check d-flex align-items-center" style={{ color: "#58c5c6", fontSize: "1.5rem" }}></i>
                                    <p className="m-0 ml-3 flex-grow-1 text-muted font-weight-bold">{item.year}</p>
                                    <p className="m-0 mr-3 font-weight-bold text-info">Não optante</p>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <Dialog
                            dialogData={{
                                buttonText: (
                                <div>
                                    <div className="d-flex justify-content-between align-items-center" id={item.id}>
                                    <i className="bi bi-calendar-check d-flex align-items-center" style={{ color: "#58c5c6", fontSize: "1.5rem" }}></i>
                                    <p className="m-0 ml-3 flex-grow-1 text-muted font-weight-bold">{item.year}</p>
                                    <p className={"m-0 mr-3 font-weight-bold".concat(item.status === 'SENT' ? ' text-success' : ' text-warning')}>{item.status === 'SENT' ? 'Entregue' : (item.status === 'PROCESSANDO' ? 'Processando' : 'Em aberto')}</p>
                                    <i className="bi bi-chevron-right text-muted font-weight-bold mr-3"></i>
                                    </div>
                                </div>
                                ),
                                buttonClass: "col-12 text-left border-bottom border-muted",
                                buttonVariant: "light",
                                showHeader: true,
                                showBody: true,
                                dialogTitle: item.status === 'SENT' ? 'Informação imposto DASN' : (item.status === 'PROCESSANDO' ? 'Sua DASN está sendo processada, para verificar se já foi processada, clique em atualizar' : 'Atenção!'),
                                dialogBody: item.status === 'SENT' ? modalDeliveredContent(item) : (item.status === 'PROCESSANDO' ? '' : modalOpenContent(item.year))
                            }}
                            />
                        )}
                        </div>
                )
            })}
        </div>
    )

    /**
     * Parte da interface que mostra card com algumas informações e logo da acsp
     */
    const consult = (
        <div className="mt-4">
            <div>
                <p className="text-muted font-weight-bold">Está com dificuldades para gerar seu DASN?</p>
            </div>
            <div className="shadow p-4 border rounded">
                <div>
                    <p className="text-muted font-weight-bold">Agende sua consultoria</p>
                </div>
                <div>
                    <p className="text-muted">
                        Os consultores da Associação Comercial de São Paulo estão prontos para te ajudar!
                    </p>
                </div>
                <div className="d-flex justify-content-end">
                    <Link to="/consultancy">Saiba mais</Link>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <img className="img-fluid" src="/blackacsplogo.svg" />
                </div>
            </div>
        </div>
    )

    const page = (
        <div className="row ">
            <div className="col-12 m-0 p-0 d-flex align-items-baseline justify-content-start flex-wrap">
                <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                    <h1 style={{ color: "#002c43" }}>
                        <FontAwesomeIcon
                            className="back-icon mr-3 icon-hover"
                            icon={faArrowLeft}
                            size="1x"
                            onClick={() => history.push("/home")}
                            color="#002c43"
                            style={{ hover: "cursor: pointer" }}
                        />
                            Declaração anual - DASN
                        </h1>
                </div>
            </div>
            <div className="col-6 mt-3">
                <button
                    className={`btn ${buttonClass} ${atualizarDasn ? '' : ' disabled'} `}
                    onClick={(e) => updateDASN()}
                >
                {atualizarDasnTitle}
                {loading ?  (<span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>) : ''}
                </button>
                
            </div>
            <div className="col-12 col-md-10 mt-3 lista-dasn">
                {loading && (
                <div className="coverDasn" style={{ position: "absolute", background: "rgba(0, 0, 0, 0.2)", top: 0, width: "100%", height: "100%", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="spinner-border text-light" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
                </div>
                )}
                {dasnList}
            </div>
            <div className="col-12 col-lg-4 mt-3">
                {consult}
            </div>
        </div>
    )

    return (
        <PageDefault
            pageData={{
                pageContent: page,
            }}
        />
    );
}

export default AnnualDeclaration;
