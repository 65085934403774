import '../App.css';
import { useHistory, useParams } from 'react-router-dom';
import PageDefault from '../../../components/pageDefault';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ButtonSubmit from '../../../components/buttonSubmit';
import ButtonActionDefault from '../../../components/buttonAction';
import { useState } from 'react'
import * as yup from 'yup'
import { putUpdateEmail } from '../../../services/associate/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function ChangeEmail() {
    const [email, setEmail] = useState();
    const [confirmEmail, setConfirmEmail] = useState();
    const [isAlterEmail, setIsAlterEmail] = useState(false);
    const { token } = useParams();
    const [backErrors, setBackErrors] = useState({});

    const clearBackErrors = (field) => {
      backErrors[field] = null;
      setBackErrors(backErrors);
  };

    const validation = yup.object().shape({
        email: yup.string()
            .email("Insira um e-mail válido!")
            .oneOf([confirmEmail], "O e-mail deve ser igual ao campo Confirmar e-mail")
            .required("Campo obrigatório!"),
        confirmEmail: yup.string()
            .email("Insira um e-mail válido!")
            .oneOf([email], "Confirmar e-mail deve ser igual ao campo Novo e-mail")
            .required("Campo obrigatório!")
    })

    const history = useHistory()
    const page = (
        <div className="row d-flex justify-content-start">
            <div className="col-12 m-0 p-0 d-flex align-items-center flex-wrap">
                <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                    <h1 style={{ color: "#002c43" }}>
                        <FontAwesomeIcon
                            className="back-icon mr-3 icon-hover"
                            icon={faArrowLeft}
                            size="1x"
                            onClick={() => history.push("/home")}
                            color="#002c43"
                            style={{ hover: "cursor: pointer" }}
                        />
                        Alterar Email
                    </h1>
                </div>
            </div>

            <div className="col-11 p-0 col-md-6 border rounded shadow m-4 p-4">
                <p className="text-muted">Informe seu novo e-mail</p>

                <div>
                    <Formik
                        initialValues={{
                            email,
                            confirmEmail
                        }}
                        validationSchema={validation}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            putUpdateEmail({
                              email: values.email,
                              emailToken: token
                            }).then(response => {
                              clearBackErrors('email')
                              localStorage.removeItem('jwt_auth')
                              setIsAlterEmail(true);
                            }).catch(err => {
                              if(err.response) {
                                err.response.data.errors.forEach(error => {
                                  const { fieldName, message } = error
                                  setBackErrors({ [fieldName]: message })
                                })
                              }
                            })
                        }}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Form>
                                <div>
                                    <p className="text-muted m-0">Novo e-mail <label className="text-danger">*</label></p>
                                    <Field
                                        name="email"
                                        className="form-control form-control-sm"
                                        placeholder="Informe o novo e-mail"

                                        onChange={(data) => {
                                            setEmail(data.target.value)
                                            setFieldValue("email", data.target.value)
                                        }}
                                    />
                                    <ErrorMessage className="text-danger" component="small" name="email" />
                                </div>
                                <div className="mt-3">
                                    <p className="text-muted m-0">Confirmar novo e-mail <label className="text-danger">*</label></p>
                                    <Field
                                        name="confirmEmail"
                                        className="form-control form-control-sm"
                                        placeholder="Confirme o novo e-mail"

                                        onChange={(data) => {
                                            setConfirmEmail(data.target.value)
                                            setFieldValue("confirmEmail", data.target.value)
                                        }}
                                    />
                                    <ErrorMessage className="text-danger" component="small" name="confirmEmail" />
                                    {backErrors.email && <p className="error"> {backErrors.email} </p>}
                                    {isAlterEmail ? <p className="text-success"> Email alterado com sucesso, será necessario realizar o login novamente!</p> : null}
                                </div>
                                <div className="d-flex flex-row mt-3">
                                    <div>
                                        <ButtonSubmit
                                            buttonData={{
                                                variant: "info",
                                                text: "Alterar",
                                            }}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <ButtonActionDefault
                                            buttonData={{
                                                text: "Voltar",
                                                variant: "outline-info",
                                                action: () => history.goBack()
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            {
                backErrors.token && (
                    <div className="col-11 p-0 col-md-6 m-4 d-flex align-items-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} color="#e9655f" size="2x" />
                        <small className="text-danger ml-3">Por questões de segurança o tempo para alteração de e-mail foi excedido. <a href="#">Clique aqui</a> para solicitar novamente.</small>
                    </div>
                )
            }
        </div>
    )

    return (
        <PageDefault
            pageData={{
                pageContent: page
            }}
        />
    );
}

export default ChangeEmail;
