import { api } from '../connection/api'

export async function getDigitalCertificate() {
    return await api.get(`associate/digital-certificate`);
}

export async function postDigitalCertificate(digitalCertificate) {
    return await api.post(`associate/digital-certificate`, digitalCertificate);
}

export async function putDigitalCertificate(digitalCertificate) {
    return await api.put(`associate/digital-certificate`, digitalCertificate);
}

export async function deleteDigitalCertificate() {
    return await api.delete(`associate/digital-certificate`);
}