import React from "react";
import acmarcas from "../../imgs/servicos/ac_marcas.svg";
import balcao from "../../imgs/servicos/balcao_consumidor.svg";
import grupoAtivarNegocios from "../../imgs/servicos/grupo_ativar_negocios.svg";
import consultaSCPC from "../../imgs/servicos/consulta_scpc.svg";
import clubeDescontoVitrineSampa from "../../imgs/servicos/clube_desconto_vitrine_sampa.svg";
import acCredito from "../../imgs/servicos/ac_credito.svg";
import acspLegal from "../../imgs/servicos/acsp_legal.svg";
import jucesp from "../../imgs/servicos/jucesp.svg";
import receitaFederal from "../../imgs/servicos/receita_federal.svg";
import cemaac from "../../imgs/servicos/cemaac.svg";
import declaracaoExclusividade from "../../imgs/servicos/declaracao_exclusividade.svg";

const SvgComponent = ({ svg }) => <img src={svg} alt="Logomarca" />;

export const AcMarcas = () => <SvgComponent svg={acmarcas} />;
export const BalcaoConsumidor = () => <SvgComponent svg={balcao} />;
export const GrupoAtivarNegocios = () => (
  <SvgComponent svg={grupoAtivarNegocios} />
);
export const ConsultaSCPC = () => <SvgComponent svg={consultaSCPC} />;
export const ClubeDescontoVitrineSampa = () => (
  <SvgComponent svg={clubeDescontoVitrineSampa} />
);
export const AcCredito = () => <SvgComponent svg={acCredito} />;
export const AcspLegal = () => <SvgComponent svg={acspLegal} />;
export const Jucesp = () => <SvgComponent svg={jucesp} />;
export const ReceitaFederal = () => <SvgComponent svg={receitaFederal} />;
export const Cemaac = () => <SvgComponent svg={cemaac} />;
export const DeclaracaoExclusividade = () => (
  <SvgComponent svg={declaracaoExclusividade} />
);
