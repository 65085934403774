import React, { useEffect, useState } from "react";
import { Box, padding } from "@mui/system";
import LogoACSP from "../../../imgs/icons/logoACSP.svg";
import { Link } from "react-router-dom";

import "./style.css";

export default function AgendeConsultoriaCard() {
  return (
    <Box
      className="card heig card-agende-consultoria"
      sx={{
        textAlign: "center",
      }}
    >
      <h3>
        <b>Agende sua consultoria</b>
      </h3>
      <p
        style={{ fontSize: "14px", padding: "15px 0 10px 0", color: "#7B7B7B" }}
      >
        Os Consultores da <br></br> Associação Comercial de <br></br> São Paulo
        estão prontos <br></br> para te ajudar!
      </p>
      <Link to="/consultancy" className="linkCard" style={{ alignSelf: "center" }}>
        <p className="saiba-mais">Saiba mais!</p>
      </Link>
      <img className="logo-agende-consultoria" src={LogoACSP} alt="Logo ACSP" />
    </Box>
  );
}
