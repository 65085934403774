import { api } from "../connection/api";

const baseEndpoint = "payment/recurrence";

export async function cancelRecurrence(associatedId) {
  return await api.delete(`${baseEndpoint}/cancel/${associatedId}`);
}

export async function checkRecurrenceActive(associatedId) {
  return await api.get(`${baseEndpoint}/checkRecurrenceActive/${associatedId}`);
}
