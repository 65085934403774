import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import PasswordInput from "../passwordInput";
import {
  deleteDigitalCertificate,
  putDigitalCertificate,
} from "../../services/digitalCertificate/index";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

//-------------------------------------------------------
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//-------------------------------------------------------
import "./style.css";

export default function CertificateList(props) {
  const { data, onDeleteCertificate, onChangeCertificate } = props;

  const [certificate, setCertificate] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(" ");
  const [password, setPassword] = useState(" ");
  const [valid, setValid] = useState(true);
  const [editShow, setEditShow] = useState(false);
  const [show, setShow] = useState(false);
  const [truthyPassword, setTruthyPassword] = useState(true);

  //-------------------------------------------------------
  // const options = ["Editar", "Excluir"];

  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };
  //-------------------------------------------------------

  const [backErrors, setBackErrors] = useState({});
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };

  const handleClose = () => {
    setShow(false);
    setEditShow(false);
  };

  const handleShow = (certificate) => {
    setCertificate(certificate);
    setShow(true);
  };

  const handleEditShow = (certificate) => {
    setCertificate(certificate);
    setEditShow(true);
  };

  const deleteCertificate = () => {
    deleteDigitalCertificate()
      .then((res) => {
        onDeleteCertificate(certificate);
        handleClose();
      })
      .catch((err) => {
        handleClose();
      });
  };

  const changePassword = () => {
    const { id } = certificate;
    const data = {
      id,
      password,
      confirmPassword,
    };

    if (password.length < 4) {
      setTruthyPassword(false);
      return;
    }

    if (password !== confirmPassword) {
      setValid(false);
      return;
    }

    putDigitalCertificate(data)
      .then((res) => {
        setPassword("");
        setConfirmPassword("");
        onChangeCertificate();
        handleClose();
      })
      .catch((err) => {
        const errors = {};

        if (err.response)
          err.response.data.errors.forEach((item) => {
            const { fieldName, message } = item;

            if (fieldName == "digitalCertificte") errors.file = message;
            else errors[fieldName] = message;
          });

        setBackErrors(errors);
      });
  };

  useEffect(() => {
    clearBackErrors("password");
    clearBackErrors("confirmPassword");

    if (password.length >= 4) {
      setTruthyPassword(true);
    }

    password === confirmPassword ? setValid(true) : setValid(false);
  }, [password, confirmPassword]);

  const renderCertificate = (certificate) => {
    return (
      <tr key={certificate.id}>
        <td style={{ paddingRight: "150px", whiteSpace: "nowrap" }}>
          {certificate.file}
        </td>
        <td style={{ paddingRight: "50px" }}>{certificate.valid}</td>
        {certificate.status ? (
          <td className="cert-active">
            <span className="cert-active-icon">
              <CheckCircleOutlineRoundedIcon
                sx={{
                  marginRight: "5px",
                  padding: "2px",
                }}
              />
            </span>
            <p className="cert-active-p">ATIVO</p>
          </td>
        ) : (
          <td className="cert-inactive">
            <ErrorOutlineRoundedIcon />
            SUSPENSO
          </td>
        )}
        <td className="iconsActions">
          {/* ---------- Menu(...) início ---------- */}
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                marginTop: "-5px",
                padding: "5px",
                transform: "rotate(90deg)",
                color: "#7B7B7B",
                "&:hover": {
                  color: "#7B7B7B",
                  backgroundColor: "#EBEBEB",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClosee}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "100px",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleEditShow(certificate);
                  handleClosee();
                }}
                sx={{
                  color: "#7B7B7B",
                  "&:hover": {
                    color: "#7B7B7B",
                    backgroundColor: "#EBEBEB",
                  },
                }}
              >
                Editar
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleShow(certificate);
                  handleClosee();
                }}
                sx={{
                  color: "#7B7B7B",
                  "&:hover": {
                    color: "#7B7B7B",
                    backgroundColor: "#EBEBEB",
                  },
                }}
              >
                Excluir
              </MenuItem>
            </Menu>
          </div>
          {/* ---------- Menu(...) fim ---------- */}
        </td>
      </tr>
    );
  };

  return (
    <div className="certificateListContainer">
      <p>Certificado</p>
      <Table>
        <thead>
          <tr>
            <th style={{ fontSize: "16px" }}>Arquivo</th>
            <th style={{ fontSize: "16px" }}>Válido até</th>
            <th style={{ fontSize: "16px" }}>Status</th>
            <th style={{ fontSize: "16px" }}>Ações</th>
          </tr>
        </thead>
        <tbody className="certificateListBody">
          {data.map((certificate) => renderCertificate(certificate))}
        </tbody>
      </Table>

      {/*Modals*/}
      <Modal
        centered
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        className="modalDeleteCertificate"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modalBody">
          <p>
            Deseja excluir <br></br> esse certificado?
          </p>
          <div className="modalButtonContainer">
            <Button
              className="modalButton btn-nao"
              variant="info"
              onClick={handleClose}
            >
              NÃO
            </Button>

            <Button
              variant="outline-info"
              onClick={deleteCertificate}
              className="modalButton btn-sim"
            >
              SIM
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={editShow}
        onHide={handleClose}
        className="modalPasswordContainer"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modalPassword">
          <h4>
            Insira a nova senha do <br></br>Certificado Digital
          </h4>
          <div className="container-inputChangePassword">
            <div className="inputChangePassword">
              <PasswordInput
                placeholder="Informe a senha"
                title="Senha do certificado digital"
                onChangePassword={setPassword}
              />
              {truthyPassword ? (
                ""
              ) : (
                <p className="error"> Mínimo 4 caracteres</p>
              )}
              {backErrors.password && (
                <p className="error"> {backErrors.password} </p>
              )}
            </div>

            <PasswordInput
              placeholder="Confirme a senha"
              title="Confirme senha do certificado digital"
              onChangePassword={setConfirmPassword}
            />
            <p className="error">{valid ? "" : "Senhas não correspondem! "} </p>
            {backErrors.password && (
              <p className="error"> {backErrors.password} </p>
            )}
          </div>
          <div className="container-btn-password">
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              className="modalButton btn-cancelar"
            >
              Cancelar
            </Button>

            <Button
              className="modalButton btn-salvar"
              variant="info"
              onClick={() => {
                changePassword();
              }}
            >
              Salvar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
