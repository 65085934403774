import React from "react"
import { OverlayTrigger, Popover, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./style.css"

export default function popoverUser(props) {

  const renderName = (name) => {
    return name.length > 16 ? name.substring(0, 16) + '...' : name
  }
  const popover = (
    <Popover id="popover-user">
      <Popover.Content>
        <div className="userHeader">
          <strong>{renderName(props.data.nomeFantasia)}</strong><br/>
          <small className="email">{props.data.email}</small>
        </div>
        <div className="userBody">
          <Link
            to="/profile/edit"
          >
            <p>Editar perfil</p>
          </Link>
          <Link
            onClick={()=> {localStorage.removeItem('jwt_auth')}}
            to={'/'}
          >
            <p>Sair</p>
          </Link>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <button className="userInfo">
          <strong>{renderName(props.data.nomeFantasia)}</strong>
          <p>{props.data.email}</p>
         </button>
      </OverlayTrigger>
    </div>
  )
}