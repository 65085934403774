/* eslint-disable no-restricted-globals */
import "../App.css";
import PageDefault from "../../../components/pageDefault";
import { useEffect, useState } from "react";
import Dialog from "../../../components/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBalanceScale,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Currency from "../../../components/inputs/currency";
import ButtonActionDefault from "../../../components/buttonAction";
import { PaymentStatus } from "../../../enumerations/paymentStatusDAS";
import { getAssociated } from "../../../services/associate";
import {
  getOne,
  getAllDas,
  downloadDasByAssociated,
  updateAvailable,
  lastUpdateLog,updateCurrentDas
} from "../../../services/das/index";
import moment from "moment";
import { useHistory } from "react-router";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

function PayTax() {
  const [dases, setDases] = useState([]);
  const [das, setDas] = useState({});
  const [allYears, setAllYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [atualizarDas, setAtualizarDas] = useState(true);
  const [atualizarDasTitle, setAtualizarDasTitle] = useState("Atualizar Das");
  const [buscarDas, setBuscarDas] = useState("Ver PDF");
  const [disableButtonDas, setDisableButtonDas] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [buttonClass, setButtonClass] = useState('');
  const [closeButton, setCloseButton] = useState(true);


  useEffect(() => {
    getAssociated().then(
      (response) => {
        const referenceDate = new Date(response.data.foundationDate);
        const referenceYear = referenceDate.getFullYear();
        const yearsArray = [];
        for (let year = currentYear; year >= referenceYear; year--) {
          yearsArray.push(year);
        }
        setAllYears(yearsArray);
      }).catch((error) => {
        let msg = error.response ? error.response.data.message : "Erro na solicitação buscar associado";
        NotificationManager.error(msg);
      });  
    getAllDas({ order: "ASC", perPage: 12, fieldOrder: "id", year:currentYear }).then(
      (response) => {
          setDases(response.data.results);
      }).catch((error) => {
        let msg = error.response ? error.response.data.message : "Erro na solicitação listar DAS";
        NotificationManager.error(msg);
      });  
    checkAtualizarDas();
  }, []);
  const updateDAS = async (year = null) => {
    if(!year) {
      year = document.getElementById('ano-das').value
    }
    if(atualizarDas == false) {
      return;
    }
    console.log("executando atualizacao da listagem da das");
    setAtualizarDas(false);
    setAtualizarDasTitle("Aguarde um momento, estamos sincronizando com os dados da receita federal");
    setButtonClass('btn-primary');
    setLoading(true);
    await downloadDasByAssociated({year:year}).then((res) => {
      getAllDas({ order: "ASC", perPage: 12, fieldOrder: "id", year:year }).then(
        (response) => {
          if (response.status === 200) {
            setDases(response.data.results);
            lastUpdateLog({year:year, funcao:"listar-das", type:"das"});
            setAtualizarDas(false);
            setAtualizarDasTitle(`Atualização indisponível no momento`);
            setButtonClass("btn-danger");
            setLoading(false);
            NotificationManager.success("atualização realizada com sucesso");
          }
        })
    }).catch((error) => {
      let msg = error.response ? error.response.data.message : "Erro na solicitação";
      setAtualizarDasTitle("Atualizar DAS");
      setButtonClass("btn-info");
      setLoading(false);
      setAtualizarDas(true);
      NotificationManager.error(msg);
    });  
  };

  const listarDAS = async (year = null) => {
    checkAtualizarDas(year);
    await getAllDas({ order: "ASC", perPage: 12, fieldOrder: "id", year:year }).then(
      (response) => {
        setDases(response.data.results);
      });

  
};
  const getOneDas = (id) => {
    setBuscarDas("Ver PDF");
    setDisableButtonDas(false);
    getOne(id).then((response) => {
      setDas(response.data);
    }).catch((error) => {
      NotificationManager.error("erro ao buscar dados da DAS selecionada");
    })
  };
    const checkAtualizarDas = async (year = null) => {
      console.log("verificando diponibilidade botao atualizar das");

      await updateAvailable({year:!year ? new Date().getFullYear() : year,funcao:"listar-das",type:"das"}).then(
        (response) => {
          if(response.data.horasRestantes <= 0) {
            setAtualizarDas(true);
            setAtualizarDasTitle("Atualizar DAS");
            setButtonClass('btn-info');
          } else {
            setAtualizarDas(false);
            setAtualizarDasTitle(`Atualização disponível aproximadamente daqui a ${response.data.horasRestantes} horas`);
            setButtonClass('btn-danger');
          }
        }
      ).catch((error) => {
        console.log("erro na verificacao disponibilidade de botao de atualizacao DAS")
      })
  };

  const abrirDAS = async (das) => {
   setBuscarDas(
      <div>
        Carregando arquivo na receita federal. Por favor, aguarde ...<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
  );
   setDisableButtonDas(true);
   setCloseButton(false);
   let apurarDas = true;
   if(das.path) {
    const dataAtual = new Date();
    const dataVencimento = new Date(das.dueDate);
    const dataUltimaAtualizacao = new Date(das.updated_at);
    if (dataVencimento > dataAtual) {
      apurarDas = false;
    }
  }
   if(!apurarDas) {
      console.log("pega do banco")
      setBuscarDas("Ver PDF");
      window.open(das.path, "_blank", "noopener,noreferrer");
      setDisableButtonDas(false);
      setCloseButton(true);
   } else {
      console.log("apura o das");
      await updateCurrentDas(das).then(
        (response) => {
          let filePath = response.data.filePath;
          das.path = response.data.filePath;
          window.open(filePath, "_blank", "noopener,noreferrer");
          setBuscarDas("Ver PDF");
          setDisableButtonDas(false);
        }
        ).catch((error) => {
          let msg = error.response ? error.response.data.message : "Erro na solicitação";
          NotificationManager.error(msg);
          setBuscarDas("Ocorreu um erro na solicitação");
        })
        setCloseButton(true);
    }
  };
  const meses = {
    Janeiro: 1,
    Fevereiro: 2,
    Março: 3,
    Abril: 4,
    Maio: 5,
    Junho: 6,
    Julho: 7,
    Agosto: 8,
    Setembro: 9,
    Outubro: 10,
    Novembro: 11,
    Dezembro: 12,
  };
  
  const modalContentPaid = () => {
    return (      
      <div div className="row">
        <div className="col-12">
          <p className="m-0">Boleto do imposto DAS referente a:</p>
          <p className="text-muted font-weight-bold m-0">
            {" "}
            {das.month} de {das.year}{" "}
          </p>
        </div>
        {das.dueDate && (
          <div className="col-12 mt-3">
            <p className="m-0">Vencimento em:</p>
            <p className="text-muted font-weight-bold m-0">
              {moment(das.dueDate).format("DD/MM/YYYY")}
            </p>
          </div>
        )}
        {das.paymentDate && (
          <div className="col-12 mt-3">
            <p className="m-0">Data de pagamento:</p>
            <p className="text-muted font-weight-bold m-0">
              {moment(das.paymentDate).format("DD/MM/YYYY")}
            </p>
          </div>
        )}
        <div className="col-12 mt-3">
          <p className=" m-0">Status do boleto:</p>
          <p
            className={"font-weight-bold m-0".concat(
              das.status === PaymentStatus.PAID
                ? " text-success"
                : " text-warning"
            )}
          >
            {das.status === PaymentStatus.PAID ? "Pago" : "Em aberto"}
          </p>
        </div>
        <div className="col-12 mt-3">
          <p className="m-0">Valor:</p>
          <p className="text-muted h4 font-weight-bold m-0">
            {" "}
            R$ {Currency({ value: parseFloat(das.value) })}{" "}
          </p>
        </div>

        {das.status !== PaymentStatus.PAID && das.slipBarcode && (
          <div className="row col-11 p-0 m-0 d-flex justify-content-between align-items-center">
            <hr className="col-12" />
            <div className="col mt-2">
              <p className="text-muted m-0">Código do boleto:</p>
              <p className="text-muted font-weight-bold m-0">
                {" "}
                {das.slipBarcode}{" "}
              </p>
            </div>
            <div className="d-flex align-items-end">
              <div>
                <div className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={faCopy}
                    size="2x"
                    id="icon"
                    color="#64c2c8"
                    onClick={() => {
                      navigator.clipboard.writeText(das.slipBarcode);
                    }}
                  />
                </div>
                <p className="m-0">Copiar</p>
              </div>
            </div>
            <hr className="col-12" />
          </div>
        )}
        {das.status !== PaymentStatus.PAID  && (
          <div className=" col-12 d-flex justify-content-center mt-3">
            <ButtonActionDefault
              buttonData={{
                text: buscarDas ? buscarDas : 'Ver PDF',
                variant: "outline-info",
                action: () => {
                  abrirDAS(das);
                },
                disabled:disableButtonDas
              }}
            />
            
          </div>
        )}
      </div>
    );
  };

  const modalDas = dases.map((currentDas) => {
    if(currentYear == currentDas.year && meses[currentDas.month] > currentMonth) {
      return null;
    }

    return (
      <Dialog
        dialogData={{
          showHeader: true,
          showBody: true,
          showCloseButton: closeButton,
          buttonText: (
            <div
              onClick={() => {
                getOneDas(currentDas.id);
              }}
              className="row"
            >
              <div className="col-5 row">
                <FontAwesomeIcon
                  className="mt-1"
                  icon={faBalanceScale}
                  color="#64c2c8"
                />
                <p className="ml-2 flex-grow-1 text-muted font-weight-bold">
                  {currentDas.month}
                </p>
              </div>
              <p className="col -3 ">
                {currentDas.dueDate
                  ? moment(currentDas.dueDate).format("DD/MM/YYYY")
                  : ""}
              </p>
              <p
                className={"m-0 mr-3 font-weight-bold".concat(
                  currentDas.status === PaymentStatus.PAID
                    ? " text-success"
                    : " text-warning"
                )}
              >
                {currentDas.status === PaymentStatus.PAID
                  ? "Pago!"
                  : "Em aberto"}
              </p>
              <i className="bi bi-chevron-right text-muted font-weight-bold mr-3"></i>
            </div>
          ),
          buttonClass: "col-12 text-left border-bottom border-muted",
          buttonVariant: "light",
          dialogTitle: "Informações imposto DAS",
          dialogBody: modalContentPaid(),
        }}
      />
    );
  });

  const renderYears = (year) => {
    return (
      <option
        selected={currentYear === year ? true : false}
        key={year}
        value={year}
      >
        {year}
      </option>
    );
  };

  const page = (
    <div>
      <div className="row d-flex align-items-center col-11">
        <div className="col-7 m-0 p-0 d-flex ">
          <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
            <h1 style={{ color: "#002c43" }}>
              <FontAwesomeIcon
                className="back-icon mr-3 icon-hover"
                icon={faArrowLeft}
                size="1x"
                onClick={() => history.goBack("")}
                color="#002c43"
                style={{ hover: "cursor: pointer" }}
              />
              Pagar imposto DAS
            </h1>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center col-11">
        {/* 
        <div className="col-2 mr-2 mt-3">
          <select
            className="custom-select"
            onChange={(e) => {
             // getAllDasByYear(selectedYear, e.target.value);
            }}
          >
            <option key="all" value="">
              Todos
            </option>
            <option key="WAITING" value="WAITING">
              Em aberto
            </option>
            <option key="PAID" value="PAID">
              Pagos
            </option>
          </select>
        </div>
        */}

        
        <div className="col-2 mt-3" >
          <select
            className="custom-select" id="ano-das"
            disabled={loading}
            onChange={(e) => {listarDAS(e.target.value)
            }}
          >
            {allYears?.map(renderYears)}
          </select>
        </div>
        
        <div className="col-6 mt-3">
          <button
            className={`btn ${buttonClass} ${atualizarDas ? '' : ' disabled'}`}
            onClick={(e) => updateDAS()}
          >
           {atualizarDasTitle}
           {loading ?  (<span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>) : ''}
          </button>
        </div>
      </div>
      <div className="col-11 shadow border rounded mt-4 p-0 flex flex-column">

      {loading && (
                <div className="coverDasn" style={{ position: "absolute", background: "rgba(0, 0, 0, 0.2)", top: 0, width: "100%", height: "100%", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="spinner-border text-light" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
                </div>
                )}
        <div className="d-flex flex-column px-4">
          {dases.length === 0 && <p className="h4">Nenhum item encontrado! Clique no botão atualizar para buscar os registros diponíveis</p>}
          {modalDas}
          <p className="my-2">
            <small>
              Observação: A situação é atualizada após a informação de
              compensação bancária.
            </small>
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <PageDefault
      pageData={{
        pageContent: page,
      }}
    />
  );
}

export default PayTax;
