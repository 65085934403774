import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import PageDefault from '../../../components/pageDefault'
import CardDefault from '../../../components/cardDefault'
import ButtonSubmit from '../../../components/buttonSubmit'
import DialogDefault from '../../../components/dialog'
import { ParagraphDefault } from '../../../components/typograph'
import { useEffect, useState } from 'react';
import * as yup from 'yup'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { putSolicitationUpdateEmail, putAlterPassword, putSolicitationInactiveAccount } from '../../../services/associate/index'
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';;

function EditProfile() {
    const history = useHistory()

   
    // primeria parte
    const [isEmailSended, setIsEmailSended] = useState(false)
    const [email, setEmail] = useState('')
    const [emailDataPassword, setEmailDataPassword] = useState()

    //segunda parte
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)
    const [currentPassword, setCurrentPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmNewPassword, setConfirmNewPassword] = useState()

    //terceira parte
    const [modalPassword, setModalPassword] = useState()
    const [justification, setJustification] = useState()
    const [isSendEmailToInactive, setIsSendEmailToInactive] = useState(false)
    const [backErrors, setBackErrors] = useState({});
    const clearBackErrors = (field) => {
      backErrors[field] = null;
      setBackErrors(backErrors);
  };

  useEffect(()=> {
    const token = localStorage.getItem('jwt_auth');
    const {email} = jwt_decode(token);

    setEmail(email);
  },[])

    const validation = yup.object().shape({
        email: yup.string()
            .required('Campo obrigatório.')
            .email('Insira um e-mail válido.'),
    })

    const validationPasswords = yup.object().shape({
        currentPassword:
            yup.string()
                .required('Campo obrigatório')
                .min(8, 'Digite no mínimo 8 caracteres.'),
        newPassword:
            yup.string()
                .required('Campo obrigatório')
                .min(8, 'Digite no mínimo 8 caracteres.')
                .oneOf([yup.ref('confirmNewPassword')], 'As senhas estão diferentes.'),
        confirmNewPassword:
            yup.string()
                .required('Campo obrigatório.')
                .min(8, 'Digite no mínimo 8 caracteres.')
                .oneOf([yup.ref('newPassword')], 'As senhas estão diferentes.')
    })

    const modalValidations = yup.object().shape({
        modalPassword:
            yup.string()
                .required('Campo obrigatório.')
                .min(8, 'Digite no mínimo 8 caracteres.'),
        justification:
            yup.string()
                .required('Campo obrigatório.')
                .trim()
    })

    const page = (
        <div>
            <div className="row d-flex justify-content-left px-4">
                <div className="col-12 m-0 p-0 d-flex align-items-center flex-wrap">
                    <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                        <h1 style={{ color: "#002c43" }}>
                            <FontAwesomeIcon
                                className="back-icon mr-3 icon-hover"
                                icon={faArrowLeft}
                                size="1x"
                                onClick={() => history.push("/home")}
                                color="#002c43"
                                style={{ hover: "cursor: pointer" }}
                            />
                           Editar Perfil
                        </h1>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <ParagraphDefault paragraphData={{
                        text: 'Informações de Perfil',
                        _class: 'text-muted font-weight-bold'
                    }} />
                </div>
                <div className="col-12 col-md-7">
                    {/* <CardDefault cardData={{
                        header: 'E-mail',
                        headerClass: 'text-muted font-weight-bold',
                        actions: [
                            { goesTo: 'home', text: 'home' },
                            { goesTo: 'home', text: 'home' },
                        ],
                        body: (
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <Formik
                                            initialValues={{
                                                email
                                            }}
                                            enableReinitialize
                                            validationSchema={validation}
                                            validateOnBlur={false}
                                            validateOnChange={false}
                                            onSubmit={async (values) => {
                                                await putSolicitationUpdateEmail({
                                                  email: values.email
                                                }).then(response=> {
                                                  setIsEmailSended(true)
                                                }).catch(err => {
                                                  if(err.response) {
                                                    err.response.data.errors.forEach(error => {
                                                      const { fieldName, message } = error
                                                      setBackErrors({ [fieldName]: message })
                                                    })
                                                  }
                                                })
                                            }}
                                        >
                                            <Form>
                                                <div>
                                                    <label className="text-muted font-weight-bold">E-mail:</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bi bi-envelope-fill text-info"></i>
                                                        </span>
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Informe o e-mail"
                                                            disabled
                                                        />
                                                    </div>
                                                    <small className="text-success">
                                                        {
                                                            isEmailSended
                                                                ?
                                                                `Favor acessar seu e-mail e realizar a confirmação.`
                                                                :
                                                                null
                                                        }
                                                    </small>
                                                    <ErrorMessage className="text-danger" component="small" name="email" />
                                                    {backErrors.email && <p className="error"> {backErrors.email} </p>}
                                                </div>
                                                <div className="row col m-0 p-0 mt-3">
                                                    <div className="col-12 col-md-3 m-0 p-0">
                                                        <ButtonSubmit
                                                            buttonData={{
                                                                variant: "info",
                                                                text: "Alterar e-mail",
                                                                _class: "text-center col-12",
                                                                iconColor: "#58c5c6",
                                                                textColor: "#FFF",
                                                                size: "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        )
                    }} /> */}
                    <div className="col-12 p-0 mt-2">
                        <CardDefault cardData={{
                            header: 'Senha',
                            headerClass: 'text-muted font-weight-bold',
                            actions: [
                                { goesTo: 'home', text: 'home' },
                                { goesTo: 'home', text: 'home' },
                            ],
                            body: (
                                <div className="row">
                                    <div className="col-12">
                                        <Formik
                                            initialValues={{
                                                currentPassword,
                                                newPassword,
                                                confirmNewPassword
                                            }}
                                            validationSchema={validationPasswords}
                                            validateOnBlur={false}
                                            validateOnChange={false}
                                            validateOnSubmit={true}
                                            onSubmit={async (values) => {
                                                clearBackErrors('actualPassword')
                                                clearBackErrors('newPassword')
                                                setIsPasswordChanged(false);
                                                await putAlterPassword({
                                                  actualPassword: values.currentPassword,
                                                  newPassword: values.newPassword,
                                                }).then(response => {
                                                  setIsPasswordChanged(true);
                                                }).catch(err => {
                                                  if(err.response) {
                                                    err.response.data.errors.forEach(error => {
                                                      const { fieldName, message } = error
                                                      setBackErrors({ [fieldName]: message })
                                                    })
                                                  }
                                                })
                                            }}
                                        >
                                            <Form>
                                                <div className="mt-3">
                                                    <label className="text-muted font-weight-bold">Senha atual:</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bi bi-shield-lock-fill text-info"></i>
                                                        </span>
                                                        <Field
                                                            type="password"
                                                            name="currentPassword"
                                                            className="form-control"
                                                            placeholder="Informe a sua senha atual"
                                                        />
                                                    </div>
                                                    <ErrorMessage className="text-danger" component="small" name="currentPassword" />
                                                    {backErrors.actualPassword && <p className="error"> {backErrors.actualPassword} </p>}
                                                </div>
                                                <div className="mt-3">
                                                    <label className="text-muted font-weight-bold">Nova senha:</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bi bi-shield-lock-fill text-info"></i>
                                                        </span>
                                                        <Field
                                                            type="password"
                                                            name="newPassword"
                                                            className="form-control"
                                                            placeholder="Informe a sua nova senha"
                                                        />
                                                    </div>
                                                    <ErrorMessage className="text-danger" component="small" name="newPassword" />
                                                    {backErrors.newPassword && <p className="error"> {backErrors.newPassword} </p>}
                                                </div>
                                                <div className="mt-3">
                                                    <label className="text-muted font-weight-bold">Confirmar nova senha:</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bi bi-shield-lock-fill text-info"></i>
                                                        </span>
                                                        <Field
                                                            type="password"
                                                            name="confirmNewPassword"
                                                            className="form-control"
                                                            placeholder="Confirme sua nova senha"
                                                        />
                                                    </div>
                                                    <small className="text-success"> {isPasswordChanged ? 'Senha alterada com sucesso.' : null} </small>
                                                    <ErrorMessage className="text-danger" component="small" name="confirmNewPassword" />
                                                </div>
                                                <div className="row col m-0 p-0 mt-3">
                                                    <div className="col-12 col-md-3 m-0 p-0">
                                                        <ButtonSubmit
                                                            buttonData={{
                                                                variant: "info",
                                                                text: "Alterar senha",
                                                                _class: "text-center col-12",
                                                                iconColor: "#58c5c6",
                                                                textColor: "#FFF",
                                                                size: "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            )
                        }} />
                    </div>
                    
                    <div className="col-12 p-0 mt-5">
                        <CardDefault cardData={{
                            header: 'Encerrar Conta',
                            headerClass: 'text-muted font-weight-bold',
                            actions: [
                                { goesTo: '', text: '' },
                                { goesTo: '', text: '' },
                            ],
                            body: (
                                <div className="row">
                                    <div className="col-12">
                                        <div >
                                            <div className="row m-0">
                                                <p className="text-muted m-0">
                                                    <label className="text-danger font-weight-bold m-0">Aviso:</label>{" "}Se encerrar sua conta, você estará desvinculando o seu associativismo com o PORTAL DO MEI. Os seus dados poderão ser recuperados caso crie uma nova conta com o mesmo código de associativismo.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row col m-0 p-0 mt-3">
                                            <div className="col-12 m-0 p-0">
                                                <DialogDefault dialogData={{
                                                    buttonText: 'Encerrar Conta',
                                                    buttonVariant: 'danger',
                                                    dialogTitle: 'Encerrar Conta',
                                                    buttonClass: 'col-12 col-md-3',
                                                    showHeader: true,
                                                    showBody: true,
                                                    dialogBody: (
                                                        <div>
                                                            <Formik
                                                                initialValues={{
                                                                    modalPassword,
                                                                    justification
                                                                }}
                                                                validationSchema={modalValidations}
                                                                validateOnBlur={false}
                                                                validateOnChange={false}
                                                                onSubmit={async (values) => {
                                                                  clearBackErrors('password')
                                                                  await putSolicitationInactiveAccount({
                                                                    password: values.modalPassword,
                                                                    justify: values.justification
                                                                  }).then(response => {
                                                                    setIsSendEmailToInactive(true);
                                                                    localStorage.removeItem('jwt_auth');
                                                                  }).catch(err => {
                                                                    if(err.response) {
                                                                      err.response.data.errors.forEach(error => {
                                                                        const { fieldName, message } = error
                                                                        setBackErrors({ [fieldName]: message })
                                                                      })
                                                                    }
                                                                  })
                                                                }}

                                                            >
                                                                <Form>
                                                                    <div className="mt-3">
                                                                        <label className="text-muted font-weight-bold">Senha:</label>
                                                                        <div className="input-group">
                                                                            <span className="input-group-text"><i className="bi bi-shield-lock-fill text-info"></i>
                                                                            </span>
                                                                            <Field
                                                                                type="password"
                                                                                name="modalPassword"
                                                                                className="form-control"
                                                                                placeholder="Insira sua senha"
                                                                            />
                                                                        </div>
                                                                        <ErrorMessage className="text-danger" component="small" name="modalPassword" />
                                                                        {backErrors.password && <p className="error"> {backErrors.password}</p>}
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <label className="text-muted font-weight-bold">Justificativa:</label>
                                                                        <Field
                                                                            as="textarea"
                                                                            name="justification"
                                                                            className="form-control"
                                                                            placeholder="Informe a justificativa"
                                                                        />
                                                                        <ErrorMessage className="text-danger" component="small" name="justification" />
                                                                        <small className="text-success"> {isSendEmailToInactive ? 'Email para confirmação de inativação enviado.' : null} </small>
                                                                        {backErrors.justify && <p className="error"> {backErrors.justify}</p>}
                                                                    </div>
                                                                    <div className="mt-3 d-flex justify-content-end">
                                                                        <ButtonSubmit
                                                                            buttonData={{
                                                                                variant: "info",
                                                                                text: "Encerrar Conta",
                                                                                _class: "text-center col-12",
                                                                                iconColor: "#58c5c6",
                                                                                textColor: "#FFF",
                                                                                size: "",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            </Formik>
                                                        </div>
                                                    ),
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
    return (
        <PageDefault
            pageData={{
                pageContent: page,
            }}
        />
    );
}

export default EditProfile;