import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";

const ButtonSidebar = (props) => {
  const {
    variant,
    text,
    action,
    icon,
    iconHover,
    marcacao,
    textColor,
    _class,
    goesTo,
  } = props.buttonData;

  const [hoverButton, sethoverButton] = useState(false);

  return (
    <div className="d-flex">
      <div className="d-flex col-3 pl-1 border-right bg-light align-items-center justify-content-center">
        <div className="d-flex col-1 pl-1 align-items-center justify-content-center">
          {hoverButton ? <img src={marcacao} alt="Marcação" /> : undefined}
        </div>
        <div className="d-flex col-11 pl-1 align-items-center justify-content-center">
          <img
            src={hoverButton && iconHover !== undefined ? iconHover : icon}
            alt="Icon"
          />
        </div>
      </div>
      <Button
        className={_class}
        variant={variant}
        onMouseOver={() => {
          sethoverButton(true);
        }}
        onMouseOut={() => {
          sethoverButton(false);
        }}
        onClick={() => {
          action();
        }}
      >
        <div className="infoSidebar">
          <h3 className="sideBarText">{text}</h3>
        </div>
      </Button>
    </div>
  );
};

export default ButtonSidebar;
