import '../App.css';
import PageDefault from "../../../components/pageDefault"
import { useEffect, useRef, useState } from 'react'
import PhoneMask from '../../../components/inputs/phone';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CreditCardMask from '../../../components/inputs/creditCard'
import Currency from '../../../components/inputs/currency'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonSubmit from '../../../components/buttonSubmit';
import moment from 'moment'
import ptbr from "date-fns/locale/pt-BR";
import * as yup from 'yup';
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ButtonActionDefault from '../../../components/buttonAction';
import { addDays } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import {
    faArrowLeft,
    faBuilding,
    faCalendarAlt,
    faClock,
    faCreditCard,
    faLock,
    faPhone,
    faShieldAlt,
    faUserAlt
} from '@fortawesome/free-solid-svg-icons';
import {
    getAvailableTimes,
    getExcludedDates,
    getRegion,
    zones,
    createSchedule,
    getConsultation,
    updateSchedule,
    getScheduleById
} from '../../../services/consultancy'
import { ConsultancyStatus, PaymentStatus } from '../../../enumerations/Consultancy';


function ConsultancyCreate() {
    const { id } = useParams();
    const history = useHistory()
    const [apiConsultancyType, setApiConsultancyType] = useState([])

    const [consultancy, setConsultancy] = useState('')
    const [consultancyValue, setConsultancyValue] = useState('')
    const [isUpdatingConsultancy, setIsUpdatingConsultancy] = useState(false)
    const [transaction, setTransaction] = useState({});

    /**
     * Estados dos inputs
     */
    const [region, setRegion] = useState('')
    const [district, setDistrict] = useState('')
    const [modality, setModality] = useState('')
    const [consultancyType, setConsultancyType] = useState('')
    const [dateConsultancy, setDateConsultancy] = useState('')
    const [hour, setHour] = useState('')
    const [phone, setPhone] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [dateForm, setDateForm] = useState('')
    const [terms, setTerms] = useState(false)

    const [consultationValueForInit, setConsulationValueForInit] = useState('')

    // Cartão de crédito
    const [cardNumber, setCardNumber] = useState('')
    const [cardOwner, setCardOwner] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [cardCvv, setCardCvv] = useState('')
    const [cardValueToPay, setCardValueToPay] = useState('')

    registerLocale("pt-BR", ptbr);

    // Dias de compensação do boleto e dias para vencimento
    const [compensationDays, setCompensationsDays] = useState(2)
    const [dueDays, setDueDays] = useState(2)


    const [allDistricts, setAllDistricts] = useState([])
    const [currentRegion, setCurrentRegion] = useState('');
    const [currentDistrict, setCurrentDistrict] = useState("");
    const [districtId, setDistrictId] = useState('');

    const [apiModality, setApiModality] = useState([]);
    const [daysNotAvaiable, setDaysNotAvaiable] = useState([]);
    const [apiDateConsultancy, setApiDateConsultancy] = useState('');

    const [backErrors, setBackErrors] = useState({
        attendanceModality: "",
        consultancyDate: "",
        time: "",
        district: "",
        consultation: "",
        paymentType: "",
    });

    const [apiDistricts, setApiDistricts] = useState([])
    const [openingHoursApi, setOpeningHoursApi] = useState([])

    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')


    // Meses para ser adicinados na busca dos dias não disponíveis
    const numMonths = [0, 0, 0, 0, 0, 0];


    const formRef = useRef()

    //Busca as distritais
    useEffect(() => {
        getRegion().then(res => {
            setApiDistricts(res.data.filter(e => e.zone === currentRegion))
            setAllDistricts(res.data)
            setDistrictId(res.data[0].id)
            res.data.forEach(e => {
                setApiModality(p => e.attendanceModalities)
            })
        }).catch(err => {
            console.log(err)
        })

        getConsultation().then(({ data }) => {
            setApiConsultancyType(data)
        }).catch(err => {
            console.log(err)
        })
    }, [currentRegion])

    useEffect(() => {
        if (id) {
            moment.locale('pt-br');
            getScheduleById(id).then(({ data }) => {
                const scheduleStatus = data.scheduleDetail[0].status.toUpperCase();
                if (
                    scheduleStatus === ConsultancyStatus.CANCELEDUSER ||
                    scheduleStatus === ConsultancyStatus.CANCELEDACSP
                ) {
                    history.push('/consultancy');
                }
                setRegion(data.scheduleDetail[0].district.zone)
                setModality(`${data.scheduleDetail[0].attendanceModalityId}`)
                setCurrentRegion(data.scheduleDetail[0].district.zone)
                setPhone(data.whatsapp)
                setPaymentMethod(data.transaction[0].paymentType)
                setDistrict(data.scheduleDetail[0].districtId)
                setConsultancyType(`${data.consultationValue.id}`)
                setDateConsultancy(moment(data.scheduleDetail[0].date).format('DD/MM/YYYY'))
                setApiDateConsultancy(moment(data.scheduleDetail[0].date).format('DD/MM/YYYY'))
                setHour(moment(data.scheduleDetail[0].date).format('LT'))
                setDateForm(data.scheduleDetail[0].date)
                setTerms(data.acceptTerms)
                setConsulationValueForInit(data.consultationValue.value)
                setTransaction(data.transaction[0])
            })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [])

    useEffect(() => {
        const date = {
            districtId,
            month: new Date(moment().locale("pt-br")).getMonth(),
            year: new Date(moment().locale("pt-br")).getFullYear()
        }
        if (districtId) {
            daysNotAvaiable.splice(0, daysNotAvaiable.length)
            numMonths.forEach((e, index) => {
                getExcludedDates(date).then(res => {
                    res.data.unavailableDates.forEach(e => {
                        const dateSplit = e.split("/")
                        const formatedDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
                        daysNotAvaiable.push(new Date(moment(formatedDate).locale("pt-br").format("YYYY/MM/DD")));
                    })
                    date.month += 1;
                }).catch(err => {
                    console.log(err);
                })
                date.month += 1;
            })
        }
    }, [districtId])

    useEffect(() => {
        const auxDate = dateConsultancy?.split('/')
        const data = {
            districtId,
            date: `${auxDate[2]}-${auxDate[1]}-${auxDate[0]}`
        }

        getAvailableTimes(data).then(({ data }) => {
            moment.locale("pt-br");
            const dates = data.availableTimes.map(e => {
                return moment(e).format("LT")
            });

            if (dateConsultancy === apiDateConsultancy) {
                dates.push(hour)
            }
            setOpeningHoursApi(dates.sort())
        }).catch(err => {
            console.log(err)
        })
    }, [dateForm])

    useEffect(() => {
        setApiDistricts(allDistricts.filter(e => e.zone === currentRegion));
    }, [allDistricts, currentRegion, district, region])

    const generateAddress = ({ complement, neighborhood, numberHouse, referencePoint, street, zipCode }) => {
        const address = `${street} ${numberHouse && `nº ${numberHouse}`}, ${neighborhood}. ${complement ? complement : ''} - ${zipCode}`
        return address;
    }

    const findConsultancy = (id) => {
        return apiConsultancyType.find(consultancyType => consultancyType.id === id);
    }

    const formSchema = yup.object().shape({
        region: yup.string()
            .required("Campo obrigatório!"),
        district: yup.string()
            .required("Campo obrigatório!"),
        modality: yup.string()
            .required("Campo obrigatório!"),
        dateConsultancy: yup.string()
            .test('val', 'Data do agendamento inválida', val => moment(dateForm).isValid)
            .required("Campo obrigatório!"),
        hour: yup.string()
            .required("Campo obrigatório!"),
        phone: yup.string().test(
            'phone',
            'Telefone inválido!',
            (phoneMask) => {
                if (!phoneMask) return false;
                const phoneLenght = phoneMask.replace(/[^0-9]+/g, '').length;
                return phoneLenght === 11 || phoneLenght === 10;
            }
        ).required("Campo obrigatório!").nullable(),
        consultancyType: yup.string()
            .required("Campo obrigatório!"),
        paymentMethod: yup.string()
            .when('consultancyType', {
                is: consultancyId => {
                    const selectedConsultancy = findConsultancy(consultancyId);

                    return selectedConsultancy.value !== 0
                },
                then: yup.string().required("Campo obrigatório!").nullable(),
                otherwise: null,
            })
            .nullable(),
        terms: yup.boolean().isTrue('Para prosseguir com o agendamento é necessário aceitar os termos de consentimento de dados'),

        cardNumber: yup.string()
            .when("paymentMethod", {
                is: value => value === "creditCard",
                then: yup.string().required("Campo obrigatório!"),
                otherwise: null
            }),
        cardOwner: yup.string()
            .when("paymentMethod", {
                is: value => value === "creditCard",
                then: yup.string().required("Campo obrigatório!"),
                otherwise: null
            }),
        month: yup.string()
            .when("paymentMethod", {
                is: value => value === "creditCard",
                then: yup.string().required("Campo obrigatório!"),
                otherwise: null
            }),
        year: yup.string()
            .when("paymentMethod", {
                is: value => value === "creditCard",
                then: yup.string().required("Campo obrigatório!"),
                otherwise: null
            }),
        cardCvv: yup.string()
            .when("paymentMethod", {
                is: value => value === "creditCard",
                then: yup.string().required("Campo obrigatório!")
                    .min(3, "Código de Segurança(CVV) deverá possuir 3 ou 4 números.")
                    .max(4, "Código de Segurança(CVV) deverá possuir 3 ou 4 números."),
                otherwise: null
            }),
    });

    const generateBackErrors = (data) => {
        data?.forEach(currentError => {
            switch (currentError.fieldName) {
                case "scheduleDetails.attendanceModalityId":
                    setBackErrors(prevState => ({
                        ...prevState,
                        attendanceModality: currentError.message
                    }));
                    break;
                case "scheduleDetails.date":
                    setBackErrors(prevState => ({
                        ...prevState,
                        consultancyDate: currentError.message
                    }));
                    break;
                case "scheduleDetails.time":
                    setBackErrors(prevState => ({
                        ...prevState,
                        time: currentError.message
                    }));
                    break;
                case "scheduleDetails.districtId":
                    setBackErrors(prevState => ({
                        ...prevState,
                        district: currentError.message
                    }));
                    break;
                case "scheduleDetails.consultationId":
                    setBackErrors(prevState => ({
                        ...prevState,
                        consultation: currentError.message
                    }));
                    break;
                case "paymentMethod.paymentType":
                    setBackErrors(prevState => ({
                        ...prevState,
                        paymentType: currentError.message
                    }));
                    break;
                default:
            }
        })

        return backErrors;
    }

    const generateMonths = () => {
        const num = Array(12).fill(0);

        return num.map((e, index) => {
            return (
                <option value={index + 1}>{index + 1}</option>
            )
        })
    }

    const generateYears = () => {
        const currentDate = new Date().getFullYear()
        const num = Array(10).fill(currentDate);
        const year = num.map((e, index) => {
            return e + index
        })

        return year.map((e) => {
            return (
                <option value={e}>{e}</option>
            )
        })
    }

    if (formRef.current !== undefined) {
        formRef.current.values.region = region ?? '';
        formRef.current.values.modality = modality ?? '';
        formRef.current.values.phone = phone ?? '';
        formRef.current.values.paymentMethod = paymentMethod ?? '';
        formRef.current.values.district = district ?? '';
        formRef.current.values.consultancyType = consultancyType ?? '';
        formRef.current.values.dateConsultancy = dateConsultancy ?? '';
        formRef.current.values.hour = hour ?? '';
        formRef.current.values.terms = terms ?? '';
    }

    const page = (
        <div>
            <NotificationContainer />
            <div className="row">
                <div className="col-12 m-0 p-0 d-flex flex-wrap">
                    <div className="ml-3 d-flex align-items-center flex-wrap mt-3">
                        <h1 style={{ color: "#002c43" }}>
                            <FontAwesomeIcon
                                className="back-icon mr-3 icon-hover"
                                icon={faArrowLeft}
                                size="1x"
                                onClick={() => history.push("/consultancy")}
                                color="#002c43"
                                style={{ hover: "cursor: pointer" }}
                            />
                            Agendamento de Consultoria
                        </h1>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-10 col-lg-6 mt-3">
                <Formik
                    validationSchema={formSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={async (values) => {
                        const selectedConsultancy = findConsultancy(values.consultancyType);
                        if (selectedConsultancy.value === 0) {
                            values.paymentMethod = 'local';
                        }

                        const data = {
                            schedule: {
                                acceptTerms: true,
                                consultationId: values.consultancyType,
                                whatsapp: values.phone
                            },
                            scheduleDetails: {
                                attendanceModalityId: values.modality,
                                date: dateForm,
                                time: values.hour,
                                districtId: values.district
                            },
                            paymentMethod: {
                                paymentType: values.paymentMethod,
                                creditCard: values.paymentMethod === "creditCard" ? {
                                    number: values.cardNumber.replace(/[ ]/g, '') || null,
                                    holder: values.cardOwner || null,
                                    expirationDate: `${values.year}-${values.month}` || null,
                                    cvv: values.cardCvv || null
                                } : null
                            }
                        }
                        if (!id) {
                            createSchedule(data).then(res => {
                                setBackErrors({})
                                NotificationManager.listNotify.splice(0, NotificationManager.listNotify.length)
                                NotificationManager.success('Consultoria agendada com sucesso');
                                history.push("/consultancy");
                            }).catch(err => {
                                const errors = err?.response?.data?.errors;
                                generateBackErrors(errors);
                                NotificationManager.listNotify.splice(0, NotificationManager.listNotify.length)
                                NotificationManager.error(
                                    errors[0].message && typeof errors[0].message === 'string' ?
                                        errors[0].message :
                                        'Erro ao agendar consultoria'
                                );
                            })
                        } else {
                            data.id = id;
                            updateSchedule(data).then(res => {
                                setBackErrors({})
                                NotificationManager.listNotify.splice(0, NotificationManager.listNotify.length)
                                NotificationManager.success('Consultoria alterada com sucesso');
                                history.push("/consultancy");
                            }).catch(err => {
                                const errors = err?.response?.data?.errors;
                                generateBackErrors(errors);
                                NotificationManager.listNotify.splice(0, NotificationManager.listNotify.length)
                                NotificationManager.error(errors[0].message ? errors[0].message : "Não foi possível alterar o seu agendamento");
                            })
                        }
                    }}
                    innerRef={id ? formRef : null}
                    initialValues={{
                        region,
                        district,
                        consultancy,
                        dateConsultancy,
                        hour,
                        phone,
                        modality,
                        consultancyType,
                        paymentMethod,
                        cardNumber,
                        cardOwner,
                        dueDate,
                        cardCvv,
                        cardValueToPay,
                        terms
                    }}
                >
                    {({
                        values,
                        errors,
                        setFieldValue,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div className="m-0 mt-3 shadow-lg border rounded bg-white p-4">
                                <div className="col-12 p-0">
                                    <p className="text-muted h4 font-weight-bold mb-2">Informações de agendamento</p>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12 col-md-10">
                                        <p className="text-muted font-weight-bold mb-2">Escolha uma região</p>

                                        <div className="input-group">
                                            <span class="input-group-text bg-white">
                                                <FontAwesomeIcon icon={faBuilding} size="1x" color="#64c2c8" />
                                            </span>
                                            <Field
                                                name="region"
                                                className="custom-select custom-select-sm"
                                                as="select"
                                                value={region}
                                                onChange={(e) => {
                                                    setCurrentRegion(e.target.value)
                                                    setFieldValue("region", e.target.value)
                                                    setRegion(e.target.value)
                                                }}
                                            >
                                                <option value="" selected>Selecione uma região</option>
                                                {
                                                    zones !== undefined && zones?.map(currentRegion => {
                                                        return (
                                                            <option value={currentRegion.code}> {currentRegion.name} </option>
                                                        )
                                                    })
                                                }
                                            </Field>
                                        </div>
                                        <ErrorMessage className="text-danger" component="small" name="region" />
                                    </div>

                                    <div className="col-12 col-md-10 mt-3">
                                        <p className="text-muted font-weight-bold mb-2">Escolha uma distrital</p>

                                        <div className="input-group">
                                            <span class="input-group-text bg-white">
                                                <FontAwesomeIcon icon={faBuilding} size="1x" color="#64c2c8" />
                                            </span>
                                            <Field
                                                name="district"
                                                className="custom-select custom-select-sm"
                                                as="select"
                                                value={values.district}
                                                onChange={(e) => {
                                                    setCurrentDistrict(e.target.value)
                                                    setFieldValue("district", e.target.value)
                                                }}
                                            >
                                                <option value="" selected>Selecione uma distrital</option>
                                                {
                                                    apiDistricts.map(currentDistrict => {
                                                        return (
                                                            <>
                                                                <option value={currentDistrict.id}> {currentDistrict.name} </option>
                                                                <option disabled value="" className="option-address ml-5">
                                                                    {generateAddress(currentDistrict.address)}
                                                                </option>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Field>
                                        </div>
                                        <ErrorMessage className="text-danger" component="small" name="district" />
                                        <small className="text-danger">{backErrors.district}</small>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <p className="text-muted font-weight-bold mb-2">Modalidade</p>
                                        {
                                            apiModality !== undefined && apiModality.map(currentModality => {
                                                return (
                                                    <div>
                                                        <Field
                                                            name="modality"
                                                            type="radio"
                                                            id={`modality_${currentModality.id}`}
                                                            value={currentModality.id}

                                                            onClick={e => {
                                                                setFieldValue('modality', e.target.value)
                                                                setModality(e.target.value)
                                                            }}
                                                        />
                                                        <label for={`modality_${currentModality.id}`} className="ml-2 text-muted font-radio">{currentModality.name}</label>
                                                    </div>
                                                )
                                            })
                                        }

                                        <ErrorMessage className="text-danger" component="small" name="modality" />
                                        <small className="text-danger">{backErrors.attendanceModality}</small>
                                    </div>
                                    <div className="col-12">
                                        <p className="text-muted font-weight-bold mb-2 m-0">Escolha uma data e horário disponíveis</p>

                                        <div>
                                            <div className="d-flex col-12 col-md-10 p-0 input-group">
                                                <span class="input-group-text bg-white">
                                                    <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#64c2c8" />
                                                </span>

                                                <Field
                                                    render={() => (
                                                        <DatePicker
                                                            type="date"
                                                            className="col form-control form-control-sm"
                                                            dateFormat="dd/MM/yyyy"
                                                            locale="pt-BR"
                                                            placeholderText="Selecione uma data"
                                                            value={dateConsultancy}
                                                            excludeDates={daysNotAvaiable.sort()}
                                                            disabled={!values.district}
                                                            minDate={new Date()}

                                                            onChange={(date) => {
                                                                setFieldValue('dateConsultancy', date)
                                                                setDateForm(date)
                                                                setDateConsultancy(moment(date).format('DD/MM/YYYY'))
                                                            }}
                                                        />
                                                    )}
                                                />


                                            </div>
                                            <ErrorMessage className="text-danger" component="small" name="dateConsultancy" />
                                            <small className="text-danger">{backErrors.consultancyDate}</small>
                                        </div>

                                        <div className="col-12 col-md-10 p-0">
                                            <div className="input-group mt-2">
                                                <span class="input-group-text bg-white">
                                                    <FontAwesomeIcon icon={faClock} size="1x" color="#64c2c8" />
                                                </span>
                                                <select
                                                    name="hour"
                                                    className="custom-select custom-select-sm"
                                                    as="select"
                                                    value={hour}

                                                    onChange={(e) => {
                                                        setFieldValue('hour', e.target.value)
                                                        setHour(e.target.value)
                                                    }}
                                                >
                                                    <option value="">Escolha um horário</option>
                                                    {
                                                        openingHoursApi !== undefined && openingHoursApi.map(currentHour => {
                                                            return (
                                                                <option value={currentHour}> {currentHour} </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <ErrorMessage className="text-danger" component="small" name="hour" />
                                            <small className="text-danger">{backErrors.time}</small>
                                        </div>

                                        <div className="col-12 col-md-10 p-0 mt-3">
                                            <p className="text-muted font-weight-bold mb-2">Informe o telefone</p>

                                            <div className="input-group">
                                                <span class="input-group-text bg-white">
                                                    <FontAwesomeIcon icon={faPhone} size="1x" color="#64c2c8" />
                                                </span>
                                                <PhoneMask
                                                    placeholder="Informe seu telefone"
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="phone"
                                                    defaultValue={phone}
                                                    onChange={(e) => {
                                                        setFieldValue('phone', e.target.value)
                                                        setPhone(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <ErrorMessage className="text-danger" component="small" name="phone" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="mt-3 shadow-lg border rounded bg-white p-4 mt-4">
                                <div>
                                    <p className="text-muted font-weight-bold h4 mb-2">Tipo da consultoria</p>
                                </div>

                                <div className="col-12 p-0 mt-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="col ml-4 p-0 pl-2">
                                            <p>
                                                Descrição
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                Valor
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        id ? <>
                                            <div className="row mb-2 p-0">
                                                <div className="row col-12 d-flex align-items-center m-0">
                                                    <Field
                                                        type="radio"
                                                        checked={true}
                                                        name="consultancyType"
                                                        id="selectedConsultancyType"
                                                    />

                                                    <label for="selectedConsultancyType" className="text-muted ml-3 mb-0 font-radio">{findConsultancy(consultancyType)?.name}</label>

                                                    <div className="col p-0">
                                                        <div className="d-flex justify-content-end flex-grow-1">
                                                            <p className="ml-2 mb-0">
                                                                R${" "}
                                                                <Currency
                                                                    value={parseFloat(findConsultancy(consultancyType)?.value)}
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 pb-3 border-bottom"></div>
                                                </div>
                                            </div>
                                        </> :
                                            apiConsultancyType.map(currentConsultancyType => {
                                                return (
                                                    <div className="row mb-2 p-0">
                                                        <div className="row col-12 d-flex align-items-center m-0">
                                                            <Field
                                                                id={'consultancyType_' + currentConsultancyType.id}
                                                                type="radio"
                                                                name="consultancyType"
                                                                value={currentConsultancyType.id}
                                                                max={currentConsultancyType.value}

                                                                onClick={(e) => {
                                                                    setConsultancyValue(e.target.max)
                                                                    setConsultancyType(e.target.value)
                                                                    setFieldValue('consultancyType', e.target.value)
                                                                }}
                                                            />

                                                            <label for={'consultancyType_' + currentConsultancyType.id} className="text-muted ml-3 mb-0 font-radio">{currentConsultancyType.name}</label>

                                                            <div className="col p-0">
                                                                <div className="d-flex justify-content-end flex-grow-1">
                                                                    <p className="ml-2 mb-0">
                                                                        R${" "}
                                                                        <Currency
                                                                            value={parseFloat(currentConsultancyType.value)}
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 pb-3 border-bottom"></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                    <ErrorMessage className="text-danger" component="small" name="consultancyType" />
                                    <small className="text-danger">{backErrors.consultation}</small>
                                </div>
                            </div>

                            <div className="mt-3 shadow-lg border rounded bg-white p-4 mt-4">
                                {
                                    ((transaction?.status?.toUpperCase() !== PaymentStatus.PAID) && consultancyValue !== '0') ?
                                        <>
                                            <div>
                                                <p className="text-muted h4 font-weight-bold mb-2">Escolha sua forma de pagamento</p>
                                            </div>

                                            <div className="mt-4">
                                                <div className="col-12 border-payment py-2">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Field
                                                                id="cc"
                                                                type="radio"
                                                                name="paymentMethod"
                                                                value="creditCard"
                                                                disabled={isUpdatingConsultancy}

                                                                onClick={e => {
                                                                    setFieldValue('paymentMethod', e.target.value);
                                                                    setPaymentMethod(e.target.value);
                                                                }}
                                                            />
                                                            <label className="text-muted m-0 ml-2" for="cc">Cartão de crédito</label>
                                                        </div>

                                                        {
                                                            values.paymentMethod === "creditCard" ?
                                                                (
                                                                    <div className="row col-12 m-0 p-0">
                                                                        <div className="col-12 mt-3 d-flex align-items-center flex-nowrap">
                                                                            <FontAwesomeIcon icon={faLock} size="2x" color="#64c2c8" />
                                                                            <div className="ml-2">
                                                                                <p className="text-muted m-0">Pagamento seguro com o cartão</p>
                                                                                <p className="text-muted m-0" style={{ fontSize: "0.8rem" }}>Este é um site seguro e seus dados estão protegidos com criptografia.</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-10 col-12 mt-3">
                                                                            <p className="text-muted m-0">Número do cartão</p>
                                                                            <div className="input-group">
                                                                                <span class="input-group-text bg-white">
                                                                                    <FontAwesomeIcon icon={faCreditCard} size="1x" color="#64c2c8" />
                                                                                </span>
                                                                                <Field
                                                                                    name="cardNumber"
                                                                                    render={({ field }) => (
                                                                                        <CreditCardMask
                                                                                            {...field}
                                                                                            inputmode="numeric"
                                                                                            placeholder="Número do cartão"
                                                                                            className="form-control form-control-sm"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <ErrorMessage className="text-danger" component="small" name="cardNumber" />
                                                                        </div>
                                                                        <div className="col-md-10 col-12 mt-3">
                                                                            <p className="text-muted m-0">Nome impresso no cartão</p>
                                                                            <div className="input-group">
                                                                                <span class="input-group-text bg-white">
                                                                                    <FontAwesomeIcon icon={faUserAlt} size="1x" color="#64c2c8" />
                                                                                </span>
                                                                                <Field
                                                                                    name="cardOwner"
                                                                                    placeholder="Titular do cartão"
                                                                                    className="form-control form-control-sm"
                                                                                />
                                                                            </div>
                                                                            <ErrorMessage className="text-danger" component="small" name="cardOwner" />
                                                                        </div>
                                                                        <div className="col-12 col-md-6 mt-3">
                                                                            <p className="text-muted m-0">Vencimento</p>

                                                                            <div className="input-group">
                                                                                <div className="col p-0">
                                                                                    <select
                                                                                        as="select"
                                                                                        className="custom-select custom-select-sm"
                                                                                        name="month"

                                                                                        onChange={e => {
                                                                                            setFieldValue('month', e.target.value)
                                                                                            setMonth(e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        <option value="">Mês</option>
                                                                                        {
                                                                                            generateMonths()
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.month && (
                                                                                            <small className="text-danger">{errors.month}</small>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className="col p-0">
                                                                                    <select
                                                                                        as="select"
                                                                                        className="custom-select custom-select-sm ml-1"
                                                                                        name="year"

                                                                                        onChange={e => {
                                                                                            setFieldValue('year', e.target.value)
                                                                                            setYear(e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        <option value="" name="year">Ano</option>
                                                                                        {
                                                                                            generateYears()
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.year && (
                                                                                            <small className="text-danger">{errors.year}</small>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-4 mt-3">
                                                                            <p className="text-muted m-0">Cvv</p>
                                                                            <div className="input-group">
                                                                                <span class="input-group-text bg-white">
                                                                                    <FontAwesomeIcon icon={faShieldAlt} size="1x" color="#64c2c8" />
                                                                                </span>
                                                                                <Field
                                                                                    name="cardCvv"
                                                                                    maxlength="4"
                                                                                    className="form-control form-control-sm"
                                                                                    placeholder="Ex: 123"
                                                                                />
                                                                            </div>
                                                                            <ErrorMessage className="text-danger" component="small" name="cardCvv" />
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="mt-3">
                                    <div className="border-payment py-2 col-12">
                                        <Field
                                            id="bs"
                                            type="radio"
                                            name="paymentMethod"
                                            value="billet"
                                            disabled={checkDateIsAvaiable() || isUpdatingConsultancy}

                                            onClick={e => {
                                                setFieldValue('paymentMethod', e.target.value);
                                                setPaymentMethod(e.target.value);
                                            }}
                                        />
                                        <label className="text-muted m-0 ml-2" for="bs">Boleto bancário</label>

                                        {
                                            values.paymentMethod === "billet" &&
                                            (
                                                <div>
                                                    <p className="text-muted font-weight-light m-0 mt-3">
                                                        Ao finalizar a compra, você será redirecionado para uma página com o boleto gerado no valor de
                                                        {parseFloat(consultancyValue || consultationValueForInit).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        checkDateIsAvaiable() &&
                                        (
                                            <small className="text-muted">
                                                Para escolher a opção de boleto é preciso selecionar uma data que seja superior a compensação do boleto (dias de vencimento do boleto + dias de compensação do boleto)
                                            </small>
                                        )
                                    }
                                    {
                                        values.paymentMethod === "billet" && checkDateIsAvaiable() &&
                                        (
                                            values.paymentMethod = null
                                        )
                                    }
                                </div> */}

                                            <div className="mt-3">
                                                <div className="border-payment py-2 col-12">
                                                    <Field
                                                        id="ps"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        value="local"
                                                        disabled={values.modality != 1 ? true : false || isUpdatingConsultancy}

                                                        onClick={e => {
                                                            setFieldValue('paymentMethod', e.target.value);
                                                            setPaymentMethod(e.target.value);
                                                        }}
                                                    />
                                                    <label className="text-muted m-0 ml-2" for="ps">Pagamento presencial</label>
                                                    {
                                                        values.paymentMethod === "local" &&
                                                        (
                                                            <div>
                                                                <p className="text-muted font-weight-light m-0 mt-3">
                                                                    Caso opte por esta opção, o pagamento da taxa de inscrição deverá de ser realizado junto ao atendente presente no local, data e horário agendado.
                                                                </p>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        values.modality != 1 && values.paymentMethod === "local" &&
                                                        (
                                                            values.paymentMethod = null
                                                        )
                                                    }
                                                </div>


                                                {
                                                    values.modality != 1 &&
                                                    (
                                                        <small className="text-muted">
                                                            Pagamento no local está disponível apenas na modalidade presencial.
                                                        </small>
                                                    )
                                                }
                                            </div>
                                        </> : ''
                                }
                                <div className="col-12 p-0">
                                    <ErrorMessage className="text-danger" component="small" name="paymentMethod" />
                                    {
                                        backErrors.paymentType !== "" && (

                                            <small className="text-danger">{backErrors.paymentType}</small>
                                        )
                                    }
                                </div>

                                <div className="d-flex align-items-center mt-3" id="terms">
                                    <Field
                                        type="checkbox"
                                        name="terms"
                                        id="terms"

                                        checked={terms}
                                        onClick={(e) => {
                                            setTerms(!terms)
                                            setFieldValue('terms', e.target.checked)
                                        }}

                                    />
                                    <label className="text-muted m-0 ml-3" htmlFor="terms">Concordo com os <a href="/consultancy/terms" target="__blank">termos de consentimento de dados</a> do "Portal MEI"</label>
                                </div>
                                <ErrorMessage className="text-danger" component="small" name="terms" />
                            </div>

                            <div className="d-flex mt-5">
                                <div>
                                    <ButtonSubmit
                                        buttonData={{
                                            variant: "info",
                                            text: "Confirmar Informações",
                                            disabled: isSubmitting
                                        }}
                                    />
                                </div>

                                <div className="ml-2">
                                    <ButtonActionDefault
                                        buttonData={{
                                            text: "Voltar",
                                            variant: "outline-info",
                                            action: () => history.goBack()
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )

    return (
        <PageDefault
            pageData={{
                pageContent: page,
            }}
        />
    );
}

export default ConsultancyCreate;