// import React, { useState } from "react";

// import { Modal, Button } from "react-bootstrap";
// import { Link, useHistory } from "react-router-dom";
// import { getDasn } from "../../../services/dasn/index";
// import Currency from "../../inputs/currency";
// import "./style.css";

// export default function DASNCard(props) {
//   const { data } = props;

//   const [modalDasn, setModalDasn] = useState({});
//   const [show, setShow] = useState(false);
//   const [year, setYear] = useState("");
//   const [show2, setShow2] = useState(false);

//   const history = useHistory();
//   const handleClose = () => setShow(false);
//   const handleShow = (year) => {
//     setShow(true);
//     setYear(year);
//   };

//   const handleClose2 = () => setShow2(false);
//   const handleShow2 = (id) => {
//     setShow2(true);
//     getDasn(id).then((response) => {
//       setModalDasn(response.data);
//     });
//   };

//   const renderDAS = (dasn) => {
//     return (
//       <button
//         key={dasn.id}
//         className="cardInfo"
//         onClick={() => {
//           dasn.status === "SENT" ? handleShow2(dasn.id) : handleShow(dasn.year);
//         }}
//       >
//         <div className="flex">
//           <img src={props.icon} alt="DASN" />
//           <p> {dasn.year} </p>
//         </div>
//         <div
//           className={`status ${dasn.status === "SENT" ? "green" : "yellow"}`}
//         >
//           {dasn.status === "SENT" ? "Entregue !" : "Em aberto !"}
//           <i className="bi bi-chevron-right" />
//         </div>
//       </button>
//     );
//   };

//   return (
//     <div>
//       <div className="card heig">
//         <div className="DASTitle">
//           <h5>DASN</h5>
//         </div>
//         {data?.map((dasn) => renderDAS(dasn))}
//         <Link to="/financial/annual_declaration" className="linkCard">
//           <p>Ver todas</p>
//         </Link>
//       </div>

//       MODAL

//       <Modal centered show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title> Atenção </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             Preencha com cuidado, pois os dados a seguir serão enviado para a
//             Receita federal
//           </p>
//           <p>Esta declaração se refere ao ano de {year}!</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="info"
//             onClick={() => {
//               history.push(
//                 `/financial/annual_declaration/create/${year}/false`
//               );
//             }}
//           >
//             Ok! entendi
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <Modal
//         centered
//         show={show2}
//         onHide={handleClose2}
//         className="modalContentDAS"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title> Informação imposto DASN </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modalBody">
//             <label>
//               <p>Declaração referente a:</p>
//               <strong>{modalDasn.year}</strong>
//             </label>

//             <label>
//               <p>Tem algum funcionário registrado:</p>
//               <strong>{modalDasn.hasEmployee ? "Sim" : "Não"}</strong>
//             </label>

//             <label>
//               <p>Total faturado em comércio:</p>
//               <strong>
//                 R$ {Currency({ value: parseFloat(modalDasn.totalTrade) })}
//               </strong>
//             </label>

//             <label>
//               <p>Total faturado na prestação de serviços:</p>
//               <strong>
//                 R$ {Currency({ value: parseFloat(modalDasn.totalService) })}
//               </strong>
//             </label>

//             <label>
//               <p>Faturamento total:</p>
//               <strong>
//                 R${" "}
//                 {Currency({
//                   value: parseFloat(
//                     modalDasn.totalService + modalDasn.totalTrade
//                   ),
//                 })}
//               </strong>
//             </label>

//             <label>
//               <p>Status de declaração:</p>
//               <strong
//                 className={`status ${
//                   modalDasn.status === "SENT" ? "green" : "yellow"
//                 }`}
//               >
//                 {modalDasn.status === "SENT" ? "Entregue !" : "Em aberto"}
//               </strong>
//             </label>
//           </div>

//           <div className="modalFooter">
//             <Button
//               variant="outline-info"
//               className="showPdf"
//               onClick={handleClose2}
//             >
//               Ver PDF
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { getDasn } from "../../../services/dasn/index";
import Currency from "../../inputs/currency";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import checkIcon from "../../../imgs/icons/check.svg";
import paidIcon from "../../../imgs/icons/paid.svg";
import "./style.css";

export default function DASCard(props) {
  const { data } = props;
  const [modalDasn, setModalDasn] = useState({});
  const [show, setShow] = useState(false);
  const [year, setYear] = useState("");
  const [show2, setShow2] = useState(false);

  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = (year) => {
    setShow(true);
    setYear(year);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    getDasn(id).then((response) => {
      setModalDasn(response.data);
    });
  };

  const renderDas = (dasn) => {
    return (
      <tr
        key={dasn.id}
        style={{ cursor: "pointer" }}
      >
        <td>{dasn.year}</td>
        <td style={{ display: "flex", justifyContent: "space-between" }}>
          {dasn.status === "SENT" ? (
            <>
              <span>Entregue</span>
              <img
                alt="check"
                src={checkIcon}
                style={{ margin: 1, height: 22, width: 22, float: "right" }}
              />
            </>
          ) : (
            <>
              <span>Pendente</span>
              <img
                alt="check"
                src={paidIcon}
                style={{ margin: 1, height: 22, width: 22, float: "right" }}
              />
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <Box>
      <div>
        <div className="card heig card-dasn">
          <Box
            sx={{
              marginY: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>DASN</h2>
            <Link to="/financial/annual_declaration" className="linkCard">
              <Button
                sx={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "none",
                  width: 130,
                  height: 45,
                  color: "#fff",
                  bgcolor: "#58C5C6",
                  border: "none",
                  "&:hover": {
                    color: "#58C5C6",
                    border: "1px solid #58C5C6",
                  },
                }}
              >
                Ver todas
              </Button>
            </Link>
          </Box>
          <table class="table table-hover p-0">

            {data.length === 0 ? (
              <>
              <tr><th colspan="2">Nenhuma DASN encontrada</th></tr>
              <tr><td colspan="2">Favor clicar no botão "Ver todas" e na página DASN, clicar no botão de atualizar</td></tr>
              </>
            ) : (
              <>
              <thead>
                <tr>
                  <th>Ano</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((das) => renderDas(das))}
              </tbody>
              </>
            )}
          </table>
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="col-11 text-center"> Atenção </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Preencha com cuidado, pois os dados a seguir serão enviado para a
            Receita federal
          </p>
          <p>Esta declaração se refere ao ano de {year}!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => {
              history.push(
                `/financial/annual_declaration/create/${year}/false`
              );
            }}
          >
            Ok! entendi
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={show2}
        onHide={handleClose2}
        className="modalContentDAS"
      >
        <Modal.Header closeButton>
          <Modal.Title className="col-11 text-center"> Informação imposto DASN </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <label>
              <p>Declaração referente a:</p>
              <strong>{modalDasn.year}</strong>
            </label>

            <label>
              <p>Tem algum funcionário registrado:</p>
              <strong>{modalDasn.hasEmployee ? "Sim" : "Não"}</strong>
            </label>

            <label>
              <p>Total faturado em comércio:</p>
              <strong>
                R$ {Currency({ value: parseFloat(modalDasn.totalTrade) })}
              </strong>
            </label>

            <label>
              <p>Total faturado na prestação de serviços:</p>
              <strong>
                R$ {Currency({ value: parseFloat(modalDasn.totalService) })}
              </strong>
            </label>

            <label>
              <p>Faturamento total:</p>
              <strong>
                R${" "}
                {Currency({
                  value: parseFloat(
                    modalDasn.totalService + modalDasn.totalTrade
                  ),
                })}
              </strong>
            </label>

            <label>
              <p>Status de declaração:</p>
              <strong
                className={`status ${
                  modalDasn.status === "SENT" ? "green" : "yellow"
                }`}
              >
                {modalDasn.status === "SENT" ? "Entregue !" : "Em aberto"}
              </strong>
            </label>
          </div>

          <div className="modalFooter">
            <Button
              variant="outline-info"
              className="showPdf"
              onClick={handleClose2}
            >
              Ver PDF
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
}
