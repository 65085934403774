import { api } from '../connection/api'

const baseEndpoint = 'associate/invoice';
const fetchBasket = 'associate/nfe';


const baseEndPointInvoice = 'invoice';
const createInvoice = 'invoice/invoice_create/basket';
const validateInvoice = 'invoice/invoice_create/basketValidate';


export async function fetchAssociatedInvoiceServices() {
    return await api.get(fetchBasket,{
      params: {type: 1}
    });
}

export async function getAssociatedInvoiceService(id) {
    return await api.get(`${fetchBasket}/${id}`,{
      params: {type: 0}
    });
}

export async function postAssociatedInvoiceService(associatedInvoiceService) {
    return await api.post(baseEndpoint, associatedInvoiceService);
}


export async function postAssociatedInvoiceCreateValidateServiceProduct(associatedInvoiceService) {
  return await api.post(validateInvoice, associatedInvoiceService);
}

export async function postAssociatedInvoiceCreateServiceProduct(associatedInvoiceService) {
  return await api.post(createInvoice, associatedInvoiceService);
}

export async function cancelAssociatedInvoiceService(id, data) {
    return await api.post(`${baseEndpoint}/cancel/${id}`, data);
}

export async function putAssociatedInvoiceService(id, associatedInvoiceService) {
    return await api.put(`${baseEndpoint}/${id}`, associatedInvoiceService);
}
