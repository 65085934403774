
/**
 * ------ ATTRIBUTES ------ 
 * width: largura do card
 * title: título do card
 * subtitle: (opcional): subtitulo do card
 * body: conteúdo que irá ser mostrado
 * actions (array de obj): ações de sistema  como por exemplo ir para  outra  página
 * showImage (opcional): mostra uma imgagem acima do title
 * imgVariant: 
 * iamgePath: caminho da imagem
 * showActions: mostra os linkS com as ações citadas acima
 * variant:  define a cor padrão do card OBS -> Segue as cores apenas do bootstrap (priary, secondary etc...)
 * textColor: define a cor dos textos dentro do card OBS -> Segue as cores apenas do bootstrap (priary, secondary etc...)
 * header: Cabeçalho do card
 */

import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const CardDefault = (props) => {
  const {
    width,
    title,
    subtitle,
    body,
    actions,
    showImage,
    imgVariant,
    imagePath,
    showActions,
    variant,
    textColor,
    header,
    headerClass,
  } = props.cardData;

  const [linkActions, setLinkActions] = useState();

  useEffect(() => {
    setLinkActions(
      actions.map((action) => {
        return <Card.Link href={action.goesTo}> {action.text} </Card.Link>;
      })
    );
  }, []);

  return (
    <Card className="shadow" style={{ width }} bg={variant} text={textColor}>
      {showImage ? <Card.Img variant={imgVariant} src={imagePath} /> : null}
      <Card.Header className={headerClass}>{header}</Card.Header>
      <Card.Body>
        <Card.Title> {title} </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        <Card.Text>{body}</Card.Text>

        {showActions ? linkActions : null}
      </Card.Body>
    </Card>
  );
};

export default CardDefault;