import "../App.css";
import { useHistory } from "react-router-dom";
import PageDefault from "../../../components/pageDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faPencilAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  fetchAssociatedServices,
  getAssociatedService,
  putActivate,
  putDeactivate,
} from "../../../services/associatedService";
import FontAwesomeIconButtonDefault from "../../../components/buttonWithFontAwesomeIcon";
import SwitcherDefault from "../../../components/switcher";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";

function Service() {
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [services, setServices] = useState([]);
  const [currentServiceId, setCurrentServiceId] = useState();

  useEffect(() => {
    fetchAssociatedServices().then(({ data }) => setServices(data));
  }, []);

  const updateStatus = () => {
    getAssociatedService(currentServiceId)
      .then(({ data: { id, status } }) => {
        switch (status) {
          case false: {
            putActivate(id)
              .then(() => {
                NotificationManager.success("Status alterado com sucesso");
                fetchAssociatedServices().then(({ data }) => setServices(data));
              })
              .catch((err) => NotificationManager.error(err));

            break;
          }

          case true: {
            putDeactivate(id)
              .then(() => {
                NotificationManager.success("Status alterado com sucesso");
                fetchAssociatedServices().then(({ data }) => setServices(data));
              })
              .catch((err) => NotificationManager.error(err));

            break;
          }

          default: {
            NotificationManager.error("Não foi possível alterar o status");

            break;
          }
        }
      })
      .catch((err) => NotificationManager.error(err));

    setAlert(false);
  };

  const data = services.map(({ id, code, name, status }) => [
    code,
    name,
    <SwitcherDefault
      switcherData={{
        checked: status,
        id,
        action: () => {
          setAlert(true);
          setCurrentServiceId(id);
        },
      }}
    />,
    <div className="">
      <FontAwesomeIcon
        icon={faEye}
        color="#878787"
        style={{ cursor: "pointer" }}
        className="mr-2"
        size="lg"
        title="Visualizar os dados do serviço"
        onClick={() => history.push(`/registration/service/view/${id}`)}
      />
      {status && (
        <FontAwesomeIcon
          icon={faPencilAlt}
          color="#878787"
          style={{ cursor: "pointer" }}
          size="lg"
          title="Alterar os dados do serviço"
          onClick={() => history.push(`/registration/service/update/${id}`)}
        />
      )}
    </div>,
  ]);

  const options = {
    selectableRows: false,
    download: false,
    downloadOptions: {
      filename: "servicos.csv",
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página: ",
        displayRows: "de",
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: "",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
    onDownload: (buildHead, buildBody, columns) =>
      buildHead(columns) +
      buildBody(
        services.map((currentService) => {
          currentService.status = currentService.status ? "Ativo" : "Inativo";
          return {
            data: [
              currentService.code,
              currentService.name,
              currentService.status,
            ],
          };
        })
      ),
    onPrint: (buildHead, buildBody, columns) =>
      buildHead(columns) +
      buildBody(
        services.map((service) => {
          return {
            index: service.length - 1,
            data: [service.name, service.status],
          };
        })
      ),

    sortOrder: {
      name: "Nome",
      direction: "asc",
    },
  };

  const columns = [
    {
      name: "Código interno",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Ações",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
      },
    },
  ];

  const pageContent = (
    <div className="row ml-lg-3">
      <NotificationContainer />
      <SweetAlert
        custom
        showCancel
        showCloseButton
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        title="Confirmar ação"
        btnSize="md"
        show={alert}
        onCancel={() => setAlert(false)}
        onConfirm={() => updateStatus()}
      >
        Você realmente deseja alterar o status do Serviço?
      </SweetAlert>
      <div className="d-flex justify-content-between align-items-center flex-wrap col-11 mt-3 p-0">
        <h2 style={{ color: "#002c43" }}>
          <FontAwesomeIcon
            className="back-icon mr-3 icon-hover"
            icon={faArrowLeft}
            size="1x"
            onClick={() => history.push("/home")}
            color="#002c43"
          />
          Serviços
        </h2>
        <FontAwesomeIconButtonDefault
          buttonData={{
            variant: "info",
            text: "Novo serviço",
            action: () => history.push("/registration/service/create"),
            icon: <FontAwesomeIcon icon={faPlus} />,
          }}
        />
      </div>
      <div className="row col-11 p-0 mt-3 m-0">
        <MUIDataTable
          className="col-12"
          title="Serviços"
          data={data}
          options={options}
          columns={columns}
        />
      </div>
    </div>
  );

  return <PageDefault pageData={{ pageContent }} />;
}

export default Service;
