import InvoiceDevolution from "../../imgs/icons/notasDevolucao.svg";
import Schedule from "../../imgs/icons/agendamentos.svg";

// Menu atual
import InitialPage from "../../imgs/icons/paginaInicial.svg";
import Training from "../../imgs/icons/treinamento.svg";
import Financial from "../../imgs/icons/financeiro.svg";
import GeneralInformation from "../../imgs/icons/informacoes-legais.svg";
import Consultancy from "../../imgs/icons/consultoria.svg";
import Leave from "../../imgs/icons/sair.svg";
import Profile from "../../imgs/icons/update.svg";

// import MEI from "../../imgs/icons/meuMEI.svg";

// Início Menu
import VisaoGeral from "../../imgs/menu/visao_geral.svg";
import MEI from "../../imgs/menu/meu_mei.svg";
import Cadastros from "../../imgs/menu/cadastros.svg";
import Financas from "../../imgs/menu/financas.svg";
import Servicos from "../../imgs/menu/servicos.svg";
import Capacitacao from "../../imgs/menu/capacitacao.svg";
import MeAjuda from "../../imgs/menu/me_ajuda.svg";
import Sobre from "../../imgs/menu/sobre.svg";
import Sair from "../../imgs/menu/sair.svg";
import Perfil from "../../imgs/menu/perfil.svg";

import VisaoGeralHover from "../../imgs/menu/visao_geral_hover.svg";
import MEIHover from "../../imgs/menu/meu_mei_hover.svg";
import CadastrosHover from "../../imgs/menu/cadastros_hover.svg";
import FinancasHover from "../../imgs/menu/financas_hover.svg";
import ServicosHover from "../../imgs/menu/servicos_hover.svg";
import CapacitacaoHover from "../../imgs/menu/capacitacao_hover.svg";
import MeAjudaHover from "../../imgs/menu/me_ajuda_hover.svg";
import SobreHover from "../../imgs/menu/sobre_hover.svg";
import PerfilHover from "../../imgs/menu/perfil_hover.svg";

import Marcacao from "../../imgs/menu/marcacao.svg";
// Fim Menu

import Registration from "../../imgs/icons/cadastroGerais.svg";
import Company from "../../imgs/icons/dadosEmpresa.svg";
import CertificatedMEI from "../../imgs/icons/certificadoMEI.svg";
import DigitalCertificate from "../../imgs/icons/certificadoDigital.svg";
import NFE from "../../imgs/icons/nfE.svg";
import NFSE from "../../imgs/icons/nfsE.svg";
import BillReciver from "../../imgs/icons/contasReceber.svg";
import BillPay from "../../imgs/icons/contasPagar.svg";
import FinancialAnalysis from "../../imgs/icons/analiseFinanceira.svg";
import NotesSeries from "../../imgs/icons/serieNotas.svg";
import DAS from "../../imgs/icons/DAS.svg";
import DASN from "../../imgs/icons/DASN.svg";
import Products from "../../imgs/icons/produtos.svg";
import Services from "../../imgs/icons/servicos.svg";
import Clients from "../../imgs/icons/cliente.svg";
import Provider from "../../imgs/icons/fornecedores.svg";
import Category from "../../imgs/icons/categoria.svg";
import LogoACSP from "../../imgs/icons/logoACSP.svg";

import LogoMei from "../../imgs/icons/logo-portal-MEI.svg";
import LogoMeiLetraBranca from "../../imgs/icons/mei-logo4.svg";

import ACCredito from "../../imgs/servicos/ac_credito.svg";
import ACLojas from "../../imgs/servicos/ac_lojas.svg";
import ACMarcas from "../../imgs/servicos/ac_marcas.svg";
import ACSPLegal from "../../imgs/servicos/acsp_legal.svg";
import BalcaoConsumidor from "../../imgs/servicos/balcao_consumidor.svg";
import ClubeDescontoVitrineSampa from "../../imgs/servicos/clube_desconto_vitrine_sampa.svg";
import CEMAAC from "../../imgs/servicos/cemaac.svg";
import ConsultaSCPC from "../../imgs/servicos/consulta_scpc.svg";
import DeclaracaoExclusividade from "../../imgs/servicos/declaracao_exclusividade.svg";
import GrupoAtivarNegocios from "../../imgs/servicos/grupo_ativar_negocios.svg";
import JUCESP from "../../imgs/servicos/jucesp.svg";
import ReceitaFederal from "../../imgs/servicos/receita_federal.svg";
import MEIMaintenance from "../../imgs/maintenance.png";

const iconsIMG = {
  Schedule,

  VisaoGeral,
  MEI,
  Cadastros,
  Financas,
  Servicos,
  Capacitacao,
  MeAjuda,
  Sobre,
  Perfil,
  VisaoGeralHover,
  MEIHover,
  CadastrosHover,
  FinancasHover,
  ServicosHover,
  CapacitacaoHover,
  MeAjudaHover,
  SobreHover,
  Sair,
  PerfilHover,
  Marcacao,

  Registration,
  Company,
  CertificatedMEI,
  DigitalCertificate,
  InvoiceDevolution,
  BillReciver,
  NFSE,
  NFE,
  BillPay,
  FinancialAnalysis,
  NotesSeries,
  DAS,
  DASN,
  Products,
  Services,
  Clients,
  Provider,
  Category,
  LogoACSP,
  LogoMei,
  LogoMeiLetraBranca,
  ACCredito,
  ACLojas,
  ACMarcas,
  ACSPLegal,
  BalcaoConsumidor,
  ClubeDescontoVitrineSampa,
  CEMAAC,
  ConsultaSCPC,
  DeclaracaoExclusividade,
  GrupoAtivarNegocios,
  JUCESP,
  ReceitaFederal,
  InitialPage,
  Training,
  Financial,
  GeneralInformation,
  Consultancy,
  Leave,
  MEIMaintenance,
};

export default iconsIMG;
