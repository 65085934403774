// import {
//   faArrowLeft,
//   faClipboard,
//   faDollarSign,
//   faMapMarkerAlt,
//   faBalanceScale,
//   faCheck,
//   faCity,
//   faListOl,
//   faCalendarAlt,
// } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Field } from "formik";
import * as moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
// import AlertDefault from "../../../components/alertDefault";
import ButtonActionDefault from "../../../components/buttonAction";
import ButtonDefault from "../../../components/buttonDefault";
import DialogDefault from "../../../components/dialog";
import CepjMask from "../../../components/inputs/cep";
import CnpjMask from "../../../components/inputs/cnpj";
import CpfMask from "../../../components/inputs/cpf";
import PhoneMask from "../../../components/inputs/phone";
import PageDefault from "../../../components/pageDefault";
import {
  buscaCep,
  fetchCities,
  fetchStates,
} from "../../../services/address/index";
import {
  getAssociated,
  putAssociated,
  getUpdatedAssociatedCnaesCompany,
} from "../../../services/profile/index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Update from "../../../imgs/icons/update.svg";
import Tooltip from "@mui/material/Tooltip";
import {
  AlertMessages,
  AlertSuccess,
  SuccessMessages,
  WarningMessages,
} from "../../../helper";
import checkIcon from "../../../imgs/icons/check.svg";
import errorIcon from "../../../imgs/icons/errorIcon.svg";
import ReactLoading from "react-loading";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import "../App.css";
import "./CompanyData.css";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import AssignmentIndRounded from "@mui/icons-material/AssignmentIndRounded";
import AssignmentRounded from "@mui/icons-material/AssignmentRounded";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import GavelRounded from "@mui/icons-material/GavelRounded";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import BusinessRounded from "@mui/icons-material/BusinessRounded";
import PhoneIphoneRounded from "@mui/icons-material/PhoneIphoneRounded";
import FormatListNumberedRounded from "@mui/icons-material/FormatListNumberedRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MarkunreadMailboxRoundedIcon from "@mui/icons-material/MarkunreadMailboxRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Button } from "@mui/material";
import RefreshRounded from "@mui/icons-material/RefreshRounded";
import { getCertificate } from "../../../services/associate";
import ContentPasteGoRounded from "@mui/icons-material/ContentPasteGoRounded";
import { Alert } from "react-bootstrap";
import brLocale from "moment/locale/pt-br";

function CompanyData() {
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [associatedCode, setAssociatedCode] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [socialName, setSocialName] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [natureOperation, setNatureOperation] = useState("");
  const [taxationRegime, setTaxationRegime] = useState("");
  const [fondationDate, setFondationDate] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street, setStreet] = useState("");
  const [numberHouse, setNumberHouse] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [complement, setComplement] = useState("");
  const [isFree, setIsFree] = useState("");
  const [tempCity, setTempCity] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [isTouched, setIsTouched] = useState("");
  const companyDataFormRef = useRef();
  const [someThingWasClicked, setSomeThingWasClicked] = useState(false);
  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [cnaes, setCnaes] = useState([]);
  const [municipalRegistries, setMunicipalRegistries] = useState([]);
  const [emiteNfe, setEmiteNfe] = useState();
  const [emiteNfse, setEmiteNfse] = useState();
  const history = useHistory();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [updateDate, setUpdateDate] = useState("");
  const cancel = () => {
    history.push("/home");
  };
  const [backErrors, setBackErrors] = useState({});
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };

  const normalizeString = (string) =>
    string
      .normalize("NFD")
      .replace(/[^a-zA-Zs]/g, "")
      .toUpperCase();

  const modalButtonYes = () => {
    setShow(true);
    handleSubmitAssociated();
  };

  const handleClicked = () => {
    setSomeThingWasClicked(true);
    setSuccess(false);
  };

  const cnaeFormater = (string) =>
    string.replace(/(\d{4})(\d{1})(\d{2})/, "$1-$2/$3");

  const ValidationSchema = Yup.object().shape({
    zipCode: Yup.string().nullable().required("Campo Obrigatório"),

    street: Yup.string().nullable().required("Campo Obrigatório"),

    numberHouse: Yup.string().nullable().required("Campo Obrigatório"),

    neighborhood: Yup.string().nullable().required("Campo Obrigatório"),

    state: Yup.string().nullable().required("Campo Obrigatório"),

    city: Yup.string().nullable().required("Campo Obrigatório"),
    contactEmail: Yup.string()
      .email("Email inválido")
      .required("Campo Obrigatório!"),
    contactPhone: Yup.string()
      .required("Campo Obrigatório!")
      .test("phone", "Telefone inválido", (val) => {
        if (!val) return true;
        return (
          String(val.replace(/\D/g, "")).length >= 10 &&
          String(val.replace(/\D/g, "")).length < 12
        );
      }),
  });

  const formatTaxationRegime = (taxationRegime) => {
    switch (taxationRegime) {
      case "SIMPLE_NATIONAL": {
        return "Simples Nacional";
      }
      case "SIMPLE_NATIONAL_SUB_LIMIT_EXCESS": {
        return "Simples Nacional, excesso sublimite de receita bruta";
      }
      case "NORMAL_REGIME": {
        return "Regime Normal";
      }
      default: {
        return "";
      }
    }
  };

  const AlertDefault = (props) => {
    const [show, setShow] = useState(false);

    const {
      head,
      content,
      alertVariant,
      buttonCloseText,
      buttonCloseVariant,
      showCloseButton,
    } = props.alertData;

    useEffect(() => setShow(props.showAlert), [props.showAlert]);

    return (
      <Alert show={show} variant={alertVariant}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CheckRoundedIcon sx={{ color: "#fff" }} />

          <p
            style={{
              marginTop: 5,
              marginBottom: 5,
              paddingRight: 20,
              color: "#FFF",
            }}
          >
            {content}
          </p>
        </div>

        {showCloseButton && <hr />}
        <div className="d-flex">
          {showCloseButton && (
            <Button onClick={() => setShow(false)} variant={buttonCloseVariant}>
              {buttonCloseText}
            </Button>
          )}
        </div>
      </Alert>
    );
  };

  useEffect(() => {
    fetchStates().then(({ data }) => {
      setStates(
        data.map((state) => ({
          value: state.id,
          label: state.name,
          uf: state.initials,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (!stateId) return;
    fetchCities(stateId).then(({ data }) => {
      const cities = data.map((city) => ({
        value: city.id,
        label: city.name,
      }));
      setCities(cities);
      const cityFullName = cities.filter(
        (element) => normalizeString(element.label) == normalizeString(tempCity)
      );
      setCity(cityFullName.length ? cityFullName[0].label : "");
      setCityId(cityFullName.length ? cityFullName[0].value : "");
    });
  }, [stateId, tempCity]);


  function initDataLoad(){
    getAssociated().then(({ data }) => {
      const {
        cpf,
        rg,
        associatedCode,
        cnpj,
        stateRegistration,
        socialName,
        fantasyName,
        natureOperation,
        taxationRegime,
        foundationDate,
        address,
        contact,
        cnaes,
        municipalRegistries,
        emiteNfe,
        emiteNfse,
        generalUpdateAt,
      } = data;
      moment.locale('pt-br', [brLocale])
      const { zipCode, street, numberHouse, complement, neighborhood, city } =
        address;
      const { state, stateId } = city;
      setEmiteNfe(emiteNfe);
      setEmiteNfse(emiteNfse);
      setTaxationRegime(formatTaxationRegime(taxationRegime));
      setCpf(cpf);
      setRg(rg);
      setAssociatedCode(associatedCode);
      setCnpj(cnpj);
      setStateRegistration(stateRegistration);
      setSocialName(socialName);
      setFantasyName(fantasyName);
      setNatureOperation(natureOperation);
      setFondationDate(
        foundationDate ? moment.utc(foundationDate).format("DD/MM/YYYY") : ""
      );

      setZipCode(zipCode);
      setStreet(street);
      setNumberHouse(numberHouse);
      setComplement(complement);
      setNeighborhood(neighborhood);
      setState(state.name);
      setTempCity(city.name);
      setCity(city.name);
      setStateId(stateId);
      setCityId(city.id);
      setIsFree(stateRegistration === "ISENTO");
      setContactEmail(contact.email);
      setContactPhone(phoneMask(contact.phone));

      setUpdateDate(
        generalUpdateAt
          ? moment.utc(generalUpdateAt).format(" L, H:mm ")
          : ""
      );

      setCnaes(cnaes);
      setMunicipalRegistries(municipalRegistries);
    });
  }
  useEffect(() => {
   initDataLoad();
  }, []);

  const handleSubmitAssociated = (e) => {
    const associated = {
      // municipalRegistration: municipalRegistration,
      stateRegistration: stateRegistration,
      zipCode: zipCode.replace(/[^0-9]/g, ""),
      street: street,
      numberHouse: numberHouse,
      complement: complement,
      neighborhood: neighborhood,
      stateId: stateId,
      cityId: cityId,
      email: contactEmail,
      phone: contactPhone.replace(/[^0-9]/g, ""),
    };

    putAssociated(associated)
      .then((res) => {
        setSuccess(true);
        setSomeThingWasClicked(false);
      })
      .catch((err) => {
        const errors = {};

        if (err.response)
          err.response.data.errors.forEach((item) => {
            const { fieldName, message } = item;

            if (fieldName == "stateId") errors.state = message;
            else if (fieldName == "cityId") errors.city = message;
            else errors[fieldName] = message;
          });

        setBackErrors(errors);
      });
  };
  const handleUpdateCompanySubmit = async (e) => {
    setLoadingUpdate(true);
    setSomeThingWasClicked(true);
    await getUpdatedAssociatedCnaesCompany()
      .then((res) => {
        NotificationManager.success(SuccessMessages.SUCCESS_UPDATE_MEI_REQUEST);

        initDataLoad();
        setSomeThingWasClicked(false);
      })
      .catch((error) => {
        NotificationManager.listNotify.splice(
          0,
          NotificationManager.listNotify.length
        );
        NotificationManager.error(error?.response?.data?.message);
        setLoadingUpdate(false);
      });
    setLoadingUpdate(false);
  };

  const onBlurZipCode = (e) => {
    const cep = e.target.value.replace(/[^0-9]+/g, "");

    if (!cep || cep.length < 8) {
      return;
    }

    buscaCep(cep)
      .then(({ data }) => {
        if (!data.erro) {
          const stateFullName = states.filter(
            (element) => element.uf.toLowerCase() == data.uf.toLowerCase()
          );
          setTempCity(data.localidade);
          setZipCode(data.cep);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setState(stateFullName.length ? stateFullName[0].label : "");
          setStateId(stateFullName.length ? stateFullName[0].value : "");
        } else {
          setStreet("");
          setNeighborhood("");
          setState("");
          setStateId("");
          setCity("");
          setCityId("");
          setComplement("");
          setNumberHouse("");
        }
      })
      .catch(() => {
        setStreet("");
        setNeighborhood("");
        setState("");
        setStateId("");
        setCity("");
        setCityId("");
        setComplement("");
        setNumberHouse("");
      });
  };

  const phoneMask = (phone) => phone.replace(/\D/g, "");

  const page = (
    <div className="container mt-5">
      <Formik
        initialValues={{
          cpf,
          rg,
          associatedCode,
          cnpj,
          socialName,
          fantasyName,
          stateRegistration,
          natureOperation,
          taxationRegime,
          fondationDate,
          updateDate,
          zipCode,
          street,
          numberHouse,
          neighborhood,
          state,
          city,
          complement,
          contactPhone,
          contactEmail,
          isSecondButton: false,
        }}
        enableReinitialize
        innerRef={companyDataFormRef}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          if (values.isSecondButton) {
            handleUpdateCompanySubmit();
          } else {
            handleSubmitAssociated();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <div>
              <h2 className="yor_companyData">Dados da sua empresa</h2>
            </div>
            <div className="row">
              <div className="col-sm-1" />
            </div>
            <div className="row mt-2">
              <div className="col-sm-1" />
              <div className="col-sm-10 companyData__form p-4">
                <p className="generalData">Dados gerais</p>

                <div className="row mt-4 mb-2">
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">CPF</label>

                    <div className="input-group">
                      <TextField
                        onChange={(cpf) => {
                          clearBackErrors("cpf");
                          setCpf(cpf.target.value);
                          setFieldValue("cpf", cpf.target.value);
                        }}
                        value={values.cpf}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          inputComponent: CpfMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentIndRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.cpf && touched.cpf && errors.cpf) ||
                        backErrors.cpf}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">RG</label>
                    <div className="input-group">
                      <TextField
                        onChange={(rg) => {
                          clearBackErrors("rg");
                          setRg(rg.target.value);
                          setFieldValue("rg", rg.target.value);
                        }}
                        value={values.rg}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentIndRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.rg && touched.rg && errors.rg) || backErrors.rg}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Código de Associado
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(associatedCode) => {
                          clearBackErrors("associatedCode");
                          setAssociatedCode(associatedCode.target.value);
                          setFieldValue(
                            "associatedCode",
                            associatedCode.target.value
                          );
                        }}
                        value={values.associatedCode}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentIndRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.associatedCode &&
                        touched.associatedCode &&
                        errors.associatedCode) ||
                        backErrors.associatedCode}
                    </small>
                  </div>
                </div>

                <div className="row mt-2 mb-2">
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">CNPJ</label>

                    <div className="input-group">
                      <TextField
                        onChange={(cnpj) => {
                          clearBackErrors("cnpj");
                          setCnpj(cnpj.target.value);
                          setFieldValue("cnpj", cnpj.target.value);
                        }}
                        value={values.cnpj}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          inputComponent: CnpjMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.cnpj && touched.cnpj && errors.cnpj) ||
                        backErrors.cnpj}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Razão Social
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(socialName) => {
                          clearBackErrors("socialName");
                          setSocialName(socialName.target.value);
                          setFieldValue("socialName", socialName.target.value);
                        }}
                        value={values.socialName}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.socialName &&
                        touched.socialName &&
                        errors.socialName) ||
                        backErrors.socialName}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Nome fantasia
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(fantasyName) => {
                          clearBackErrors("fantasyName");
                          setFantasyName(fantasyName.target.value);
                          setFieldValue(
                            "fantasyName",
                            fantasyName.target.value
                          );
                        }}
                        value={values.fantasyName}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.fantasyName &&
                        touched.fantasyName &&
                        errors.fantasyName) ||
                        backErrors.fantasyName}
                    </small>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <Button
                      onClick={() => {
                        setFieldValue("isSecondButton", true, false);
                        handleUpdateCompanySubmit();
                      }}
                      startIcon={<RefreshRounded />}
                      sx={{
                        marginRight: "10px",
                        marginTop: "40px",
                        width: "240px",
                        height: "42px",
                        color: "#FFFFFF",
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "1px solid #58C5C6",
                        backgroundColor: "#58c5c6",
                        textTransform: "none",
                        "&:hover": {
                          color: "#58C5C6",
                          border: "1px solid #58C5C6",
                        },
                      }}
                    >
                      Solicitar Atualização
                    </Button>

                    <p
                      style={{
                        marginTop: "5px",
                        fontSize: "11px",
                        color: "#0CBA90",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                      }}
                    >
                      Última atualização feita em {updateDate}
                    </p>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        getCertificate().then((response) => {
                          window.open(
                            response.data.certificate,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        });
                      }}
                      startIcon={<ContentPasteGoRounded />}
                      sx={{
                        marginLeft: "10px",
                        marginTop: "40px",
                        width: "240px",
                        height: "42px",
                        color: "#FFFFFF",
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "1px solid #58C5C6",
                        backgroundColor: "#58c5c6",
                        textTransform: "none",
                        "&:hover": {
                          color: "#58C5C6",
                          border: "1px solid #58C5C6",
                        },
                      }}
                    >
                      Emitir certificado de MEI
                    </Button>
                  </div>
                </div>

                {/* alertas */}
                <div className="row justify-content-center mt-3">
                  <div
                    style={{
                      marginRight: "10px",
                      width: "268px",
                    }}
                  >
                    {emiteNfse ? (
                      <div
                        style={{
                          backgroundColor: "#0CBA90",
                          display: "flex",
                          alignSelf: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                          height: 60,
                        }}
                      >
                        <CheckRoundedIcon
                          sx={{
                            width: 35,
                            height: 35,
                            color: "#FFF",
                            alignSelf: "center",
                            paddingLeft: "10px",
                          }}
                        />
                        <span
                          style={{ color: "white", padding: 10, fontSize: 14 }}
                        >
                          {AlertSuccess.CAN_EMITE_NFSE}
                        </span>
                      </div>
                    ) : (
                      <Tooltip arrow title={AlertMessages.CANNOT_EMITE_NFSE}>
                        <div
                          style={{
                            backgroundColor: "#D70F57",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 5,
                            alignSelf: "center",
                            justifyItems: "center",
                            justifyContent: "center",
                            height: 60,
                          }}
                        >
                          <ErrorOutlineRoundedIcon
                            sx={{
                              color: "#fff",
                              width: "30px",
                              height: "30px",
                              alignSelf: "center",
                              marginLeft: "10px",
                            }}
                          />
                          <span
                            style={{
                              color: "white",
                              padding: 10,
                              fontSize: 14,
                            }}
                          >
                            {AlertMessages.CANNOT_EMITE_NFSE_DETAIL}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                      width: "268px",
                    }}
                  >
                    {emiteNfe ? (
                      <div
                        style={{
                          backgroundColor: "#0CBA90",
                          display: "flex",
                          alignSelf: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                          height: 60,
                        }}
                      >
                        <CheckRoundedIcon
                          sx={{
                            width: 35,
                            height: 35,
                            color: "#FFF",
                            alignSelf: "center",
                            paddingLeft: "10px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: 14,
                            color: "white",
                            padding: 10,
                          }}
                        >
                          {AlertSuccess.CAN_EMITE_NFE}
                        </span>
                      </div>
                    ) : (
                      <Tooltip arrow title={AlertMessages.CANNOT_EMITE_NFE}>
                        <div
                          style={{
                            backgroundColor: "#D70F57",
                            display: "flex",
                            borderRadius: 5,
                            alignSelf: "center",
                            justifyItems: "center",
                            justifyContent: "center",
                            height: 60,
                          }}
                        >
                          <ErrorOutlineRoundedIcon
                            sx={{
                              color: "#fff",
                              width: "30px",
                              height: "30px",
                              alignSelf: "center",
                              marginLeft: "10px",
                            }}
                          />
                          <span
                            style={{
                              fontSize: 14,
                              color: "white",
                              padding: 10,
                            }}
                          >
                            {AlertMessages.CANNOT_EMITE_NFE_DETAIL}{" "}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                {/* alertas */}

                <p className="generalData" style={{ marginTop: "100px" }}>
                  Dados tributários
                </p>

                <div className="row mt-4 mb-2">
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Inscrição Estadual
                    </label>
                    <div className="input-group">
                      <TextField
                        onChange={(stateRegistration) => {
                          clearBackErrors("stateRegistration");
                          setStateRegistration(
                            stateRegistration.target.value.replace(
                              /[^0-9]+/g,
                              ""
                            )
                          );
                          setFieldValue(
                            "stateRegistration",
                            stateRegistration.target.value.replace(
                              /[^0-9]+/g,
                              ""
                            )
                          );
                        }}
                        value={values.stateRegistration}
                        disabled={isFree}
                        onClick={handleClicked}
                        sx={{
                          width: "320px",
                          backgroundColor: "#fff",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOn sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.stateRegistration &&
                        touched.stateRegistration &&
                        errors.stateRegistration) ||
                        backErrors.stateRegistration}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{
                      color: "#7B7B7B",
                      margin: "-5px 0px 0px 10px",
                    }}
                  >
                    <Checkbox
                      checked={isFree}
                      onChange={(data) => {
                        setIsFree(data.target.checked);

                        if (data.target.checked) {
                          setFieldValue("stateRegistration", "ISENTO");
                          setStateRegistration("ISENTO");
                        } else {
                          setFieldValue("stateRegistration", "");
                          setStateRegistration("");
                        }
                      }}
                      defaultChecked
                      sx={{
                        color: "#7B7B7B",
                        "&.Mui-checked": {
                          color: "#58C5C6",
                        },
                      }}
                    />

                    <label
                      for="state-inscription"
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "14px",
                      }}
                    >
                      Isenta de inscrição estadual
                    </label>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Natureza da Operação
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(natureOperation) => {
                          clearBackErrors("natureOperation");
                          setNatureOperation(natureOperation.target.value);
                          setFieldValue(
                            "natureOperation",
                            natureOperation.target.value
                          );
                        }}
                        value={values.natureOperation}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOn sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.natureOperation &&
                        touched.natureOperation &&
                        errors.natureOperation) ||
                        backErrors.natureOperation}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Reg. Especial de Tributação
                    </label>
                    <div className="input-group">
                      <TextField
                        onChange={(taxationRegime) => {
                          clearBackErrors("taxationRegime");
                          setTaxationRegime(taxationRegime.target.value);
                          setFieldValue(
                            "taxationRegime",
                            taxationRegime.target.value
                          );
                        }}
                        value={values.taxationRegime}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <GavelRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.taxationRegime &&
                        touched.taxationRegime &&
                        errors.taxationRegime) ||
                        backErrors.taxationRegime}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted">Data da Fundação</label>
                    <div className="input-group">
                      <TextField
                        onChange={(fondationDate) => {
                          clearBackErrors("fondationDate");
                          setFondationDate(fondationDate.target.value);
                          setFieldValue(
                            "fondationDate",
                            fondationDate.target.value
                          );
                        }}
                        value={values.fondationDate}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EBEBEB",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonth sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.fondationDate &&
                        touched.fondationDate &&
                        errors.fondationDate) ||
                        backErrors.fondationDate}
                    </small>
                  </div>
                </div>

                {!!municipalRegistries.length && (
                  <div>
                    <label className="text-muted companyDatalabel">
                      Inscrições municipais
                    </label>
                    <div className="row">
                      {municipalRegistries.map((mr) => (
                        <>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <TextField
                                value={`${mr.city.name} - ${mr.city.state.initials}`}
                                disabled
                                sx={{
                                  width: "100%",
                                  backgroundColor: "#EBEBEB",
                                  borderRadius: "5px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <BusinessRounded
                                        sx={{ color: "#58C5C6" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <TextField
                                value={mr.municipalRegistry}
                                disabled
                                sx={{
                                  width: "100%",
                                  backgroundColor: "#EBEBEB",
                                  borderRadius: "5px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FormatListNumberedRounded
                                        sx={{ color: "#58C5C6" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                )}

                {!!cnaes.length && (
                  <div className="mt-2">
                    <label className="text-muted companyDatalabel">CNAEs</label>
                    <div className="row">
                      <div className="col-sm-12">
                        {cnaes.map((cnae) => (
                          <div className="input-group pb-1" key={cnae.id}>
                            <TextField
                              value={`${cnaeFormater(cnae.code)} - ${
                                cnae.name
                              }`}
                              disabled
                              sx={{
                                width: "100%",
                                backgroundColor: "#EBEBEB",
                                borderRadius: "5px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CheckBoxRoundedIcon
                                      sx={{ color: "#58C5C6" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="generalData"
                  style={{
                    marginTop: "40px",
                  }}
                >
                  Endereço
                </div>
                <div className="row mt-4 mb-2">
                  <div className="col-sm-4">
                    <div className="d-flex justify-content-between">
                      <label className="text-muted companyDatalabel">CEP</label>

                      <a
                        href="http://www.buscacep.correios.com.br/sistemas/buscazipCode/"
                        target="_blank"
                        className="companyData__label--findZipCodeLink"
                        rel="noreferrer"
                        style={{ color: "#58c5c6" }}
                      >
                        <u>Não sei meu CEP?</u>
                      </a>
                    </div>
                    <div className="input-group">
                      <TextField
                        onChange={(zipCode) => {
                          clearBackErrors("zipCode");
                          setZipCode(zipCode.target.value);
                          setFieldValue("zipCode", zipCode.target.value);
                        }}
                        value={values.zipCode}
                        onBlur={onBlurZipCode}
                        onClick={handleClicked}
                        sx={{
                          width: "320px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          inputComponent: CepjMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.zipCode && touched.zipCode && errors.zipCode) ||
                        backErrors.zipCode}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Logradouro
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(street) => {
                          clearBackErrors("street");
                          setStreet(street.target.value);
                          setFieldValue("street", street.target.value);
                        }}
                        value={values.street}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EAEAEA",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.street && touched.street && errors.street) ||
                        backErrors.street}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">Nº</label>

                    <div className="input-group">
                      <TextField
                        onChange={(numberHouse) => {
                          clearBackErrors("numberHouse");
                          setNumberHouse(numberHouse.target.value);
                          setFieldValue(
                            "numberHouse",
                            numberHouse.target.value
                          );
                        }}
                        value={values.numberHouse}
                        onBlur={(numberHouse) => {
                          if (numberHouse.target.value == "") {
                            setFieldValue("numberHouse", "S/N");
                            setNumberHouse("");
                          }
                        }}
                        onClick={handleClicked}
                        sx={{
                          width: "320px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MarkunreadMailboxRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.numberHouse &&
                        touched.numberHouse &&
                        errors.numberHouse) ||
                        backErrors.numberHouse}
                    </small>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Bairro
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(neighborhood) => {
                          clearBackErrors("neighborhood");
                          setNeighborhood(neighborhood.target.value);
                          setFieldValue(
                            "neighborhood",
                            neighborhood.target.value
                          );
                        }}
                        value={values.neighborhood}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EAEAEA",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.neighborhood &&
                        touched.neighborhood &&
                        errors.neighborhood) ||
                        backErrors.neighborhood}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Estado
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(state) => {
                          clearBackErrors("state");
                          setState(state.target.value);
                          setFieldValue("state", state.target.value);
                        }}
                        value={values.state}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EAEAEA",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.state && touched.state && errors.state) ||
                        backErrors.state}
                    </small>
                  </div>
                  <div className="col-sm-4">
                    <label className="text-muted companyDatalabel">
                      Cidade
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(city) => {
                          clearBackErrors("city");
                          setCity(city.target.value);
                          setFieldValue("city", city.target.value);
                        }}
                        value={values.city}
                        disabled
                        sx={{
                          width: "320px",
                          backgroundColor: "#EAEAEA",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.city && touched.city && errors.city) ||
                        backErrors.city}
                    </small>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-sm-12">
                    <label className="text-muted companyDatalabel">
                      Complemento
                    </label>
                    <div className="input-group">
                      <TextField
                        onChange={(complement) => {
                          clearBackErrors("complement");
                          setComplement(complement.target.value);
                          setFieldValue("complement", complement.target.value);
                        }}
                        value={values.complement}
                        onClick={handleClicked}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnRoundedIcon
                                sx={{ color: "#58C5C6" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.complement &&
                        touched.complement &&
                        errors.complement) ||
                        backErrors.complement}
                    </small>
                  </div>
                </div>

                <div
                  className="h4 text-muted generalData"
                  style={{ marginTop: "40px" }}
                >
                  Contato
                </div>
                <div className="row">
                  <div className="col-sm-6 m-0">
                    <label className="text-muted mt-4 companyDatalabel">
                      Email de contato
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(contactEmail) => {
                          clearBackErrors("contactEmail");
                          setContactEmail(contactEmail.target.value);
                          setFieldValue(
                            "contactEmail",
                            contactEmail.target.value
                          );
                        }}
                        value={values.contactEmail}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailRoundedIcon sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.contactEmail &&
                        touched.contactEmail &&
                        errors.contactEmail) ||
                        backErrors.email}
                    </small>
                  </div>

                  <div className="col-sm-6 m-0">
                    <label className="text-muted mt-4 companyDatalabel">
                      Telefone
                    </label>

                    <div className="input-group">
                      <TextField
                        onChange={(contactPhone) => {
                          clearBackErrors("contactPhone");
                          setContactPhone(contactPhone.target.value);
                          setFieldValue(
                            "contactPhone",
                            phoneMask(contactPhone.target.value)
                          );
                        }}
                        value={values.contactPhone}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          inputComponent: PhoneMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIphoneRounded sx={{ color: "#58C5C6" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <small className="text-danger">
                      {(errors.contactPhone &&
                        touched.contactPhone &&
                        errors.contactPhone) ||
                        backErrors.phone}
                    </small>
                  </div>
                </div>
                <div className="row mb-5 ">
                  <div>
                    <div className="col-12 mt-4">
                      <AlertDefault
                        showAlert={success}
                        alertData={{
                          head: "Sucesso",
                          alertVariant: "success",
                          content: "Dados salvos com sucesso.",
                          buttonCloseText: "Fechar",
                          buttonCloseVariant: "default",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {someThingWasClicked ? (
                        <div
                          className="col-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <DialogDefault
                            dialogData={{
                              buttonText: "Voltar",
                              buttonFontColor: "#fff",
                              buttonVariant: "none",
                              buttonClass: "btn-voltar-company-data",
                              showHeader: true,
                              showBody: true,
                              showFooter: false,
                              closeModal: show,
                              dialogBody: (
                                <div className="div-modal-company-data">
                                  <h2>
                                    Deseja salvar as <br></br> alterações?
                                  </h2>
                                  <div className="d-flex justify-content-center">
                                    <div className="col-4 btn-modal-company-data-nao">
                                      <ButtonActionDefault
                                        className="createAccout_btn font-weight-bold col mr-1 mt-2"
                                        buttonData={{
                                          variant: "outline-info",
                                          action: () => cancel(),
                                          text: "NÃO",
                                          textColor: "#FFF",
                                          _class:
                                            "font-weight-bold col mr-1 mt-2",
                                        }}
                                      />
                                    </div>
                                    <div className="col-4 btn-modal-company-data-sim mb-4">
                                      <ButtonActionDefault
                                        buttonData={{
                                          text: "SIM",
                                          variant: "outline-info",
                                          _class: "mt-2 col",

                                          action: () => modalButtonYes(),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ),
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-2">
                          <Button
                            onClick={() => {
                              history.push("/home");
                            }}
                            sx={{
                              marginTop: "40px",
                              width: "140px",
                              height: "42px",
                              color: "#FFFFFF",
                              fontFamily: "Source Sans Pro",
                              fontSize: "16px",
                              fontWeight: "600",
                              border: "1px solid #7B7B7B",
                              backgroundColor: "#7B7B7B",
                              textTransform: "none",
                              "&:hover": {
                                color: "#7B7B7B",
                                border: "1px solid #7B7B7B",
                              },
                            }}
                          >
                            Voltar
                          </Button>
                        </div>
                      )}

                      <div className="">
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          sx={{
                            marginTop: "40px",
                            width: "140px",
                            height: "42px",
                            color: "#FFFFFF",
                            fontFamily: "Source Sans Pro",
                            fontSize: "16px",
                            fontWeight: "600",
                            border: "1px solid #58C5C6",
                            backgroundColor: "#58c5c6",
                            textTransform: "none",
                            "&:hover": {
                              color: "#58C5C6",
                              border: "1px solid #58C5C6",
                            },
                          }}
                        >
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
      <NotificationContainer />
    </div>
  );
  return (
    <>
      <Prompt when={isTouched} message="Salvar alterações?" />
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
    </>
  );
}

export default CompanyData;
