import { api } from '../connection/api'

const baseUrl = 'associate/training';

export async function fetch() {
    return await api.get(baseUrl);
}

export async function fetchCategoriesWithTrainings() {
    return await api.get(`${baseUrl}/categories`);
}

export async function find(id) {
    return await api.get(`${baseUrl}/${id}`);
}

export async function putViews(id) {
    return await api.put(`${baseUrl}/put-views/${id}`);
}
