import '../App.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import ButtonDefault from '../../../components/buttonDefault';
import IconButtonDefault from '../../../components/iconButton';
import CpfMask from '../../../components/inputs/cpf';
import CnpjMask from '../../../components/inputs/cnpj';
import PhoneMask from '../../../components/inputs/phone';
import DateMask from '../../../components/inputs/date';
import CepjMask from '../../../components/inputs/cep';


import { TypographDefault, ParagraphDefault } from '../../../components/typograph';
import PageDefault from '../../../components/pageDefault';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faBuilding,
    faCalendarAlt,
    faClock,
    faCreditCard,
    faDollarSign,
    faLock,
    faPhone,
    faPlus,
    faShieldAlt,
    faUserAlt
} from '@fortawesome/free-solid-svg-icons';
import { getIndicators, getProviderById } from '../../../services/register/providers';
import moment from 'moment';
import FontAwesomeIconButtonDefault from '../../../components/buttonWithFontAwesomeIcon';
import { Provider } from 'react-redux';


function ProviderView() {

    const [provider, setProvider] = useState('');

    const [personType, setPersonType] = useState('');
    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [foreignerId, setForeignerId] = useState('');
    const [foreignerName, setForeignerName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [phone, setPhone] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [email, setEmail] = useState('');
    const [stateInscription, setStateInscription] = useState('');
    const [stateInscriptionIndicator, setStateInscriptionIndicator] = useState('');
    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [addressNumber, setAddressNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [addressComplement, setAddressComplement] = useState('');
    // const [rg, setRg] = useState('')
    const [notes, setNotes] = useState('');
    const [publicPlace, setPublicPlace] = useState('')


    const [showAdicionalInformation, setShowAdicionaInformation] = useState(false);
    const [showTaxInformation, setShowTaxInformation] = useState(false);
    const [showAddressInformation, setShowAddressInformation] = useState(false);
    const [showObservationInformation, setShowObservationInformation] = useState(false);
    const [showPhysicalPersonInformation, setShowPhysicalPersonformation] = useState(false);
    const [showLegalPersonInformation, setShowLegalPersonformation] = useState(false);
    const [showForeignerInformation, setShowForeignerformation] = useState(false);

    const [indicators, setIndicators] = useState()

    const history = useHistory();
    const [backErrors, setBackErrors] = useState({});
    const clearBackErrors = (field) => {
        backErrors[field] = null;
        setBackErrors(backErrors);
    };

    const toggleShowAdicionalInformation = () => {
        setShowAdicionaInformation(!showAdicionalInformation);
    }

    const toggleShowTaxInformation = () => {
        setShowTaxInformation(!showTaxInformation);
    }

    const toggleShowAddressInformation = () => {
        setShowAddressInformation(!showAddressInformation);
    }

    const toggleShowObservationInformation = () => {
        setShowObservationInformation(!showObservationInformation);
    }

    useEffect(() => {
        if (personType === "FISICA") {
            setShowPhysicalPersonformation(true);
            setShowLegalPersonformation(false);
            setShowForeignerformation(false);
        } else if (personType === "JURIDICA") {
            setShowLegalPersonformation(true);
            setShowPhysicalPersonformation(false);
            setShowForeignerformation(false);
        } else if (personType === "ESTRANGEIRA") {
            setShowForeignerformation(true);
            setShowPhysicalPersonformation(false);
            setShowLegalPersonformation(false);
        }
    }, [personType])

    const { id } = useParams()
    useEffect(() => {
        getProviderById(id).then(({ data }) => {
            setProvider(data);
            setPersonType(data.type)
            setCpf(data.socialCode)
            setCnpj(data.socialCode)
            setCompanyName(data.socialName)
            setForeignerId(data.socialCode)
            setName(data.socialName)
            setBirthday(data.openingDate.substr(0, 10))
            setPhone(data.contact.phone)
            setCellPhone(data.contact.whatsapp)
            setEmail(data.email)
            // setRg(data.socialCode)
            setStateInscriptionIndicator(data.indicatorId)
            setStateInscription(data.stateRegistration)
            setCep(data.address.zipCode)
            setPublicPlace(data.address.street)
            setAddressNumber(data.address.numberHouse)
            setNeighborhood(data.address.neighborhood)
            setState(data.address.city.state.name)
            setCity(data.address.city.name)
            setAddressComplement(data.address.complement)
            setNotes(data.comments)
        })
    }, [id])

    useEffect(() => {
        getIndicators().then(res => {
            setIndicators(res.data)
        })
    }, [])

    const page = (
        <>
            <Formik
                initialValues={{
                    personType,
                    cpf,
                    name,
                    companyName,
                    cnpj,
                    foreignerId,
                    foreignerName,
                    birthday,
                    phone,
                    cellPhone,
                    email,
                    id,
                    stateInscription,
                    cep,
                    address,
                    addressNumber,
                    neighborhood,
                    state,
                    city,
                    addressComplement,
                }}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    setSubmitting(true);
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <div className="row d-flex align-items-center pl-4" style={{ height: "70vh" }}>
                        <div className="col-12 col-md-10 d-flex justify-content-between align-items-center flex-wrap p-0">
                            <div className="d-flex align-items-center flex-wrap mt-3">
                                <h1 style={{ color: "#002c43" }}>
                                    <FontAwesomeIcon
                                        className="back-icon mr-3 icon-hover"
                                        icon={faArrowLeft}
                                        size="1x"
                                        onClick={() => history.push("/registration/provider")}
                                        color="#002c43"
                                    />
                                        Visualizar fornecedor
                                    </h1>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-10 invoice__form pb-3">
                                    <div className="row col-12">
                                        <div className="col-9 col-md-9 my-3">
                                            <ParagraphDefault paragraphData={{
                                                _class: 'text-muted font-weight-bold h5 ml-2',
                                                text: 'Dados gerais'
                                            }} />
                                        </div>
                                    </div>
                                    <div className="row col-12">
                                        <div className="col-12 col-md-4 mb-3">
                                            <p className="text-muted font-weight-bold m-0">Tipo de pessoa: </p>
                                            <div class="form-group">
                                                <select
                                                    class="custom-select"
                                                    id="personType"
                                                    value={personType}
                                                    disabled
                                                >
                                                    <option value="" selected>Selecione o tipo</option>
                                                    <option value="FISICA">Física</option>
                                                    <option value="JURIDICA">Jurídica</option>
                                                    <option value="ESTRANGEIRA">Estrangeira</option>
                                                </select>
                                            </div>
                                        </div>
                                        {showPhysicalPersonInformation && (
                                            <>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">CPF: </p>
                                                    <CpfMask
                                                        id="cpf"
                                                        name="cpf"
                                                        className="form-control "
                                                        placeholder="Informe o seu CPF"
                                                        type="text"
                                                        value={values.cpf}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Nome: </p>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={values.name}
                                                        placeholder="Informe o nome"
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {showLegalPersonInformation && (
                                            <>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">CNPJ: </p>
                                                    <CnpjMask
                                                        id="cnpj"
                                                        name="cnpj"
                                                        className="form-control "
                                                        placeholder="Informe o CNPJ"
                                                        type="text"
                                                        value={values.cnpj}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Razão Social: </p>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="companyName"
                                                        placeholder="Informe a razão social"
                                                        value={companyName}
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {showForeignerInformation && (
                                            <>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Identificação estrangeira: </p>
                                                    <input
                                                        id="foreignerId"
                                                        name="foreignerId"
                                                        className="form-control "
                                                        placeholder="Informe o ID"
                                                        type="text"
                                                        value={values.foreignerId}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Nome: </p>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="foreignerName"
                                                        placeholder="Informe o nome"
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-10 invoice__form">
                                    <div className="row">
                                        <div className="col-9 col-md-9 my-3">
                                            <label
                                                className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                                                onClick={toggleShowAdicionalInformation}
                                            >
                                                Informações adicionais
                                            </label>
                                        </div>
                                        {showAdicionalInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowAdicionalInformation(),
                                                    icon: 'bi bi-chevron-up',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                        {!showAdicionalInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowAdicionalInformation(),
                                                    icon: 'bi bi-chevron-down',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {showAdicionalInformation && (
                                        <>
                                            <div className="row col-12">
                                                <div className="col-12 col-md-4 mb-3">
                                                    <p className="text-muted font-weight-bold mb-0">
                                                        {
                                                            values.personType === "JURIDICA" ? "Data de abertura:" : "Data de nascimento:"
                                                        }
                                                    </p>
                                                    <input
                                                        id="birthday"
                                                        name="birthday"
                                                        className="form-control "
                                                        type="date"
                                                        value={birthday}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Telefone comercial: </p>
                                                    <PhoneMask
                                                        id="phone"
                                                        name="phone"
                                                        className="form-control "
                                                        placeholder="Informe o telefone"
                                                        value={phone}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <p className="text-muted font-weight-bold m-0">Telefone celular: </p>
                                                    <PhoneMask
                                                        id="cellPhone"
                                                        name="cellPhone"
                                                        className="form-control "
                                                        placeholder="Informe o telefone"
                                                        value={cellPhone}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row col-12 mb-2">
                                                <div className="col-12 col-md-6 mb-3">
                                                    <p className="text-muted font-weight-bold m-1">E-mail: </p>
                                                    <input
                                                        id="email"
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Informe o e-mail"
                                                        value={email}
                                                        disabled
                                                    />
                                                </div>
                                                {/* {
                                                    values.personType !== "JURIDICA" && (
                                                        <div className="col-12 col-md-6">
                                                            <p className="text-muted font-weight-bold m-0">RG: </p>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="id"
                                                                placeholder="Informe o RG"
                                                                value={rg}
                                                                disabled
                                                            />
                                                        </div>
                                                    )
                                                } */}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-10 invoice__form pb-3">
                                    <div className="row">
                                        <div className="col-9 col-md-9 my-3">
                                            <label
                                                className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                                                onClick={toggleShowTaxInformation}
                                            >
                                                Informações fiscais
                                            </label>
                                        </div>
                                        {showTaxInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowTaxInformation(),
                                                    icon: 'bi bi-chevron-up',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                        {!showTaxInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowTaxInformation(),
                                                    icon: 'bi bi-chevron-down',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {showTaxInformation && (
                                        <>
                                            <div className="row col-12">
                                                <div className="col-12 col-md-6 mb-3">
                                                    <p className="text-muted font-weight-bold mb-0">Indicador de inscrição estadual: </p>
                                                    <div class="form-group">
                                                        <select
                                                            class="custom-select"
                                                            id="stateInscriptionIndicator"
                                                            value={stateInscriptionIndicator}
                                                            disabled
                                                        >
                                                            <option value="" selected>Informe o indicador</option>
                                                            {
                                                                indicators !== undefined &&
                                                                indicators.map(currentIndicator => {
                                                                    return (
                                                                        <option key={currentIndicator.id} value={currentIndicator.id}>{currentIndicator.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <p className="text-muted font-weight-bold m-0">Inscrição estadual: </p>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="stateInscription"
                                                        placeholder="Informe a inscrição estadual"
                                                        value={stateInscription}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-10 invoice__form">
                                    <div className="row">
                                        <div className="col-9 col-md-9 my-3">
                                            <label
                                                className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                                                onClick={toggleShowAddressInformation}
                                            >
                                                Endereço
                                            </label>
                                        </div>
                                        {showAddressInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowAddressInformation(),
                                                    icon: 'bi bi-chevron-up',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                        {!showAddressInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowAddressInformation(),
                                                    icon: 'bi bi-chevron-down',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {showAddressInformation && (
                                        <>
                                            <div className="row col-12">
                                                <div className="col-12 col-md-3 mb-3">
                                                    <p className="text-muted font-weight-bold mb-0">CEP: </p>
                                                    <div class="form-group">
                                                        <CepjMask
                                                            id="cep"
                                                            name="cep"
                                                            className="form-control"
                                                            value={values.cep}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="text-muted font-weight-bold m-0">Logradouro: </p>
                                                    <input
                                                        id="address"
                                                        name="address"
                                                        className="form-control"
                                                        value={publicPlace}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <p className="text-muted font-weight-bold m-0">Número: </p>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="addressNumber"
                                                        placeholder=""
                                                        value={addressNumber}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row col-12">
                                                <div className="col-12 col-md-4 mb-3">
                                                    <p className="text-muted font-weight-bold mb-0">Bairro: </p>
                                                    <div class="form-group">
                                                        <input
                                                            id="neighborhood"
                                                            name="neighborhood"
                                                            className="form-control"
                                                            value={neighborhood}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <p className="text-muted font-weight-bold m-0">Estado: </p>
                                                    <input
                                                        id="state"
                                                        name="state"
                                                        className="form-control"
                                                        value={state}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <p className="text-muted font-weight-bold m-0">Cidade: </p>
                                                    <input
                                                        id="city"
                                                        name="city"
                                                        className="form-control"
                                                        value={city}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <p className="text-muted font-weight-bold mb-0">Complemento: </p>
                                                    <div class="form-group">
                                                        <input
                                                            id="addressComplement"
                                                            name="addressComplement"
                                                            className="form-control"
                                                            value={addressComplement}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-10 invoice__form">
                                    <div className="row">
                                        <div className="col-9 col-md-9 my-3">
                                            <label
                                                className="text-muted font-weight-bold h5 ml-2 prividerCreate__label"
                                                onClick={toggleShowObservationInformation}
                                            >
                                                Observações
                                            </label>
                                        </div>
                                        {showObservationInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowObservationInformation(),
                                                    icon: 'bi bi-chevron-up',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                        {!showObservationInformation && (
                                            <div className="col-3 float-right">
                                                <IconButtonDefault iconButtonData={{
                                                    variant: "none",
                                                    action: () => toggleShowObservationInformation(),
                                                    icon: 'bi bi-chevron-down',
                                                    iconSize: '1.5rem',
                                                    // goesTo: '/',
                                                    iconColor: '#002c43',
                                                    _class: 'mt-3 float-right'
                                                }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {showObservationInformation && (
                                        <>
                                            <div className="row col-12 mb-5">
                                                <div className="col-md-12">
                                                    <p className="text-muted font-weight-bold m-0">Observações: </p>
                                                    <textarea
                                                        className="form-control"
                                                        id="notes"
                                                        rows="5"
                                                        value={notes}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col p-0 justify-content-between align-items-center mb-5">
                                    {
                                        provider.status ? (
                                            <ButtonDefault buttonData={{
                                                variant: 'info',
                                                action: () => history.push(`/registration/provider/edit/${id}`),
                                                text: 'Editar',
                                                textColor: '#FFF',
                                                _class: 'font-weight-bold mr-1 mt-2',
                                            }} />
                                        ) : ''
                                    }
                                    <ButtonDefault buttonData={{
                                        variant: 'none',
                                        action: () => history.push("/registration/provider"),
                                        text: 'Voltar',
                                        textColor: '#87cefa',
                                        _class: 'recovery__btn--cancel font-weight-bold mt-2',
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>)}
            </Formik>
        </>
    );
    return (
        <div>
            <PageDefault pageData={{
                pageContent: page
            }} />
        </div>
    );
}

export default ProviderView;

