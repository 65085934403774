import axios from 'axios';

const viaCepApi = axios.create({
    baseURL: 'https://viacep.com.br/ws/',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});



 const apiCancellable = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
  }
})

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },

})



api.interceptors.request.use(request => {
    const jwtAuth = localStorage.getItem('jwt_auth');
    if (jwtAuth) {
        request.headers['Authorization'] = `Bearer ${jwtAuth}`;
    }
    return request;
})

api?.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.removeItem('jwt_auth')
        window.location.href = '/';
    }

    return Promise.reject(error)
});

export { viaCepApi, api,apiCancellable };
