import { api } from '../connection/api'

const baseURL = '/financial/associated-bill-to-receive'

export async function findAllBillsToReceive({ perPage, page }) {
  return await api.get(`${baseURL}`, { params: { perPage, page } })
}

export async function findAllBillsToReceiveCategories() {
  return await api.get(`${baseURL}/categories`)
}

export async function createBillToReceiveCategory(data) {
  return await api.post(`${baseURL}/categories`, data)
}

export async function createBillToReceive(data) {
  return await api.post(`${baseURL}`, data)
}

export async function findOneBillToReceive(id) {
  return api.get(`${baseURL}/${id}`);
}

export async function updateParcel(data, billId, parcelId) {
  return await api.put(`${baseURL}/${billId}/parcel/${parcelId}`, data)
}

export async function updateBillToReceive(data, id) {
  return await api.put(`${baseURL}/${id}`, data)
}

export async function destroyBillToReceiveAdditional(billId, attachmentId) {
  return await api.delete(`${baseURL}/${billId}/additional/${attachmentId}`);
}

export async function getBillToReceiveParcelsByDate(date) {
  return await api.get(`${baseURL}/payment/parcels`, { params: { date } })
}

export async function getBillToReceiveResume(year) {
  return await api.get(`${baseURL}/resume`, { params: { year } })
}
