import { api } from '../connection/api'

const baseEndpoint = 'register/associated-services';

export async function fetchAssociatedServices() {
  return await api.get(baseEndpoint);
}

export async function getAssociatedService(id) {
  return await api.get(`${baseEndpoint}/${id}`);
}

export async function postAssociatedService(associatedService) {
  return await api.post(baseEndpoint, associatedService);
}

export async function putAssociatedService(id, associatedService) {
  return await api.put(`${baseEndpoint}/${id}`, associatedService);
}

export async function putActivate(id) {
  return await api.put(`${baseEndpoint}/${id}/activate`);
}

export async function putDeactivate(id) {
  return await api.put(`${baseEndpoint}/${id}/deactivate`);
}

export async function findAllAssociatedServicesActive() {
  return api.get(`${baseEndpoint}/active`);
}
