export const ConsultancyStatus = {
    SCHEDULED: "SCHEDULED",
    FINISHED: "FINISHED",
    UNREALIZED: "UNREALIZED",
    CANCELEDACSP: "CANCELEDACSP",
    CANCELEDUSER: "CANCELEDUSER"
}

export const PaymentMethod = {
    CREDITCARD: "CREDITCARD",
    BILLET: "BILLET",
    LOCAL: "LOCAL",
    FREE: "FREE"
}

export const PaymentStatus = {
    WAITING: "WAITING",
    PAID: "PAID",
    CANCELED: "CANCELED",
    REVERSED: "REVERSED"
}

export const ConsultancyType = {
    PRESENTIAL: "PRESENCIAL",
    VIDEO_CONFERENCE: "VIDEOCONFERÊNCIA"
}