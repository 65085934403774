import React, { useEffect, useState } from "react";
import { Image, ListGroup } from "react-bootstrap";
import "./style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import iconsIMG from "../ImgImport/index";

const DrawerDefault = (props) => {
  const { listItems, logo, logoHeight, _class } = props.drawer;

  const [items, setItems] = useState();

  useEffect(() => {
    setItems(
      listItems.map((item,index) => {
        return (
          <ListGroup.Item
            key={item.key+index}
            className="bg-white border-0"
            style={{ padding: "0 0 0 0" }}
          >
            {item.item}
          </ListGroup.Item>
        );
      })
    );
  }, []);

  return (
    <div
      className={_class}
      style={{
        height: "100%",
        backgroundColor: "#fbfbfb",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="d-flex sidebar-heading shadow-sm bg-light">
            <div className="d-flex col-3 bg-light border-left border-right"></div>
            <div className="d-flex col-9">
              <Image
                className="imgSize"
                style={{ maxWidth: "100%", marginBottom: 15 }}
                src={logo.url}
                alt={logo.alt}
                height={logoHeight}
              />
            </div>
          </div>
          <ListGroup>{items}</ListGroup>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "0px 35px 35px 0px",
          }}
        >
          <img src={iconsIMG.LogoACSP} className="logo" alt="Logo ACSP" />
        </div>
      </div>
    </div>
  );
};

export default DrawerDefault;
