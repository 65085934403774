import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PlanStatus from "./PlanStatus";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./fonteSourceSansPro.css";
import Avatar from "../../../imgs/icons/Avatar.svg";
import { getAssociated } from "../../../services/associate";
import {
  putAlterPassword,
  putSolicitationInactiveAccount,
} from "../../../services/associate/index";
import * as yup from "yup";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./ProfileMenu.css";

function ProfileMenuPage() {
  const [socialName, setSocialName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [changePassword, setChangePassword] = useState(true);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#EAEAEA");
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);

  useEffect(() => {
    getAssociated().then(({ data }) => {
      const { socialName, contact } = data;
      setSocialName(socialName);
      setContactEmail(contact.email);
      setContactPhone(maskPhone(contact.phone));
    });
  }, []);

  const maskPhone = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
  };

  const schema = yup.object().shape({
    actualPassword: yup
      .string()
      .required("Campo obrigatório")
      .min(8, "Digite no mínimo 8 caracteres."),
    newPassword: yup
      .string()
      .required("Campo obrigatório")
      .min(8, "Digite no mínimo 8 caracteres.")
      .oneOf([yup.ref("confirmNewPassword")], "As senhas estão diferentes."),
    confirmNewPassword: yup
      .string()
      .required("Campo obrigatório.")
      .min(8, "Digite no mínimo 8 caracteres.")
      .oneOf([yup.ref("newPassword")], "As senhas estão diferentes."),
  });

  const formik = useFormik({
    initialValues: {
      actualPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (data, { setFieldError }) => {
      setLoadingBackdrop(true);
      await putAlterPassword({
        actualPassword: data.actualPassword,
        newPassword: data.newPassword,
      })
        .then((response) => {
          setIsPasswordChanged(true);
        })
        .catch((err) => {
          if (err.response) {
            err.response.data.errors.forEach((error) => {
              const { fieldName, message } = error;
              setFieldError(fieldName, message);
            });
          }
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
      console.log(JSON.stringify(data, null, 2));
    },
  });

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: 90,
            margin: "0px 0px 40px 40px",
          }}
        >
          <Box
            border={"2px solid #7B7B7B"}
            width={100}
            height={100}
            borderRadius={"50%"}
            marginTop={"40px"}
            display={"flex"}
            justifyContent={"center"}
          >
            <img src={Avatar} style={{ width: "60px" }} />
          </Box>
          {/* <Button
          variant="outlined"
          sx={{
            width: 130,
            height: 40,
            marginTop: 2,
            color: "#7b7b7b",
            border: "1px solid #c1c1c1",
            textTransform: "none !important",
            fontFamily: "Source Sans Pro",
            fontSize: "19px",
            "&:hover": {
              color: "#000",
              border: "1px solid #000",
            },
          }}
        >
          Trocar foto
        </Button> */}
        </Box>

        <Grid container columns={12} marginLeft="40px">
          <Grid item xs={6}>
            <Grid item xs={6}>
              <Box
                sx={{
                  fontSize: "32px",
                  marginBottom: "30px",
                  fontFamily: "Source Sans Pro",
                  color: "#7B7B7B",
                }}
              >
                Perfil
              </Box>
            </Grid>
            <Grid container rowSpacing={2} columns={{ xs: 2, md: 2, sm: 2 }}>
              <Grid item xs={6}>
                <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                  Nome
                </p>
                <TextField
                  className="input-teste"
                  placeholder="Jane Cooper"
                  disabled
                  sx={{ marginTop: -1, width: 330, backgroundColor: "#EAEAEA" }}
                  value={socialName}
                />
              </Grid>
              <Grid item xs={6}>
                <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                  E-mail
                </p>
                <TextField
                  className="input-teste"
                  placeholder="janecooper@gmail.com"
                  disabled
                  sx={{ marginTop: -1, width: 330, backgroundColor: "#EAEAEA" }}
                  value={contactEmail}
                />
              </Grid>

              <Grid item xs={6}>
                <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                  Telefone
                </p>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    className="input-teste "
                    placeholder="(00) 00000-0000"
                    disabled
                    sx={{
                      marginTop: -1,
                      width: 330,
                      backgroundColor: "#EAEAEA",
                    }}
                    value={contactPhone}
                  />
                </Box>
              </Grid>

              <form onSubmit={formik.handleSubmit}>
                <Grid item xs={6} mt={5}>
                  <p
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontSize: "18px",
                      color: "#7B7B7B",
                    }}
                  >
                    Altere sua senha
                  </p>
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <TextField
                        name="actualPassword"
                        className="input-teste alterar"
                        placeholder="***************"
                        type="password"
                        disabled={changePassword}
                        sx={{
                          marginTop: -1,
                          width: 330,
                          backgroundColor: { backgroundColor },
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#58C5C6",
                            },
                          },
                        }}
                        value={formik.values.actualPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.actualPassword &&
                          Boolean(formik.errors.actualPassword)
                        }
                        helperText={
                          formik.touched.actualPassword &&
                          formik.errors.actualPassword
                        }
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{
                          width: "100px",
                          color: "#AEAEAE",
                          marginTop: -1,
                          marginLeft: 2,
                          border: "1px solid #c1c1c1",
                          fontFamily: "Source Sans Pro",
                          textTransform: "none !important",
                          "&:hover": {
                            color: "#AEAEAE",
                            border: "1px solid #212121",
                          },
                          minHeight: "56px",
                        }}
                        onClick={() => {
                          setChangePassword(!changePassword);
                          setBackgroundColor(
                            changePassword ? "#FFF" : "#EAEAEA"
                          );
                        }}
                      >
                        Alterar
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="newPassword"
                    className="input-teste"
                    placeholder="Nova senha"
                    type="password"
                    sx={{
                      marginTop: 1,
                      width: 330,
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#58C5C6",
                        },
                      },
                    }}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="confirmNewPassword"
                    className="input-teste"
                    placeholder="Confirme sua nova senha"
                    type="password"
                    sx={{
                      marginTop: 1,
                      width: 330,
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#58C5C6",
                        },
                      },
                    }}
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmNewPassword &&
                      Boolean(formik.errors.confirmNewPassword)
                    }
                    helperText={
                      formik.touched.confirmNewPassword &&
                      formik.errors.confirmNewPassword
                    }
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Button
                    type="submit"
                    sx={{
                      marginTop: 1,
                      color: "#fff",
                      width: 150,
                      height: 45,
                      background: "#64c2c8",
                      textTransform: "none !important",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#64c2c8",
                        border: "1px solid",
                      },
                    }}
                    disabled={changePassword}
                  >
                    Salvar
                  </Button>
                  {isPasswordChanged ? (
                    <p
                      style={{
                        fontFamily: "Source Sans Pro",
                        color: "#0CBA90",
                        paddingTop: "5px",
                      }}
                    >
                      Sua senha foi alterada com sucesso!
                    </p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>

          <Grid item xs={6} paddingLeft="100px">
            <PlanStatus />
          </Grid>
        </Grid>
        {/* <Box
          className="metodo-login"
          sx={{
            width: "90%",
            "& a": {
              color: "#64c2c8",
              fontSize: "16px",
              textDecoration: "underline",
            },
          }}
        >
          <Grid columns={12} marginLeft="40px" marginTop="50px">
            <Grid item xs={12}>
              <p style={{ fontFamily: "Source Sans Pro" }}>
                Método de Login do site{" "}
                <a href="#" style={{ fontFamily: "Source Sans Pro" }}>
                  gov.br
                </a>
              </p>
              <Select
                sx={{
                  width: "100%",
                }}
              >
                <MenuItem value={10}>Teste 01</MenuItem>
                <MenuItem value={20}>Teste 02</MenuItem>
                <MenuItem value={30}>Teste 03</MenuItem>
              </Select>
            </Grid>
            <Box
              className="metodo-login-inputs"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  width: "49%",
                  marginTop: 2,
                }}
              />
              <TextField
                sx={{
                  width: "49%",
                  marginTop: 2,
                }}
              />
            </Box>
          </Grid>
        </Box> */}
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBackdrop}
      >
        <CircularProgress color="inherit" />{" "}
      </Backdrop>
    </>
  );
}

export default ProfileMenuPage;
