import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { PaymentStatus } from "../../../enumerations/paymentStatusDAS";
import moment from "moment";
import Currency from "../../inputs/currency";
import { BorderBottom } from "@mui/icons-material";
import { getOne } from "../../../services/das/index";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import checkIcon from "../../../imgs/icons/check.svg";
import alertIcon from "../../../imgs/icons/alert.svg";
import paidIcon from "../../../imgs/icons/paid.svg";
import "./style.css";

export default function DASCard(props) {
  const { data } = props;
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const handleClose = () => setShow(false);
  const currentYear = new Date().getFullYear();
  const getOneDas = (id) => {
    setShow(true);
    getOne(id).then((response) => {
      setModalData(response.data);
    });
  };

  const renderDas = (das) => {
    return (
      <tr
        key={das.id}
        style={{ cursor: "pointer" }}
      >
        <td>{das.month}</td>
        <td>{das.dueDate ? moment(das.dueDate).format("DD/MM/YYYY") : ""}</td>
        <td style={{ display: "flex", justifyContent: "space-between" }}>
          {das.status === PaymentStatus.PAID ? (
            <>
              <span>Pago</span>
              <img
                alt="check"
                src={checkIcon}
                style={{ margin: 1, height: 22, width: 22, float: "right" }}
              />
            </>
          ) : (
            <>
              <span>Em aberto</span>
              <img
                alt="check"
                src={paidIcon}
                style={{ margin: 1, height: 22, width: 22, float: "right" }}
              />
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <Box>
      <div>
        <div className="card heig card-das">
          <Box
            sx={{
              marginY: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>DAS</h2>
            <Link to="/financial/pay_tax" className="linkCard">
              <Button
                sx={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "none",
                  width: 130,
                  height: 45,
                  color: "#fff",
                  bgcolor: "#58C5C6",
                  border: "none",
                  "&:hover": {
                    color: "#58C5C6",
                    border: "1px solid #58C5C6",
                  },
                }}
              >
                Ver todas
              </Button>
            </Link>
          </Box>
          <table class="table table-hover p-0">

          {data.length === 0 ? (
              <>
              <tr><th colspan="3">Nenhuma DAS de { currentYear } encontrada</th></tr>
              <tr><td colspan="3">Favor clicar no botão "Ver todas" e na página DAS, clicar no botão de atualizar</td></tr>
              </>
            ) : (
              <>
              <thead>
                <tr>
                  <th>Ano</th>
                  <th>Data</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((das) => renderDas(das))}
              </tbody>
              </>
            )}

            
          </table>
        </div>
      </div>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="modalContentDAS"
      >
        <Modal.Header closeButton>
          <Modal.Title className="col-11 text-center">
            Informações imposto DAS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <label>
              <p>Boleto do imposto DAS referente a:</p>
              <strong>{modalData.month} de 2020</strong>
            </label>

            <label>
              <p>Vencimento em:</p>
              <strong>{moment(modalData.dueDate).format("DD/MM/YYYY")}</strong>
            </label>

            {modalData.status === PaymentStatus.PAID ? (
              <label>
                <p>Data do pagamento</p>
                <strong>
                  {moment(modalData.paymentDate).format("DD/MM/YYYY")}
                </strong>
              </label>
            ) : (
              <div></div>
            )}

            <label>
              <p>Status do boleto:</p>
              <strong
                className={
                  modalData.status === PaymentStatus.PAID ? "green" : "yellow"
                }
              >
                {modalData.status === PaymentStatus.PAID ? "Pago" : "Em aberto"}
              </strong>
            </label>

            <label>
              <p>Valor:</p>
              <strong className="price">
                R$ {Currency({ value: parseFloat(modalData.value) })}
              </strong>
            </label>

            {modalData.status !== PaymentStatus.PAID &&
            modalData.slipBarcode ? (
              <div>
                <div className="getNumber">
                  <p>Código do boleto:</p>
                  <span>{modalData.slipBarcode} </span>
                  <Button
                    className="copyNumber"
                    variant="info"
                    onClick={() => {
                      navigator.clipboard.writeText(modalData.slipBarcode);
                    }}
                  >
                    Copiar código do boleto
                  </Button>
                </div>
                {modalData.path ? (
                  <div className="flex-column foot">
                    <p>PDF do boleto</p>
                    <Button
                      className="buttonLeft"
                      variant="outline-info"
                      onClick={() => {
                        window.open(
                          modalData.path,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      Ver PDF
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {modalData.path ? (
                  <div>
                    <div className="flex-column foot">
                      <p>Comprovante de pagamento</p>
                      <Button
                        className="buttonLeft"
                        variant="outline-info"
                        onClick={() => {
                          window.open(
                            modalData.path,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        Ver PDF
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
}
