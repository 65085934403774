import { api } from '../../connection/api'

const baseUrl = '/invoice'

export async function getInvoiceProductSum(yearReference) {
	return api.get(`${baseUrl}/sum-products`, { params: { yearReference } });
}

export async function getInvoiceServiceSum(yearReference) {
	return api.get(`${baseUrl}/sum-services`, { params: { yearReference } });
}