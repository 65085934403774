import React from 'react';
import MaskedInput from 'react-text-mask';

export default function CnpjMask(props) {

    const cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

    return (
        <MaskedInput
            {...props}
            mask={cnpjMask}
        />
    );
}