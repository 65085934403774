import React from 'react';
import MaskedInput from 'react-text-mask';

export default function CpfMask (props) {
    const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

    return (
        <MaskedInput
            {...props}
            mask={cpfMask}
        />
    )
}
