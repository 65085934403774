import React, { useEffect, useState } from 'react'
import { getUseAndPrivacyTerms } from '../../services/useAndPrivacyTerm'

const TermsContent = () => {
    const [terms, setTerms] = useState()

    useEffect(() => {
      getUseAndPrivacyTerms()
            .then(({ data }) => {
                setTerms(data.description);
            })
            .catch(({ response }) => { });
    }, []);


    return (
        <div className="text-muted">
            <div dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
    )
}

export default TermsContent