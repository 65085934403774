import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useEffect, useState } from "react";

const AlertDefault = (props) => {
  const [show, setShow] = useState(false);

  const { head, content, alertVariant, buttonCloseText, buttonCloseVariant, showCloseButton } = props.alertData

  useEffect(() => setShow(props.showAlert), [props.showAlert]);

  return (
    <Alert show={show} variant={alertVariant}>
      <Alert.Heading> {head} </Alert.Heading>
      <p>
        {content}
      </p>
      {
        showCloseButton && (
          <hr />
        )
      }
      <div className="d-flex justify-content-end">
        {
          showCloseButton && (
            <Button onClick={() => setShow(false)} variant={buttonCloseVariant}>
              {buttonCloseText}
            </Button>
          )
        }
      </div>
    </Alert>
  );
};

export default AlertDefault;
