import React from "react";
import ReactLoading from "react-loading";

import { LoadingContainer } from "./styles";


function Loading({ color, width, height }) {
  return (
    <LoadingContainer>
      <ReactLoading type="spin" color={color} width={width} height={height} />
    </LoadingContainer>
  );
}

export default Loading;
