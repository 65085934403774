import { api } from '../../connection/api'

const baseUrl = '/invoice/nfe-complements'
const chartBaseUrl = '/invoice'

export async function findAllCfops() {
  return api.get(`${baseUrl}/cfop`);
}

export async function findAllIcmsTaxes() {
  return api.get(`${baseUrl}/icms-tax`);
}

export async function findAllCestCategories() {
  return api.get(`${baseUrl}/cest-category`);
}

export async function findAllCestSubCategories() {
  return api.get(`${baseUrl}/sub-category-all`);
}

export async function findAllCestSubCategoriesByCategory(categoryId) {
  return api.get(`${baseUrl}/${categoryId}/sub-category`);
}

export async function findAllPaymentTypes() {
  return api.get(`${baseUrl}/payment-types`);
}

export async function findAllShippingMethods() {
  return api.get(`${baseUrl}/shipping-methods`);
}

export async function findAllRecipientIndicators() {
  return api.get(`${baseUrl}/recipient-indicators`);
}

export async function findAllBrokerIndicators() {
  return api.get(`${baseUrl}/broker-indicators`);
}

export async function findAllPresenceCommercialEstablishments() {
  return api.get(`${baseUrl}/presence-commercial-establishments`);
}

export async function findAllFinalConsumers() {
  return api.get(`${baseUrl}/final-consumers`);
}

export async function findAllInvoiceGoals() {
  return api.get(`${baseUrl}/invoice-goals`);
}

export async function findAllDanfePrintFormats() {
  return api.get(`${baseUrl}/danfe-print-formats`);
}

export async function findAllOperationDestinies() {
  return api.get(`${baseUrl}/operation-destinies`);
}

export async function getInvoiceChartData(year) {
  return await api.get(`${chartBaseUrl}/chart-data`, { params: { year } });
}

export async function getInvoiceYears() {
  return await api.get(`${chartBaseUrl}/years`);
}
