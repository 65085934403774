export default function getMarkers() {
  const markers = [
    {
      lat: -23.5473251,
      lng: -46.6334578,
      nome: "Centro",
      local: "DISTRITAL ACSP (SEDE)",
      address: "Rua Boa Vista, 51 - Centro Histórico",
      email: "centralderelacionamento@acsp.com.br",
      telefone: "(11) 3180-3737",
    },
    {
      lat: -23.5562773,
      lng: -46.6351356,
      nome: "Liberdade",
      local: "DISTRITAL CENTRO",
      address: "Rua Galvão Bueno, 83 - Liberdade",
      email: "dcentro@acsp.com.br",
      telefone: "(11) 3180-3580",
    },
    {
      lat: -23.5198293,
      lng: -46.5906184,
      nome: "Vila Maria",
      local: "DISTRITAL NORDESTE",
      address: "Avenida Guilherme Cotching, 1070 - Vila Maria",
      email: "dnordeste@acsp.com.br",
      telefone: "(11) 3180-3467",
    },
    {
      lat: -23.5017905,
      lng: -46.6214506,
      nome: "Santana",
      local: "DISTRITAL NORTE",
      address: "Rua Jovita, 309 - Santana",
      email: "dnorte@acsp.com.br",
      telefone: "(11) 3180-3669",
    },
    {
      lat: -23.6411416,
      lng: -46.6665922,
      nome: "Vl. Alexandria",
      local: "DISTRITAL CENTRO SUL",
      address: "Av. Santa Catarina, 641 - Jabaquara",
      email: "dcentrosul@acsp.com.br",
      telefone: "(11) 3180-3818",
    },
    {
      lat: -23.5804187,
      lng: -46.6072944,
      nome: "Ipiranga",
      local: "DISTRITAL IPIRANGA",
      address: "Rua Benjamin Jafet, 95 - Ipiranga",
      email: "dipiranga@acsp.com.br",
      telefone: "(11) 3180-3246",
    },
    {
      lat: -23.6062333,
      lng: -46.6349062,
      nome: "Vila Mariana",
      local: "DISTRITAL SUDESTE",
      address: "Rua Afonso Celso, 1659 - Vila Mariana",
      email: "dsudeste@acsp.com.br",
      telefone: "(11) 3180-3271",
    },
    {
      lat: -23.6510284,
      lng: -46.7098179,
      nome: "St. Amaro",
      local: "DISTRITAL SUL",
      address: "Av. Mário Lopes Leão, 406 - St. Amaro",
      email: "dsul@acsp.com.br",
      telefone: "(11) 3180-3946",
    },
    {
      lat: -23.5593157,
      lng: -46.5991126,
      nome: "Mooca",
      local: "DISTRITAL MOOCA",
      address: "Rua Madre de Deus, 222 - Mooca",
      email: "dmooca@acsp.com.br",
      telefone: "(11) 3180-3092",
    },
    {
      lat: -23.5189495,
      lng: -46.5481047,
      nome: "Penha de França",
      local: "DISTRITAL PENHA",
      address: "Av. Gabriela Mistral, 199 - Penha de França",
      email: "dpenha@acsp.com.br",
      telefone: "(11) 3180-3012",
    },
    {
      lat: -23.4951849,
      lng: -46.4362278,
      nome: "São Miguel Paulista",
      local: "DISTRITAL SÃO MIGUEL",
      address: "Av. Marechal Tito, 1042 - São Miguel Paulista",
      email: "dsaomiguel@acsp.com.br",
      telefone: "(11) 3180-3079",
    },
    {
      lat: -23.5488204,
      lng: -46.5681226,
      nome: "Vila Gomes Cardim",
      local: "DISTRITAL TATUAPÉ",
      address: "Largo Nossa Sra. Do Bom Parto, 163 - Vila Gomes Cardim",
      email: "dtatuape@acsp.com.br",
      telefone: "(11) 3180-3455",
    },
    {
      lat: -23.4890685,
      lng: -46.7172988,
      nome: "Vila Bonilha",
      local: "DISTRITAL NOROESTE",
      address: "Rua Luís Braille, 8 - Pirituba",
      email: "dnoroeste@acsp.com.br",
      telefone: "(11) 3180-3729",
    },
    {
      lat: -23.5279372,
      lng: -46.709955,
      nome: "Alto da Lapa",
      local: "DISTRITAL OESTE",
      address: "Rua Pio XI, 418 - Alto da Lapa",
      email: "doeste@acsp.com.br",
      telefone: "(11) 3180-3691",
    },
    {
      lat: -23.5633347,
      lng: -46.6893336,
      nome: "Pinheiros",
      local: "DISTRITAL PINHEIROS",
      address: "Rua Simão Álvares, 517 - Pinheiros",
      email: "dpinheiros@acsp.com.br",
      telefone: "(11) 3180-3637",
    },
    {
      lat: -23.5766493,
      lng: -46.7104137,
      nome: "Butantã",
      local: "DISTRITAL SUDOESTE",
      address: "Rua Alvarenga, 591 - Butantã",
      email: "dsudoeste@acsp.com.br",
      telefone: "(11) 3180-3772",
    },
    {
      lat: -23.6029385,
      lng: -46.6574856,
      nome: "Indianópolis",
      local: "ESCRITÓRIO DE SERVIÇO MOEMA",
      address: "Avenida Açocê, 308 - Moema",
      email: "balcao.moema@acsp.com.br",
      telefone: "(11) 3180-3618",
    },
    {
      lat: -23.5282037,
      lng: -46.6754992,
      nome: "Água Branca",
      local: "ESCRITÓRIO DE SERVIÇO WEST PLAZA",
      address: "Avenida Antártica, 480, Água Branca - loja 3221 2° bloco B",
      email: "balcaowestplaza@acsp.com.br",
      telefone: "(11) 99917-0040",
    },
  ];

  return markers;
}
