import "./App.css";
import { useHistory } from "react-router-dom";
import PageDefault from "../../components/pageDefault";
import ButtonDefault from "../../components/buttonDefault";
import iconsIMG from "../../components/ImgImport";
import { useState, useEffect } from "react";
import Currency from "../../components/inputs/currency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesomeIconButtonDefault from "../../components/buttonWithFontAwesomeIcon";
import {
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ButtonActionDefault from "../../components/buttonAction";
import moment from "moment";
import {
  ConsultancyStatus,
  PaymentMethod,
  PaymentStatus,
  ConsultancyType,
} from "../../enumerations/Consultancy";
import { generateReceipt, getSchedules } from "../../services/consultancy";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";

function Consultancy() {
  const history = useHistory();
  const [apiConsultancy, setApiConsultancy] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [receipt, setReceipt] = useState("");

  /**
   * Paginação
   */
  const [perPage, setPerPage] = useState(10);
  const [numRows, setNumRows] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState();
  const [nextPage, setNextPage] = useState();
  const [totalPages, setTotalPages] = useState();

  moment.locale("pt-br");
  useEffect(() => {
    const paginationData = {
      perPage,
      currentPage,
    };
    getSchedules(paginationData)
      .then(({ data }) => {
        setApiConsultancy(data);
        setNumRows(data.totalResults);
        setPreviousPage(data.previousPage);
        setNextPage(data.nextPage);
        setTotalPages(data.totalPages);
        setCurrentPage(data.page);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [perPage, currentPage]);

  const getConsultancyStatus = (status) => {
    switch (status?.toUpperCase()) {
      case ConsultancyStatus.SCHEDULED:
        return <p className="text-success">Agendado</p>;
      case ConsultancyStatus.UNREALIZED:
        return <p className="text-danger">Não realizado</p>;
      case ConsultancyStatus.FINISHED:
        return <p className="text-success">Finalizado</p>;
      case ConsultancyStatus.CANCELEDACSP:
        return <p className="text-danger">Cancelado pela ACSP</p>;
      case ConsultancyStatus.CANCELEDUSER:
        return <p className="text-danger">Cancelado pelo usuário</p>;
      default:
        return "";
    }
  };

  const getPaymentStatus = (status) => {
    switch (status?.toUpperCase()) {
      case PaymentStatus.WAITING:
        return <p>Aguardando</p>;
      case PaymentStatus.PAID:
        return <p className="text-success">Pagamento aprovado</p>;
      case PaymentStatus.CANCELED:
        return <p className="text-danger">Pagamento cancelado</p>;
      case PaymentStatus.REVERSED:
        return <p className="text-danger">Pagamento estornado</p>;
    }
  };

  const getPaymentMethod = (type) => {
    switch (type?.toUpperCase()) {
      case PaymentMethod.BILLET:
        return "Boleto bancário";
      case PaymentMethod.CREDITCARD:
        return "Cartão de Crédito";
      case PaymentMethod.FREE:
        return "Grátis";
      case PaymentMethod.LOCAL:
        return "No local";
    }
  };

  const page = (
    <div className="pl-4">
      <NotificationContainer />
      <SweetAlert
        custom
        showCloseButton
        showConfirm={false}
        btnSize="lg"
        show={showAlert}
        onConfirm={() => {
          history.push(`/consultancy/edit/${currentSchedule.id}`);
        }}
        onCancel={() => setShowAlert(false)}
        disabled={
          currentSchedule?.scheduleDetail[0]?.status ===
            ConsultancyStatus.CANCELEDUSER ||
          currentSchedule?.scheduleDetail[0]?.status ===
            ConsultancyStatus.CANCELEDACSP
        }
      >
        <>
          <div className="text-left text-muted">
            <h4>Informações de consultoria</h4>
            <p>Local:</p>
            <b>{currentSchedule?.scheduleDetail[0]?.district?.name}</b>

            {currentSchedule?.scheduleDetail[0]?.attendanceModality?.name?.toUpperCase() ===
              ConsultancyType.VIDEO_CONFERENCE && (
              <>
                <p className="mt-4">Link:</p>
                <a
                  href={currentSchedule?.scheduleDetail[0]?.videoConferenceLink}
                  target="__blank"
                >
                  {currentSchedule?.scheduleDetail[0]?.videoConferenceLink}
                </a>
              </>
            )}

            <p className="mt-4">Data</p>
            <b>
              {moment(currentSchedule?.scheduleDetail[0]?.date).format("L")}
            </b>

            <p className="mt-4">Forma de pagamento</p>
            <b>
              {getPaymentMethod(currentSchedule?.transaction[0]?.paymentType)}
            </b>

            <p className="mt-4">Valor</p>
            <b>
              R${" "}
              {Currency({
                value:
                  currentSchedule?.consultation?.consultationValue[0]?.value,
              })}
            </b>

            {currentSchedule?.transaction[0]?.paymentType?.toUpperCase() ===
              PaymentMethod.BILLET && (
              <>
                <div className="d-flex justify-content-between align-items-end">
                  <div className="col-9 p-0">
                    <p className="mt-4 highlight">Código do boleto:</p>
                    <b href={currentSchedule?.transaction[0]?.billetCode}>
                      {currentSchedule?.transaction[0]?.billetCode}
                    </b>
                  </div>
                  <FontAwesomeIconButtonDefault
                    buttonData={{
                      variant: "info",
                      text: "Copiar",
                      _class: "mt-2",
                      action: () => [
                        navigator.clipboard.writeText(
                          currentSchedule?.transaction[0]?.billetCode
                        ),
                        NotificationManager.listNotify.splice(
                          0,
                          NotificationManager.listNotify.length
                        ),
                        NotificationManager.success(
                          "Código copiado para área de transferência"
                        ),
                      ],
                      icon: <FontAwesomeIcon icon={faCopy} />,
                    }}
                  />
                </div>

                <p className="mt-4">Link do boleto</p>
                <a
                  href={currentSchedule?.transaction[0]?.billetLink}
                  target="__blank"
                >
                  Link do boleto
                </a>
              </>
            )}
          </div>
          <div className="mt-5 d-flex justify-content-around">
            <ButtonActionDefault
              buttonData={{
                text: "Baixar comprovante",
                variant: "outline-info",
                action: () =>
                  generateReceipt(parseInt(currentSchedule?.id)).then(
                    ({ data }) => {
                      const a = document.createElement("a");
                      document.body.appendChild(a);
                      a.href = `data:application/pdf;base64,${data}`;
                      a.download = "comprovante_de_agendamento.pdf";
                      a.click();
                      setReceipt(data);
                    }
                  ),
              }}
              href={receipt}
            />

            <ButtonActionDefault
              buttonData={{
                text: "Alterar agendamento",
                variant: "info",
                disabled:
                  currentSchedule?.transaction[0].status.toUpperCase() ===
                  PaymentStatus.CANCELED,
                action: () =>
                  history.push(`/consultancy/edit/${currentSchedule.id}`),
              }}
            />
          </div>
        </>
      </SweetAlert>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center flex-wrap col-12 col-md-10 mt-3 p-0">
          <h1 style={{ color: "#002c43" }}>
            <FontAwesomeIcon
              className="back-icon mr-3 icon-hover"
              icon={faArrowLeft}
              size="1x"
              onClick={() => history.push("/home")}
              color="#002c43 icon-hover "
            />
            Balcão do empreendedor
          </h1>
          <FontAwesomeIconButtonDefault
            buttonData={{
              variant: "info",
              text: "Agendar nova consultoria",
              action: () => history.push("/consultancy/create"),
              icon: <FontAwesomeIcon icon={faPlus} />,
            }}
          />
        </div>

        <div className="col-12 col-md-10 mt-3 p-0">
          <div className="shadow-lg border rounded p-4">
            <table className="table tabled-striped table-hover table-bordered">
              <thead>
                <tr style={{ borderTop: "none!important" }}>
                  <th>
                    {" "}
                    <h5 className="text-muted">Local</h5>{" "}
                  </th>
                  <th>
                    {" "}
                    <h5 className="text-muted">Tipo de consultoria</h5>{" "}
                  </th>
                  <th>
                    {" "}
                    <h5 className="text-muted">Situação</h5>{" "}
                  </th>
                  <th>
                    {" "}
                    <h5 className="text-muted">Situação do pagamento </h5>{" "}
                  </th>
                  <th>
                    {" "}
                    <h5 className="text-muted">Data</h5>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiConsultancy !== undefined &&
                  apiConsultancy.results?.map((e, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-muted border-bottom-muted"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCurrentSchedule(e);
                          setShowAlert(true);
                        }}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={iconsIMG.Consultancy}
                              className="mr-2"
                            ></img>
                            {e?.scheduleDetail[0]?.district?.name}
                          </div>
                        </td>
                        <td>{e?.consultation?.name}</td>
                        <td>
                          {getConsultancyStatus(e?.scheduleDetail[0]?.status)}
                        </td>
                        <td>{getPaymentStatus(e?.transaction[0]?.status)}</td>
                        <td>{moment(e?.scheduleDetail[0].date).format("L")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="d-flex justify-content-end align-items-center flex-wrap">
              <p>Linhas por página:</p>
              <div className="d-flex ml-2">
                <select
                  className="custom-select custom-select-sm"
                  onChange={(e) => {
                    setPerPage(e.target.value);
                  }}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="d-flex mx-4">
                <p>
                  {perPage} de {numRows}
                </p>
              </div>
              <div className="d-flex mr-4">
                <p>
                  Página {currentPage} de {totalPages}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  color={"#878787"}
                  icon={faChevronLeft}
                  className="icon_hover mr-3"
                  onClick={() => {
                    setCurrentPage(previousPage);
                  }}
                />
                <FontAwesomeIcon
                  color={"#878787"}
                  icon={faChevronRight}
                  className="icon_hover"
                  onClick={() => {
                    setCurrentPage(nextPage);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
    </div>
  );
}

export default Consultancy;
