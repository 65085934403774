import React from "react";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom'

const ButtonActionDefault = (props) => {
    const {
        variant,
        text,
        action,
        size,
        _class,
        disabled = false,
        loading = false,
    } = props.buttonData;

    return (
        <Button
            size={size}
            className={_class}
            variant={variant}
            disabled={disabled}
            onClick={() => {
                action()
            }}
        >
                      
            {text}
        </Button>
    );
};

export default ButtonActionDefault;
