import React from "react";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom'
import "./style.css"

const IconButtonDefault = (props) => {
  const { variant, action, icon, iconSize, goesTo, iconColor, _class } = props.iconButtonData;

  return (
    <Button
      variant={variant}
      onClick={() => {
        action();
      }}
      className={_class.concat(" iconButton p-0 icon d-flex align-items-center")}
      size="sm"
    >
      <Link to={goesTo} style={{ color: iconColor }} className="buttonStyle pb-4">
        <i className={icon} style={{ fontSize: iconSize }}></i>
      </Link>
    </Button>
  );
};

export default IconButtonDefault;