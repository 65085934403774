import React from 'react';
import MaskedInput from 'react-text-mask';

export default function PhonejMask(props) {
    const mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    return (
        <MaskedInput
            {...props}
            mask={mask}
            guide={false}
        />
    );
}