import "../App.css";
import PageDefault from "../../../components/pageDefault";
import DialogDefault from '../../../components/dialog';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import ButtonDefault from "../../../components/buttonDefault";
import { useHistory, useParams, useLocation } from "react-router";
import { getAssociatedService, postAssociatedService, putAssociatedService } from "../../../services/associatedService";
import { getAssociated } from "../../../services/associate";
import { getActivities } from "../../../services/register/activities";

import ButtonActionDefault from "../../../components/buttonAction";
import AlertDefault from "../../../components/alertDefault";

function ServiceCreate() {
  const [service, setService] = useState({});
  const [cnaes, setCnaes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [success, setSuccess] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [backErrors, setBackErrors] = useState({});
  const location = useLocation();
  const [hasRegisteredInvoice, SetHasRegisteredInvoice] = useState(false)


  const [cnaeId, setCnaeId] = useState('');
  const [activityId, setActivityId] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  //Pega o nome da rota para saber se e create, update ou view;
  const [actualPage, setActualPage] = useState(location.pathname.split("/")[3]);

  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();

  const cancel = () => {
    history.push('/registration/service');
  };

  const serviceSchema = yup.object().shape({
    name: yup.string().trim().required("Campo obrigatório"),
    cnaeId: yup.number().typeError('Campo inválido').required("Campo obrigatório."),
    activityId: yup.number().typeError('Campo inválido').required("Campo obrigatório.")
  });

  useEffect(() => {
    getAssociated().then(({ data }) => setActivities(data?.municipalRegistries[0]?.activities));
    // getActivities().then(({data}) => setActivities(data));
  }, []);

  useEffect(() => {

    //Somente fazer busca e popular os dados \/
    if (actualPage === "update" || (actualPage === "view")) {
      getAssociatedService(id).then(({ data }) => {
        const { name, code, cnae,activityId } = data;
        if (!data.status && actualPage === 'update') {
          history.push('/registration/service')
        }

        setService(data)
        setName(name);
        setServiceName(name)
        setCode(code);
        let cnaeSingle = [];
        cnaeSingle.push(data.cnae);
        setCnaes(cnaeSingle);
        setCnaeId(cnae?.id);
        if(activities){
          let activitiesListSingle = [];
          activitiesListSingle.push(activities);
         setActivities(activities);
         setActivityId(activityId);
        }
      })
    }
  }, [id, actualPage, history]);

  useEffect(() => {
    setBackErrors({});
  }, [formChanged]);
  function changeCnaes(e) {
    if (e) {
      let findActivityCnae = activities.find((state) => state.id === e);
      console.log(findActivityCnae, "teste2");
      if (findActivityCnae?.cnaes?.id) {
        setCnaeId(findActivityCnae?.cnaes?.id);
        let cnaeSingle = [];
        cnaeSingle.push(findActivityCnae?.cnaes);
        setCnaes(cnaeSingle);
      }else{
        setCnaes([]);
        setCnaeId('');
        alert('Essa atividade não possui Cnae associado! Realizar a correção no orgão competente.')
      }
    }
  }
  const handleSubmit = (values, setSubmitting) => {
    const { name, code, cnaeId,activityId } = values;

    const associatedService = {
      name,
      code,
      cnaeId,
      activityId
    }

    setSubmitting(true);

    if (actualPage === 'update') {
      putAssociatedService(id, associatedService).then((res) => {
        setSuccess(true);
        setSubmitting(false);
        setFormChanged(false);
      })
        .catch((err) => {
          setSubmitting(false);
          setSuccess(false);
          const errors = {};
          err.response?.data?.errors?.forEach(({ fieldName, message }) => errors[fieldName] = message);
          setBackErrors(errors);
        })

    } else {
      postAssociatedService(associatedService).then((res) => {
        Object.keys(formRef.current.values).forEach(key => (formRef.current.values[key] = ''));
        setSuccess(true);
        setSubmitting(false);
        setFormChanged(false);
        history.push("/registration/service");
      })
        .catch((err) => {
          setSubmitting(false);
          setSuccess(false);
          const errors = {};
          err.response?.data?.errors?.forEach(({ fieldName, message }) => errors[fieldName] = message);
          setBackErrors(errors);
        })
    }


  }

  const header = (
    <div className="row">
      <div className="col-sm-1 text-right">
        <h1 onClick={() => history.push("/registration/service")}>
          <FontAwesomeIcon
            className="back-icon labelmark--backarrow"
            icon={faArrowLeft}
            size="1x"
            color="#002c43" />
        </h1>
      </div>
      <div className="col-sm-11">
        <h1 style={{ color: '#002C43' }}>
          {
            !id ? <>Cadastro de Serviços</> : <>{serviceName}</>
          }
        </h1>
      </div>
    </div>
  );

  // function changeActivitiesList(e){
  //   setActivities([]);
  //   setActivityId('');
  //   let findCnae = cnaes.find((state => state.id === e.target.value));
  //   if(findCnae.activities){
  //     setActivities(findCnae.activities);
  //     if(findCnae.activities.length === 1){
  //       setActivityId(findCnae.activities[0].id);
  //     }
  //   }
  // }


  const page = (
    <>
      {header}
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          name,
          code,
          cnaeId,
          activityId,
        }}
        validationSchema={serviceSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Form onChange={() => setFormChanged(true)}>
              <div
                className="service service_form p-3 ml-5"
              >
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <h5>Dados do serviço</h5>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-7">
                    <p>Nome do serviço:*</p>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-building text-info"></i>
                      </span>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Informe o nome do serviço"
                        disabled={
                          actualPage === "view"
                            ? true
                            : false
                        }
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setFieldValue("name", e.target.value);
                        }}
                      />
                      <small className="col-12 text-danger m-0 p-0">
                        {(errors.name && touched.name && errors.name) ||
                          backErrors.name}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <p>Código interno: </p>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-building text-info"></i>
                      </span>
                      <Field
                        type="text"
                        name="code"
                        className="form-control"
                        placeholder="Informe o código interno"
                        disabled={
                          actualPage === "view"
                            ? true
                            : false
                        }
                        value={code}
                        onChange={(code) => {
                          setCode(code.target.value);
                          setFieldValue("code", code.target.value);
                        }}
                      />
                      <small className="col-12 text-danger m-0 p-0">
                        {(errors.code && touched.code && errors.code) ||
                          backErrors.code}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service service_form p-3 m-5">
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <h5>Dados do fiscais</h5>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <p>Atividade do Serviço:*</p>
                    <small className="infoCNAE">
                      Defina a Atividade do Serviço vinculada ao serviço
                      cadastrado, pois ela será utilizada para calculo de
                      impostos
                    </small>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-building text-info"></i>
                      </span>
                      <Field
                        as="select"
                        name="activityId"
                        className="form-control"
                        placeholder="Informe a Atividade"
                        disabled={
                          actualPage === "view"
                            ? true
                            : false
                        }
                        value={activityId}
                        onChange={(e) => {
                          changeCnaes(e.target.value);
                          setActivityId(e.target.value);
                          setFieldValue("activityId", e.target.value);
                        }}
                      >
                        {activities && activities?.length > 0 ? (
                          <option value="" defaultValue>
                            Informe a Atividade
                          </option>
                        ) : (
                          <option value="" defaultValue>
                            É necessário possuir uma inscrição municipal
                            cadastrada para selecionar uma atividade
                          </option>
                        )}

                        {activities && activities?.length > 0
                          ? activities?.map((activity) => {
                              return (
                                <option
                                  key={activity.id}
                                  value={activity.id}
                                >{`${activity.code} - ${activity.description}`}</option>
                              );
                            })
                          : null}
                      </Field>
                      <small className="col-12 text-danger m-0 p-0">
                        {(errors.cnaeId && touched.cnaeId && errors.cnaeId) ||
                          backErrors.cnae}
                      </small>
                    </div>
                  </div>

                  <div className="col-sm-12" style={{ marginTop: 50 }}>
                    <p>CNAE de serviço:*</p>
                    <small className="infoCNAE">
                      O CNAE (Código de Classificação Nacional de Atividades
                      Econômicas), que deve ser vinculado ao serviço cadastrado.
                    </small>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-building text-info"></i>
                      </span>
                      <Field
                        as="select"
                        name="cnaeId"
                        className="form-control"
                        placeholder="Informe a Atividade para exibir o Cnae Associado"
                        disabled={true}
                        value={cnaeId}
                        // onChange={(e) => {
                        //   setCnaeId(e.target.value);
                        //   setFieldValue("cnaeId", e.target.value);
                        //   changeActivitiesList(e);
                        // }}
                      >
                        <option value="" defaultValue>
                          Nenhuma atividade selecionada.
                        </option>
                        {cnaes.map((cnae) => {
                          return (
                            <option
                              key={cnae?.id}
                              value={cnae?.id}
                            >{`${cnae?.code} - ${cnae?.name}`}</option>
                          );
                        })}
                      </Field>
                      <small className="col-12 text-danger m-0 p-0">
                        {(errors.cnaeId && touched.cnaeId && errors.cnaeId) ||
                          backErrors.cnae}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {success ? (
                <span className="green ml-5 ">Dados salvos com sucesso!</span>
              ) : null}
              <div className="row ml-5 mt-2">
                {!hasRegisteredInvoice && (
                  <div>
                    {actualPage === "view" ? (
                      <>
                        {service.status ? (
                          <ButtonDefault
                            buttonData={{
                              variant: "info",
                              textColor: "#fff",
                              action: () => setActualPage("update"),
                              text: "Editar",
                              _class: "col-12 pl-4 pr-4",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <ButtonDefault
                        buttonData={{
                          variant: "info",
                          textColor: "#fff",
                          action: () => handleSubmit(),
                          text: "Salvar",
                          _class: "col-12 pl-4 pr-4",
                        }}
                      />
                    )}
                  </div>
                )}
                <div className="ml-2">
                  {formChanged ? (
                    <DialogDefault
                      dialogData={{
                        buttonText: "Voltar",
                        buttonVariant: "outline-info",
                        dialogTitle: "Salvar Alterações?",
                        showHeader: true,
                        showBody: true,
                        showFooter: false,
                        closeModal: true,
                        dialogBody: (
                          <div className="row mb-3">
                            <div className="col-sm-2" />
                            <div className="col-sm-4">
                              <ButtonDefault
                                className="createAccout_btn font-weight-bold col mr-1 mt-2"
                                buttonData={{
                                  variant: "info",
                                  action: () => cancel(),
                                  text: "Não",
                                  textColor: "#FFF",
                                  _class: "font-weight-bold col mr-1 mt-2",
                                }}
                              />
                            </div>
                            <div className="col-sm-4">
                              <ButtonActionDefault
                                buttonData={{
                                  text: "Sim",
                                  variant: "outline-info",
                                  _class: "mt-2 col",
                                  action: () =>
                                    handleSubmit(formRef.current.values),
                                }}
                              />
                            </div>
                          </div>
                        ),
                      }}
                    />
                  ) : (
                    <ButtonActionDefault
                      buttonData={{
                        text: "Voltar",
                        variant: "outline-info",
                        _class: "",
                        action: () => history.push("/registration/service"),
                      }}
                    />
                  )}
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );

  return <PageDefault pageData={{ pageContent: page }} />
}

export default ServiceCreate;
