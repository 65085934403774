import { api } from '../connection/api'

const baseURL = '/financial'
const registerURL = '/register'

export async function findAllBillsToPay({ perPage, page }) {
    return await api.get(`${baseURL}/associated-bill-to-pay`, { params: { perPage, page } })
}

export async function findAllBillsToPayCategories() {
    return await api.get(`${baseURL}/associated-payment-category`)
}

export async function findAllBillsToPayTypes() {
    return await api.get(`${baseURL}/associated-payment-type`)
}

export async function findAllBillsToPayConditions() {
    return await api.get(`${baseURL}/associated-payment-condition`)
}

export async function createBillToPay(data) {
    return await api.post(`${baseURL}/associated-bill-to-pay`, data)
}

export async function updateBillToPay(data, id) {
    return await api.put(`${baseURL}/associated-bill-to-pay/${id}`, data)
}

export async function createBillToPayCategory(data) {
    return await api.post(`${baseURL}/associated-payment-category`, data)
}

export async function findAllProviders() {
    return await api.get(`${registerURL}/associated-provider`)
}

export async function getExpiredAndToExpire(year) {
    return await api.get(`${baseURL}/associated-bill-to-pay/expired-to-pay`, { params: { year } })
}

export async function getTtotal(year) {
    return await api.get(`${baseURL}/associated-bill-to-pay/total-value`, { params: { year } })
}

export async function getYears() {
    return await api.get(`${baseURL}/associated-bill-to-pay/years`)
}

export async function getPaid(year) {
    return await api.get(`${baseURL}/associated-bill-to-pay/paid`, { params: { year } })
}

export async function getPending() {
    return await api.get(`${baseURL}/associated-bill-to-pay/pending`)
}

export async function getLiquidated() {
    return await api.get(`${baseURL}/associated-bill-to-pay/liquidated`)
}

export async function getBillsToPayByPeriod(year) {
    return await api.get(`${baseURL}/associated-bill-to-pay/by-period`, { params: { year } })
}

export async function getBillToPay(id) {
    return await api.get(`${baseURL}/associated-bill-to-pay/find-one/${id}`)
}

export async function updateParcel(data, id) {
    return await api.put(`${baseURL}/associated-payment-parcel/${id}`, data)
}

export async function getBillToPayParcelsByDate(date) {
  return await api.get(`${baseURL}/associated-bill-to-pay/parcels`, { params: { date } })
}

export async function deleteAttachment(billId, attachmentId) {
    return await api.delete(`${baseURL}/associated-bill-to-pay/${billId}/additional/${attachmentId}`)
}

export async function getBillToPayResume(year) {
  return await api.get(`${baseURL}/associated-bill-to-pay/resume`, { params: { year } })
}
