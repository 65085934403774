import '../App.css';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faPencilAlt,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import PageDefault from '../../../components/pageDefault';
import { FormCheck } from "react-bootstrap";
import FontAwesomeIconButtonDefault from '../../../components/buttonWithFontAwesomeIcon';
import { getAllInvoiceSerie, putChangeStatusInvoiceSerie } from '../../../services/invoiceSerie';

function InvoiceSeries() {

  const history = useHistory();

  const [backErrors, setBackErrors] = useState({});
  const [invoiceSeries, setInvoiceSeries] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [rowPage, setRowPage] = useState('')
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };



  useEffect(()=> {
    getAllInvoiceSerie().then(response => {
      setInvoiceSeries(response.data.results);
      setInvoiceInfo(response.data);
    })
  },[])

  const changeStatusOnList = (index) => {
    const newInvoiceSeries = invoiceSeries.map((invoiceSerie, i) => {
      const { active } = invoiceSerie;
      if (i === index) {
        invoiceSerie.status = !active;
      }
      return invoiceSerie;
    });
    setInvoiceSeries(newInvoiceSeries);
  }

  const pagination = (data) => {
    getAllInvoiceSerie({
      page: data.page + 1,
      perPage: data.rowsPerPage
    }).then(response => {
      setInvoiceSeries([]);
      setInvoiceSeries(response.data.results);
      setInvoiceInfo(response.data);
    })
  }

  const changeRowsperPage = (data) => {
    getAllInvoiceSerie({
      perPage: data.rowsPerPage
    }).then(response => {
      setInvoiceSeries(response.data.results);
      setInvoiceInfo(response.data);
    })
  }

  const handleStatus = (index) => {
    const { id } = invoiceSeries[index];
    putChangeStatusInvoiceSerie({id}).then(response => {
      changeStatusOnList(index)
    })
  }

  const options = {
    selectableRows: false,
    serverSide: true,
    onDownload: (buildHead, buildBody, columns, data) =>
      buildHead(columns) +
      buildBody(
        data
      ),
    count: invoiceInfo.totalResults,
    rowsPerPageOptions: [10,20,50,100],
    onTableChange: (action, tableState) => {

      switch (action) {
        case 'changePage':
          pagination(tableState);
          break;
        case 'changeRowsPerPage':
          changeRowsperPage(tableState);
          break;
        default:

      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página: "
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: "",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
  };

  const columns = [
    {
      name: "Série:",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Númeração:",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Número inicial:",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Número final:",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Status: ",
      options: {
        filter: false,
        sort: false,
        download: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "end" } }),
        customBodyRender: (active, tableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <FormCheck
              style={{ textAlign: "end" }}
              type="switch"
              id={`status-${rowIndex}`}
              // className={active.toLowerCase()}
              defaultChecked={active}
              onChange={() => handleStatus(rowIndex)}
            />
          );
        }
      }
    },
    {
      name: "Ações:",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
  ];
  const page = (
    <div className="row ml-lg-3">
      <div className="d-flex justify-content-between align-items-center flex-wrap col-10 mt-3 p-0">
        <h1 style={{ color: "#002c43" }}>
          <FontAwesomeIcon
            className="back-icon mr-3 icon-hover"
            icon={faArrowLeft}
            size="1x"
            onClick={() => history.push("/home")}
            color="#002c43 icon-hover "
          />
          Série de Nota Fiscal de Serviço
        </h1>

        <FontAwesomeIconButtonDefault
          buttonData={{
            variant: "info",
            text: "Nova Série de Nota",
            action: () => history.push('/financial/invoice_series_service/create'),
            icon: (
              <FontAwesomeIcon icon={faPlus} />
            ),
          }}
        />
      </div>
      <MUIDataTable
        className="col-12 col-md-10 mt-3"
        title={"Série de nota fiscal"}
        data={invoiceSeries.map((invoiceSerie, index) => {
          return [
            invoiceSerie.name,
            invoiceSerie.numeration,
            invoiceSerie.initialNumbering,
            invoiceSerie.finalNumbering,
            invoiceSerie.active,
            <div style={{ textAlign: "start" }}>
              <FontAwesomeIcon
                icon={faEye}
                color="#878787"
                style={{ cursor: 'pointer' }}
                size="lg"
                title="Visualizar os dados do fornecedor"
                onClick={() => {
                  history.push(`/financial/invoice_series_service/view/${invoiceSerie.id}`)
                }}
              />
              <FontAwesomeIcon
                icon={faPencilAlt}
                color="#878787"
                className="ml-2"
                style={{ cursor: 'pointer' }}
                size="lg"
                title="Alterar os dados do fornecedor"
                onClick={() => {
                  history.push(`/financial/invoice_series_service/edit/${invoiceSerie.id}`)
                }}
              />
            </div>,
          ];
        })}
        columns={columns}
        options={options}
      />
    </div>
  )
  return (
    <div>
      <PageDefault pageData={{
        pageContent: page
      }} />
    </div>
  );
}

export default InvoiceSeries;

