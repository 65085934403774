import React from 'react'

const TypographDefault = (props) => {
    const { type, colorText, textSize, text, fontFamily } = props.typographData
    return (
        <h1 className={type.concat(" p-0 m-0")} style={{ color: colorText, fontSize: textSize, fontFamily: fontFamily }}> {text} </h1>
    )
}

const ParagraphDefault = (props) => {
    const { _class, text } = props.paragraphData
    return (
        <p className={_class.concat(" m-0")}> {text} </p>
    )
}

export { TypographDefault, ParagraphDefault };