import React from 'react'
import { Form } from 'react-bootstrap'
import { useState } from 'react'

const SwitcherDefault = (props) => {
    const { checked, id, action } = props.switcherData

    return (
        <Form.Check
            type="switch"
            checked={checked}
            id={id}

            onClick={() =>
                action()
            }
        />
    )
}

export default SwitcherDefault;