import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import "./style.css";
import Seta from "../../imgs/menu/seta.svg";

const CollapseDefault = (props) => {
  const {
    textButton,
    components,
    icon,
    iconIMG,
    iconIMGHover,
    marcacao,
    iconColor,
    iconSize,
    textColor,
    title,
  } = props.collapseData;

  const [bodyComponent, setBodyComponents] = useState();
  const [hoverButton, sethoverButton] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(false);

  useEffect(() => {
    setBodyComponents(
      components.map((item) => {
        return <div key={item.key}>{item.component}</div>;
      })
    );
  }, []);

  return (
    <div className="d-flex">
      <Accordion defaultActiveKey="1" className="col-12 pr-0 pl-0">
        <Card className="d-flex border border-0 bg-white">
          <Card.Header className="d-flex border border-0 p-0 bg-white">
            {iconIMG ? (
              <div className="d-flex col-3 pl-1 border-right bg-light align-items-center justify-content-center">
                <div className="d-flex col-1 pl-1 align-items-center justify-content-center">
                  {hoverButton ? (
                    <img src={marcacao} alt="Marcação" />
                  ) : undefined}
                </div>
                <div className="d-flex col-11 pl-1 align-items-center justify-content-center">
                  <img
                    src={
                      hoverButton && iconIMGHover !== undefined
                        ? iconIMGHover
                        : iconIMG
                    }
                    alt="Icon"
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex border-right bg-light col-3 align-items-center justify-content-end">
                <i
                  className={icon}
                  style={{ color: iconColor, fontSize: iconSize }}
                />
              </div>
            )}
            <Accordion.Toggle
              variant="button"
              eventKey="0"
              className="btn-menu text-left col-9 pl-0 pr-0 btn btn-white"
              onMouseOver={() => {
                sethoverButton(true);
              }}
              onMouseOut={() => {
                sethoverButton(false);
              }}
              onClick={() => {
                visibleIcon ? setVisibleIcon(false) : setVisibleIcon(true);
              }}
            >
              <div
                className="d-flex col-12 justify-content-start align-items-center"
                style={{ color: textColor, paddingLeft: "12px" }}
              >
                <div className="d-flex align-items-center">
                  {visibleIcon ? (
                    <img src={Seta} style={{ marginRight: "10px" }} />
                  ) : // <i
                  //   id={"icon" + title}
                  //   className="bi bi-chevron-left mr-1"
                  //   style={{ color: "#404040", fontSize: "1.4rem" }}
                  // />
                  null}

                  <h3 class="cardHeaderText">{textButton}</h3>
                </div>
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{bodyComponent}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default CollapseDefault;
